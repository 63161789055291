import { useContext } from 'react';
import { AddUserModalContext } from './AddUserModalContext';

const useAddUserModal = () => {
  const { dispatch } = useContext(AddUserModalContext);

  return { dispatch };
};

export default useAddUserModal;
