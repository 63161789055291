import { useMutation } from '@tanstack/react-query';
import { updateUser } from 'api/api';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useTenantId } from 'tenant';
import { toJson } from 'translators/UserTranslator';
import { User } from 'users/types';
import { Notification } from '@snapchat/snap-design-system';

const useUpdateUser = () => {
  const tenantId = useTenantId();
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();

  const mutation = useMutation({
    mutationKey: ['updateUser'],
    mutationFn: async (user: User) => await updateUser(tenantId, toJson(user)),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: "Failed to update user's role",
          description: 'Error message when deleting a user',
        }),
      });
    },
    onSuccess: () => {
      success({
        title: formatMessage({
          defaultMessage: 'User Role Updated',
          description: 'Success message when updating a users role',
        }),
      });
    },
  });

  return mutation;
};

export default useUpdateUser;
