import { useQuery } from '@tanstack/react-query';
import { getAdminConfig } from 'api/api';
import useIsGatewayAdmin from 'auth/useIsGatewayAdmin';
import { ADMIN_TENANT_ID } from 'common/constants';
import { fromJson } from 'translators/AdminConfigTranslator';
import { AdminConfig } from 'types';
import assertUnreachable from 'util/assertUnreachable';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  admin: AdminConfig;
}

type Output = Loading | Error | Success;

const useAdminConfig = (): Output => {
  const isGatewayAdmin = useIsGatewayAdmin();

  const data = useQuery({
    queryKey: ['tenantConfig', ADMIN_TENANT_ID],
    queryFn: async () => getAdminConfig(),
    enabled: isGatewayAdmin,
  });

  switch (data.status) {
    case 'pending':
      return { status: 'Loading' };
    case 'error':
      return { status: 'Error' };
    case 'success':
      return { status: 'Success', admin: fromJson(data.data) };
    default:
      return assertUnreachable(data);
  }
};

export default useAdminConfig;
