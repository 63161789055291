import { Dropdown, Menu, MenuItem } from '@snapchat/snap-design-system';
import { useAtom } from 'jotai';
import { selectedPixelIdAtom } from 'overview/atoms';
import useConnectedPixels from 'overview/useConnectedPixels';
import React from 'react';
import { useIntl } from 'react-intl';

const SelectPixelDropdown: React.FC = () => {
  const connectedPixels = useConnectedPixels();
  const [selectedPixelId, setSelectedPixelId] = useAtom(selectedPixelIdAtom);
  const { formatMessage } = useIntl();

  const allPixelsLabel = formatMessage({
    defaultMessage: 'All Pixels',
    description: 'All pixels dropdown label',
  });

  const label = selectedPixelId ? (connectedPixels.data?.find(pixel => pixel.pixelId === selectedPixelId)?.name ?? selectedPixelId) : allPixelsLabel;

  return (
    <Dropdown
      buttonProps={{
        label,
        isDisabled: connectedPixels.status === 'pending',
      }}
      overlay={
        <Menu>
          <MenuItem key='all' onClick={() => setSelectedPixelId(undefined)}>
            {allPixelsLabel}
          </MenuItem>
          {connectedPixels?.data?.map((pixel) => (
            <MenuItem
              key={pixel.pixelId}
              onClick={() => {
                setSelectedPixelId(pixel.pixelId);
              }}
            >
              {pixel.name ?? pixel.pixelId}
            </MenuItem>
          ))}
        </Menu>
      }
    />
  );
};

export default SelectPixelDropdown;
