import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import RootContainer from './RootContainer';

function App() {
  return (
    <RootContainer>
      <RouterProvider router={router} />
    </RootContainer>
  );
}

export default App;
