import React from 'react';
import { useIntl } from 'react-intl';
import { User } from 'users/types';

interface Props {
  row: {
    index: number;
    original: User;
  };
}

const UserNameColumnRenderer: React.FC<Props> = ({ row }) => {
  const { formatMessage } = useIntl();

  if (row.original.status === 'INVITED') {
    return (
      <span>
        {formatMessage(
          {
            defaultMessage: '{name} (Invited)',
            description: 'Invited user label',
          },
          { name: row.original.name },
        )}
      </span>
    );
  }

  return <span>{row.original.name}</span>;
};

export default UserNameColumnRenderer;
