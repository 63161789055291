import { Button, Section, Tag } from '@snapchat/snap-design-system';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useSetDnsProvider from './useSetDnsProvider';
import { DnsSetupType } from 'api/types';
import { AdminConfig } from 'types';
import {
  dnsSetupOptionBorderClassName,
  dnsSetupOptionButtonClassName,
  dnsSetupOptionClassName,
  recommendedTagClassName,
} from './styles';
import { cx } from '@emotion/css';
import useEnableCloudflare from './useEnableCloudflare';

interface Props {
  admin: AdminConfig;
}

const SelectDnsProvider: React.FC<Props> = ({ admin }) => {
  const { formatMessage } = useIntl();
  const setDnsProvider = useSetDnsProvider();
  const [pendingProvider, setPendingProvider] = useState<DnsSetupType | undefined>();
  const enableCloudflare = useEnableCloudflare();

  const selectText = formatMessage({
    defaultMessage: 'Select',
    description: 'Select button text',
  });

  return (
    <Section
      title={formatMessage({
        defaultMessage: 'Select DNS Provider',
        description: 'Select DNS Provider section title',
      })}
    >
      <div
        className={
          enableCloudflare ? cx(dnsSetupOptionClassName, dnsSetupOptionBorderClassName) : dnsSetupOptionClassName
        }
      >
        <div>
          <h4>
            {formatMessage({
              defaultMessage: 'AWS AppRunner',
              description: 'AWS AppRunner DNS Provider',
            })}
            <Tag status='recommendation' className={recommendedTagClassName}>
              {formatMessage({ defaultMessage: 'Recommended', description: 'Recommended tag text' })}
            </Tag>
          </h4>
          <p>
            {formatMessage({
              defaultMessage:
                'Use AWS AppRunner to host your subdomain. This option is recommended if you plan to connect five or fewer Pixels from different domains to your gateway.',
              description: 'AWS AppRunner DNS Provider description',
            })}
          </p>
        </div>
        <Button
          className={dnsSetupOptionButtonClassName}
          type='primary'
          onClick={async () => {
            setPendingProvider(DnsSetupType.Aws);
            await setDnsProvider({ ...admin, dnsSetupType: DnsSetupType.Aws });
          }}
          isPending={pendingProvider === DnsSetupType.Aws}
          isDisabled={pendingProvider !== undefined && pendingProvider !== DnsSetupType.Aws}
        >
          {selectText}
        </Button>
      </div>
      {enableCloudflare && (
        <div className={dnsSetupOptionClassName}>
          <div>
            <h4>
              {formatMessage({
                defaultMessage: 'Cloudflare',
                description: 'Cloudflare DNS Provider',
              })}
            </h4>
            <p>
              {formatMessage({
                defaultMessage:
                  'Use Cloudflare to host your subdomain. This option is only suggested if you plan to connect Pixels from more than five domains to your gateway. Additional costs from Cloudflare will apply in order to successfully utilize this solution.',
                description: 'Cloudflare DNS Provider description',
              })}
            </p>
          </div>
          <Button
            className={dnsSetupOptionButtonClassName}
            type='primary'
            onClick={async () => {
              setPendingProvider(DnsSetupType.Cloudflare);
              await setDnsProvider({ ...admin, dnsSetupType: DnsSetupType.Cloudflare });
            }}
            isPending={pendingProvider === DnsSetupType.Cloudflare}
            isDisabled={pendingProvider !== undefined && pendingProvider !== DnsSetupType.Cloudflare}
          >
            {selectText}
          </Button>
        </div>
      )}
    </Section>
  );
};

export default SelectDnsProvider;
