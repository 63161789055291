import { defineMessages } from 'react-intl';

const EVENT_NAME_MESSAGES = defineMessages<string>({
  AD_CLICK: {
    defaultMessage: 'Ad Click',
    description: 'Event name for ad click',
  },
  ACHIEVEMENT_UNLOCKED: {
    defaultMessage: 'Achievement Unlocked',
    description: 'Event name for achievement unlocked',
  },
  AD_VIEW: {
    defaultMessage: 'Ad View',
    description: 'Event name for ad view',
  },
  ADD_BILLING: {
    defaultMessage: 'Add Billing',
    description: 'Event name for add billing',
  },
  ADD_CART: {
    defaultMessage: 'Add to Cart',
    description: 'Event name for add to cart',
  },
  ADD_TO_WISHLIST: {
    defaultMessage: 'Add to Wishlist',
    description: 'Event name for add to wishlist',
  },
  APP_INSTALL: {
    defaultMessage: 'App Install',
    description: 'Event name for app install',
  },
  APP_OPEN: {
    defaultMessage: 'App Open',
    description: 'Event name for app open',
  },
  COMPLETE_TUTORIAL: {
    defaultMessage: 'Complete Tutorial',
    description: 'Event name for complete tutorial',
  },
  CUSTOM_EVENT_1: {
    defaultMessage: 'Custom Event 1',
    description: 'Event name for Custom Event 1',
  },
  CUSTOM_EVENT_2: {
    defaultMessage: 'Custom Event 2',
    description: 'Event name for custom event 2',
  },
  CUSTOM_EVENT_3: {
    defaultMessage: 'Custom Event 3',
    description: 'Event name for custom event 3',
  },
  CUSTOM_EVENT_4: {
    defaultMessage: 'Custom Event 4',
    description: 'Event name for custom event 4',
  },
  CUSTOM_EVENT_5: {
    defaultMessage: 'Custom Event 5',
    description: 'Event name for custom event 5',
  },
  INVITE: {
    defaultMessage: 'Invite',
    description: 'Event name for invite',
  },
  LEVEL_COMPLETE: {
    defaultMessage: 'Level Complete',
    description: 'Event name for level complete',
  },
  LIST_VIEW: {
    defaultMessage: 'List View',
    description: 'Event name for list view',
  },
  LOGIN: {
    defaultMessage: 'Login',
    description: 'Event name for login',
  },
  PAGE_VIEW: {
    defaultMessage: 'Page View',
    description: 'Event name for page view',
  },
  PURCHASE: {
    defaultMessage: 'Purchase',
    description: 'Event name for purchase',
  },
  PROMOTION_VIEW: {
    defaultMessage: 'Promotion View',
    description: 'Event name for promotion view',
  },
  PROMOTION_CARD_VIEW: {
    defaultMessage: 'Promotion Card View',
    description: 'Event name for promotion card view',
  },
  PROMOTION_CARD_ADD: {
    defaultMessage: 'Promotion Card Add',
    description: 'Event name for promotion card add',
  },
  RATE: {
    defaultMessage: 'Rate',
    description: 'Event name for rate',
  },
  RESERVE: {
    defaultMessage: 'Reserve',
    description: 'Event name for reserve',
  },
  SAVE: {
    defaultMessage: 'Save',
    description: 'Event name for save',
  },
  SEARCH: {
    defaultMessage: 'Search',
    description: 'Event name for search',
  },
  SHARE: {
    defaultMessage: 'Share',
    description: 'Event name for share',
  },
  SIGN_UP: {
    defaultMessage: 'Sign Up',
    description: 'Event name for sign up',
  },
  SPENT_CREDITS: {
    defaultMessage: 'Spent Credits',
    description: 'Event name for spent credits',
  },
  START_CHECKOUT: {
    defaultMessage: 'Start Checkout',
    description: 'Event name for start checkout',
  },
  START_TRIAL: {
    defaultMessage: 'Start Trial',
    description: 'Event name for start trial',
  },
  SUBSCRIBE: {
    defaultMessage: 'Subscribe',
    description: 'Event name for subscribe',
  },
  TOTAL: {
    defaultMessage: 'Total',
    description: 'Event name for total',
  },
  VIEW_CONTENT: {
    defaultMessage: 'View Content',
    description: 'Event name for view content',
  },
});

export default EVENT_NAME_MESSAGES;
