import { LOCALE_CODE_MAP } from '@snapchat/ads-l10n';

export const SDS_LOCALES = {
  [LOCALE_CODE_MAP.ar]: 'arEG',
  [LOCALE_CODE_MAP.deDE]: 'deDE',
  [LOCALE_CODE_MAP.enGB]: 'enGB',
  [LOCALE_CODE_MAP.enUS]: 'enUS',
  [LOCALE_CODE_MAP.es]: 'esES',
  [LOCALE_CODE_MAP.esES]: 'esES',
  [LOCALE_CODE_MAP.esMX]: 'esES',
  [LOCALE_CODE_MAP.frFR]: 'frFR',
  [LOCALE_CODE_MAP.zhHans]: 'zhCN',
};

export const LOCALE_DIRECTION: Record<string, 'ltr' | 'rtl'> = {
  [LOCALE_CODE_MAP.ar]: 'rtl',
  [LOCALE_CODE_MAP.deDE]: 'ltr',
  [LOCALE_CODE_MAP.enGB]: 'ltr',
  [LOCALE_CODE_MAP.enUS]: 'ltr',
  [LOCALE_CODE_MAP.es]: 'ltr',
  [LOCALE_CODE_MAP.esES]: 'ltr',
  [LOCALE_CODE_MAP.esMX]: 'ltr',
  [LOCALE_CODE_MAP.frFR]: 'ltr',
  [LOCALE_CODE_MAP.zhHans]: 'ltr',
};
