import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Notification } from '@snapchat/snap-design-system';
import { deleteTenant } from 'api/api';

const useDeleteTenant = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const deleteTenantMutation = useMutation({
    mutationFn: async (tenantId: string) => deleteTenant(tenantId),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to delete tenant',
          description: 'Error message when deleting a tenant',
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });
      success({
        title: formatMessage({
          defaultMessage: 'Tenant Deleted',
          description: 'Success message when deleting a tenant',
        }),
      });
    },
  });

  return deleteTenantMutation;
};

export default useDeleteTenant;
