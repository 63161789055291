import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from 'api/api';
import { Dispatch, useEffect } from 'react';
import { useTenantId } from 'tenant';
import { Event, State } from './useEditUserModalReducer';
import { userId } from 'users/userId';
import { toJson } from 'translators/UserTranslator';

const useGenerateInviteCodeEffect = (dispatch: Dispatch<Event>, state: State) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['updateUser', userId],
    mutationFn: async () =>
      await updateUser(tenantId, toJson({ ...state.initUser, role: state.selectedRole, name: state.name })),
    onSuccess: (data) => {
      dispatch({ type: 'UpdateUserSuccess' });
      queryClient.invalidateQueries({ queryKey: ['users', tenantId] });
    },
    onError: () => dispatch({ type: 'UpdateUserError' }),
  });

  useEffect(() => {
    if (state.updateUserStatus === 'Ready') {
      dispatch({ type: 'UpdateUserStarted' });
      mutation.mutate();
    }
  }, [dispatch, mutation, state.updateUserStatus]);
};

export default useGenerateInviteCodeEffect;
