import React from 'react';
import { useIntl } from 'react-intl';
import { connectedPixelColumnClassName, connectedPixelIdClassName } from './styles';
import ConnectedPixelStatus from './ConnectedPixelStatus';
import { PixelConfig } from 'types';

interface Props {
  value: string;
  row: {
    original: PixelConfig;
  };
}

const ConnectedPixelColumnRenderer: React.FC<Props> = ({ value: pixelId, row }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={connectedPixelColumnClassName}>
      <p className={connectedPixelIdClassName}>
        {row.original.name ??
          formatMessage(
            {
              defaultMessage: 'ID: {pixelId}',
              description: 'Connected pixel ID',
            },
            {
              pixelId,
            },
          )}
      </p>
      <ConnectedPixelStatus pixelId={pixelId} />
    </div>
  );
};

export default ConnectedPixelColumnRenderer;
