import { useQuery } from '@tanstack/react-query';
import { getConnectPixelRedirectUrl } from 'api/api';
import assertUnreachable from 'util/assertUnreachable';

interface Loading {
  status: 'Loading';
}

interface Success {
  status: 'Success';
  url: string;
}

interface Error {
  status: 'Error';
}

const useConnectPixelRedirectUrl = (): Loading | Error | Success => {
  const data = useQuery({
    queryKey: ['connectPixelRedirectUrl'],
    queryFn: () => getConnectPixelRedirectUrl(),
    refetchInterval: false,
  });

  switch (data.status) {
    case 'pending':
      return { status: 'Loading' };
    case 'error':
      return { status: 'Error' };
    case 'success':
      return { status: 'Success', url: data.data.data.redirect_url };
    default:
      return assertUnreachable(data);
  }
};

export default useConnectPixelRedirectUrl;
