import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Notification } from '@snapchat/snap-design-system';
import { disconnectPixel } from 'api/api';
import { useTenantId } from 'tenant';

const useDisconnectPixel = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const disconnectPixelMutation = useMutation({
    mutationFn: async (pixelId: string) => disconnectPixel({ tenant_id: tenantId, pixel_id: pixelId }),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to connect Pixel to Conversions API Gateway',
          description: 'Error message when connecting pixel to CAPI Gateway fails',
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['connectedPixels'] });
      success({
        title: formatMessage({
          defaultMessage: 'Successfully disconnected Pixel from Conversions API Gateway',
          description: 'Success message when connecting pixel to CAPI Gateway succeeds',
        }),
      });
      navigate('/');
    },
  });

  return disconnectPixelMutation;
};

export default useDisconnectPixel;
