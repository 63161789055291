import { Role } from 'api/types';
import { Reducer, useReducer } from 'react';
import { QueryStatus } from 'types';
import assertUnreachable from 'util/assertUnreachable';

export interface State {
  step: 'RoleSelection' | 'CopyLink';
  selectedRole: Role;
  visible: boolean;
  generateInviteCodeStatus: QueryStatus;
  inviteUrl?: string;
  name?: string;
}

interface RoleSelectedEvent {
  type: 'RoleSelected';
  role: Role;
}

interface NextButtonClicked {
  type: 'NextButtonClicked';
}

interface ModalClosed {
  type: 'ModalClosed';
}

interface ModalOpened {
  type: 'ModalOpened';
}

interface GenerateInviteCodeStarted {
  type: 'GenerateInviteCodeStarted';
}

interface GenerateInviteCodeSuccess {
  type: 'GenerateInviteCodeSuccess';
  inviteUrl: string;
}

interface GenerateInviteCodeError {
  type: 'GenerateInviteCodeError';
}

interface NameChanged {
  type: 'NameChanged';
  name: string;
}

interface ViewInviteClicked {
  type: 'ViewInviteClicked';
  inviteUrl: string;
}

export type Event =
  | RoleSelectedEvent
  | NextButtonClicked
  | ModalClosed
  | ModalOpened
  | GenerateInviteCodeStarted
  | GenerateInviteCodeSuccess
  | GenerateInviteCodeError
  | NameChanged
  | ViewInviteClicked;

const initialState: State = {
  step: 'RoleSelection',
  selectedRole: Role.Member,
  visible: false,
  generateInviteCodeStatus: 'None',
};

const reducer = (state: State, event: Event): State => {
  switch (event.type) {
    case 'RoleSelected':
      return {
        ...state,
        selectedRole: event.role,
      };
    case 'NextButtonClicked': {
      switch (state.step) {
        case 'RoleSelection':
          return {
            ...state,
            generateInviteCodeStatus: 'Ready',
          };
        case 'CopyLink':
          return {
            ...state,
            visible: false,
          };
        default:
          return assertUnreachable(state.step);
      }
    }
    case 'ModalClosed':
      return initialState;
    case 'ModalOpened':
      return {
        ...initialState,
        visible: true,
      };
    case 'GenerateInviteCodeStarted':
      return {
        ...state,
        generateInviteCodeStatus: 'Loading',
      };
    case 'GenerateInviteCodeError':
      return {
        ...state,
        generateInviteCodeStatus: 'Error',
      };
    case 'GenerateInviteCodeSuccess':
      return {
        ...state,
        step: 'CopyLink',
        generateInviteCodeStatus: 'Success',
        inviteUrl: event.inviteUrl,
      };
    case 'NameChanged':
      return {
        ...state,
        name: event.name,
      };
    case 'ViewInviteClicked':
      return {
        ...state,
        visible: true,
        step: 'CopyLink',
        inviteUrl: event.inviteUrl,
      };
    default:
      return assertUnreachable(event);
  }
};

const useAddUserModalReducer = () => {
  return useReducer<Reducer<State, Event>>(reducer, initialState);
};

export default useAddUserModalReducer;
