import { useQuery } from '@tanstack/react-query';
import { getConnectedPixels } from 'api/api';
import { useTenantId } from 'tenant';
import { fromJson } from 'translators/PixelConfigTranslator';

const useConnectedPixels = () => {
  const tenantId = useTenantId();
  const connectedPixels = useQuery({
    queryKey: ['connectedPixels', tenantId],
    queryFn: async () => {
      const pixels = await getConnectedPixels(tenantId);
      return pixels.pixels.map(fromJson);
    },
  });

  return connectedPixels;
};

export default useConnectedPixels;
