import { useQuery } from '@tanstack/react-query';
import { userId } from '../users/userId';
import { getUserConfigs } from 'api/api';
import { Role } from 'api/types';
import assertUnreachable from 'util/assertUnreachable';
import { useAtom } from 'jotai';
import { tenantIdAtom } from 'tenant/useTenantId';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  tenants: { tenantId?: string; role?: Role; tenantName?: string; userName?: string }[];
}

const useUserTenants = (): Loading | Error | Success => {
  const [tenantId, setTenantId] = useAtom(tenantIdAtom);
  const data = useQuery({
    queryKey: ['tenants', userId],
    queryFn: async () => await getUserConfigs(userId!),
    enabled: !!userId,
  });

  switch (data.status) {
    case 'error':
      return { status: 'Error' };
    case 'pending':
      return { status: 'Loading' };
    case 'success': {
      if (!tenantId || !data.data?.data?.tenants?.some((config) => config.tenant_id === tenantId)) {
        setTenantId(data.data?.data?.tenants?.[0]?.tenant_id ?? '');
      }

      return {
        status: 'Success',
        tenants:
          data.data.data?.tenants?.map((config) => ({
            tenantId: config.tenant_id,
            role: config.role,
            tenantName: config.tenant_name,
            userName: config.user_name,
          })) ?? [],
      };
    }
    default:
      return assertUnreachable(data);
  }
};

export default useUserTenants;
