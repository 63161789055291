import { Section, Skeleton } from '@snapchat/snap-design-system';
import React from 'react';

const DataRoutingLoadingSection: React.FC = () => {
  return (
    <Section>
      <div>
        <h4 style={{ maxWidth: 400 }}>
          <Skeleton />
        </h4>
        <Skeleton paragraph={2} />
      </div>
    </Section>
  );
};

export default DataRoutingLoadingSection;
