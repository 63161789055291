import { verifyCloudflareToken } from 'api/api';
import { Dispatch, useEffect } from 'react';
import { Events, State } from './useCloudflareTokenInputReducer';
import { useTenantId } from 'tenant';

const useValidateCloudflareTokenEffect = (dispatch: Dispatch<Events>, state: State) => {
  const tenantId = useTenantId();

  useEffect(() => {
    const validate = async (token: string) => {
      dispatch({ type: 'ValidateTokenStarted' });
      try {
        const response = await verifyCloudflareToken(tenantId, token);
        dispatch({ type: 'ValidateTokenSucceeded', valid: response.data });
      } catch {
        dispatch({ type: 'ValidateTokenFailed' });
      }
    };

    if (state.tokenValidationStatus === 'Ready') {
      validate(state.token ?? '');
    }
  }, [tenantId, state.tokenValidationStatus, dispatch, state.token]);
};

export default useValidateCloudflareTokenEffect;
