import React from 'react';
import { pageClassName } from '../styles';
import NavBar from 'root/NavBar';
import LoadingPage from '../LoadingPage';
import useAcceptInvitation from './useAcceptInvitation';
import assertUnreachable from 'util/assertUnreachable';
import AdminInvitePage from './AdminInvitePage';

const AcceptInvitePage: React.FC = () => {
  const data = useAcceptInvitation();

  switch (data.status) {
    case 'Error':
      return (
        <div>
          <NavBar />
          <div className={pageClassName}>
            <h1>{data.message}</h1>
          </div>
        </div>
      );
    case 'Loading':
      return <LoadingPage />;
    case 'AdminInvite':
      return <AdminInvitePage />;
    default:
      return assertUnreachable(data);
  }
};

export default AcceptInvitePage;
