import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Notification } from '@snapchat/snap-design-system';
import { deleteTenantSubdomain } from 'api/api';
import { useTenantId } from 'tenant';

const useDeleteSubdomain = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const deleteSubdomainMutation = useMutation({
    mutationFn: async () => deleteTenantSubdomain(tenantId),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to remove data routing setup',
          description: 'Error message when deleting a subdomain',
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tenantConfig', tenantId] });
      success({
        title: formatMessage({
          defaultMessage: 'Data Routing Setup Removed',
          description: 'Success message when deleting a subdomain',
        }),
      });
    },
  });

  return deleteSubdomainMutation;
};

export default useDeleteSubdomain;
