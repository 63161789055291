import { Reducer, useReducer } from 'react';
import { QueryStatus } from 'types';
import assertUnreachable from 'util/assertUnreachable';

export interface State {
  step: 'TenantName' | 'GoToTenant';
  tenantName: string;
  visible: boolean;
  createTenantStatus: QueryStatus;
  newTenantId?: string;
  redirectToNewTenantStatus: QueryStatus;
}

interface TenantNameChangedEvent {
  type: 'TenantNameChanged';
  name: string;
}

interface NextButtonClicked {
  type: 'NextButtonClicked';
}

interface ModalClosed {
  type: 'ModalClosed';
}

interface ModalOpened {
  type: 'ModalOpened';
}

interface CreateTenantStarted {
  type: 'CreateTenantStarted';
}

interface CreateTenantSuccess {
  type: 'CreateTenantSuccess';
  newTenantId: string;
}

interface CreateTenantError {
  type: 'CreateTenantError';
}

export type Event =
  | TenantNameChangedEvent
  | NextButtonClicked
  | ModalClosed
  | ModalOpened
  | CreateTenantStarted
  | CreateTenantSuccess
  | CreateTenantError;

const initialState: State = {
  step: 'TenantName',
  tenantName: '',
  visible: false,
  createTenantStatus: 'None',
  redirectToNewTenantStatus: 'None',
};

const reducer = (state: State, event: Event): State => {
  switch (event.type) {
    case 'TenantNameChanged':
      return {
        ...state,
        tenantName: event.name,
      };
    case 'NextButtonClicked': {
      switch (state.step) {
        case 'TenantName':
          return {
            ...state,
            createTenantStatus: 'Ready',
          };
        case 'GoToTenant':
          return {
            ...state,
            redirectToNewTenantStatus: 'Ready',
          };
        default:
          return assertUnreachable(state.step);
      }
    }
    case 'ModalClosed':
      return initialState;
    case 'ModalOpened':
      return {
        ...initialState,
        visible: true,
      };
    case 'CreateTenantStarted':
      return {
        ...state,
        createTenantStatus: 'Loading',
      };
    case 'CreateTenantError':
      return {
        ...state,
        createTenantStatus: 'Error',
      };
    case 'CreateTenantSuccess':
      return {
        ...state,
        createTenantStatus: 'Success',
        newTenantId: event.newTenantId,
        step: 'GoToTenant',
      };
    default:
      return assertUnreachable(event);
  }
};

const useAddTenantModalReducer = () => {
  return useReducer<Reducer<State, Event>>(reducer, initialState);
};

export default useAddTenantModalReducer;
