import { css, Global } from '@emotion/react';
import React from 'react';
import 'antd/dist/antd.min.css';
import { Colors } from '@snapchat/snap-design-system';

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        body {
          margin: 0;
          font-family: 'Graphik';

          .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border-color: ${Colors.Yellow.V200};
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
