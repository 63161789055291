import { css } from '@emotion/css';
import { Spacing } from '@snapchat/snap-design-system';

export const pageHeaderRightContentClassName = css({
  display: 'flex',
  flexDirection: 'row',
});

export const setupSubdomainAlertClassName = css({
  marginBottom: Spacing.MD,
});
