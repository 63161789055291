import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const tenantIdAtom = atomWithStorage('tenantId', '');

const useTenantId = (): string => {
  const [tenantId] = useAtom(tenantIdAtom);
  return tenantId;
};

export default useTenantId;
