import React from 'react';

import { codeSnippetClassName, preClassName, setupCodePreviewClassName } from './styles';

interface Props {
  codeSnippet: string;
  cardClassName?: string;
  dataTestId?: string;
}
export const SetupCodePreview: React.FC<Props> = ({ codeSnippet, dataTestId }) => {
  return (
    <div className={setupCodePreviewClassName}>
      <pre className={preClassName}>
        <code className={codeSnippetClassName} data-testid={dataTestId ?? 'app.adsMgr.pixelSetupWizard.codeSnippet'}>
          {codeSnippet}
        </code>
      </pre>
    </div>
  );
};
