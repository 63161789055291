import React, { useContext, useState } from 'react';
import useConnectPixelRedirectUrl from './useConnectPixelRedirectUrl';
import { Button, Notification } from '@snapchat/snap-design-system';
import { PlusSignIcon } from '@snapchat/snap-design-system-icons';
import { useIntl } from 'react-intl';
import { buttonContentClassName } from './styles';
import assertUnreachable from 'util/assertUnreachable';
import { useCookies } from 'react-cookie';
import { CONNECT_PIXEL_STATE_COOKIE_NAME } from 'common/constants';

const ConnectPixelButton: React.FC = () => {
  const [clicked, setClicked] = useState(false);
  const { warning } = useContext(Notification.Context);
  const data = useConnectPixelRedirectUrl();
  const { formatMessage } = useIntl();
  const [, setCookie] = useCookies();

  const buttonContent = (
    <span className={buttonContentClassName}>
      <PlusSignIcon />
      {formatMessage({
        defaultMessage: 'Add Data Source',
      })}
    </span>
  );

  switch (data.status) {
    case 'Loading':
      return <Button isPending>{buttonContent}</Button>;
    case 'Error':
      return <Button isDisabled>{buttonContent}</Button>;
    case 'Success':
      return (
        <Button
          onClick={() => {
            if (clicked) {
              warning({
                title: formatMessage({
                  defaultMessage: 'If you are having issues connecting a data source, please disable any ad blockers',
                  description: 'Button clicked repeatedly warning',
                }),
              });
            }
            setClicked(true);
            setCookie(CONNECT_PIXEL_STATE_COOKIE_NAME, new URLSearchParams(data.url).get('state'));
            window.open(data.url, '_self');
          }}
        >
          {buttonContent}
        </Button>
      );
    default:
      return assertUnreachable(data);
  }
};

export default ConnectPixelButton;
