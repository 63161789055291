import { Dropdown, Menu, MenuItem } from '@snapchat/snap-design-system';
import { useAtom } from 'jotai';
import React from 'react';
import useSelectedTenant from 'tenant/useSelectedTenant';
import { tenantIdAtom } from 'tenant/useTenantId';
import { dropdownContainerClassName, dropdownLabelClassName, tenantNameClassName, tenantRoleClassName } from './styles';
import useSessionInfo from 'auth/SessionInfoProvider/useSessionInfo';
import { defineMessages, useIntl } from 'react-intl';
import { ChevronDownIcon } from '@snapchat/snap-design-system-icons';
import { UserRole } from 'users/types';

const roleMessages = defineMessages<UserRole>({
  [UserRole.SuperAdmin]: {
    defaultMessage: '{name} - Admin',
    description: 'Admin role label',
  },
  [UserRole.Admin]: {
    defaultMessage: '{name} - Admin',
    description: 'Admin role label',
  },
  [UserRole.Member]: {
    defaultMessage: '{name} - Member',
    description: 'Member role label',
  },
  [UserRole.SuperMember]: {
    defaultMessage: '{name} - Member',
    description: 'Member role label',
  },
});

const TenantsDropdown: React.FC = () => {
  const { userTenants } = useSessionInfo();
  const { name, role, userName } = useSelectedTenant();
  const [, setTenantId] = useAtom(tenantIdAtom);
  const { formatMessage } = useIntl();

  if (userTenants.length === 1) {
    return (
      <div className={dropdownContainerClassName}>
        <div className={dropdownLabelClassName}>
          <span className={tenantNameClassName}>{name}</span>
          <span className={tenantRoleClassName}>{formatMessage(roleMessages[role], { name: userName })}</span>
        </div>
      </div>
    );
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          {userTenants.map((tenant) => (
            <MenuItem onClick={() => setTenantId(tenant.tenantId ?? '')} key={tenant.tenantId}>
              {tenant.tenantName}
            </MenuItem>
          ))}
        </Menu>
      }
    >
      <div className={dropdownContainerClassName} style={{ cursor: 'pointer' }}>
        <div className={dropdownLabelClassName}>
          <span className={tenantNameClassName}>{name}</span>
          <span className={tenantRoleClassName}>{formatMessage(roleMessages[role], { name: userName })}</span>
        </div>
        <ChevronDownIcon />
      </div>
    </Dropdown>
  );
};

export default TenantsDropdown;
