import { AutoSizer, Section, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { tenantsSectionClassName } from '../styles';
import TenantActionsColumnRenderer from './TenantActionsColumnRenderer';
import { TenantConfig } from 'types';
import EmptyState from 'common/EmptyState';
import TenantNameColumnRenderer from './TenantNameColumnRenderer';

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<TenantConfig>[] = [
  {
    id: 'name',
    accessor: 'tenantId',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Name' description='Header text for name column' />,
    Cell: TenantNameColumnRenderer,
  },
  {
    id: 'actions',
    accessor: 'tenantId',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Actions' description='Header text for actions column' />,
    Cell: TenantActionsColumnRenderer,
  },
];

interface Props {
  tenants: TenantConfig[];
}

const TenantsTable: React.FC<Props> = ({ tenants }) => {
  const { formatMessage } = useIntl();

  return (
    <Section className={tenantsSectionClassName}>
      <div style={{ height: ROW_HEIGHT * (tenants.length + 1) }}>
        <AutoSizer>
          {({ width, height }: { width: number; height: number }) => (
            <VirtualTable
              columns={columns}
              data={tenants}
              loadMore={() => Promise.resolve()}
              hasMore={false}
              height={height}
              width={width}
              rowHeight={ROW_HEIGHT}
              emptyMessage={
                <EmptyState
                  title={formatMessage({
                    defaultMessage: 'No tenants found',
                    description: 'Empty state title for tenants table',
                  })}
                />
              }
            />
          )}
        </AutoSizer>
      </div>
    </Section>
  );
};

export default TenantsTable;
