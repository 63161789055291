import { useMutation } from '@tanstack/react-query';
import { acceptInvite, updateTenantConfig, updateUser } from 'api/api';
import { TenantStatus, UserJson } from 'api/types';
import { ADMIN_TENANT_ID, ROUTES } from 'common/constants';
import { useAtom } from 'jotai';
import useConnectPixel from 'overview/AddPixelConnectionModal/useConnectPixel';
import usePixelParamData from 'overview/AddPixelConnectionModal/usePixelParamData';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tenantIdAtom } from 'tenant/useTenantId';
import { toJson } from 'translators/TenantConfigTranslator';

const useAcceptAdminInvite = () => {
  const connectPixel = useConnectPixel();
  const pixelParamData = usePixelParamData();
  const navigate = useNavigate();
  const [, setTenantId] = useAtom(tenantIdAtom);
  const [userConfig, setUserConfig] = useState<UserJson>();

  const mutation = useMutation({
    mutationKey: ['accept-invite'],
    mutationFn: async () => acceptInvite(ADMIN_TENANT_ID, ADMIN_TENANT_ID),
    onSuccess: async (response) => {
      setUserConfig(response.data);
    },
    onError: async (error: { response: { status: number } }) => {
      if (error.response.status === 404) {
        navigate(ROUTES.OVERVIEW, { replace: true });
      }
    },
  });

  useEffect(() => {
    mutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return async (userName: string, adminTenantName: string) => {
    setTenantId(ADMIN_TENANT_ID);
    // At this point the invite has been accepted so even if the subsequent calls fail,
    // the user is logged in and can proceed to the overview page.
    try {
      await updateUser(ADMIN_TENANT_ID, {
        ...userConfig,
        name: userName,
      });

      if (pixelParamData.type === 'Valid') {
        await connectPixel.mutate({
          pixel_id: pixelParamData.pixelId,
          capi_token: pixelParamData.capiToken,
          tenant_id: ADMIN_TENANT_ID,
          name: pixelParamData.name,
        });
      }

      await updateTenantConfig(
        toJson({
          name: adminTenantName,
          tenantId: ADMIN_TENANT_ID,
          status: TenantStatus.Active,
        }),
      );
    } catch (error) {}

    navigate(ROUTES.OVERVIEW);
  };
};

export default useAcceptAdminInvite;
