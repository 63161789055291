import React, { PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthorizeUser from './useAuthorizeUser';
import { ROUTES } from '../../common/constants';
import assertUnreachable from 'util/assertUnreachable';
import LoadingPage from 'auth/LoadingPage';
import SessionInfoProvider from 'auth/SessionInfoProvider';
import ConfirmLogoutModalProvider from 'auth/ConfirmLogoutModalProvider';

const AuthRedirect: React.FC<PropsWithChildren> = ({ children }) => {
  const data = useAuthorizeUser();

  switch (data.status) {
    case 'Loading':
      return <LoadingPage />;
    case 'Error':
      return <Navigate to={ROUTES.LOGIN} />;
    case 'Success':
      return (
        <SessionInfoProvider sessionInfo={data.sessionInfo}>
          <ConfirmLogoutModalProvider>
            <Outlet />
          </ConfirmLogoutModalProvider>
        </SessionInfoProvider>
      );
    default:
      assertUnreachable(data);
  }

  return null;
};

export default AuthRedirect;
