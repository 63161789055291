import React, { PropsWithChildren } from 'react';
import { AddTenantModalContext } from './AddTenantModalContext';
import { defineMessages, useIntl } from 'react-intl';
import useAddTenantModalReducer, { State } from './useAddTenantModalReducer';
import { Modal } from '@snapchat/snap-design-system';
import AddTenantModalContent from './AddTenantModalContent';
import useCreateTenantEffect from './useCreateTenantEffect';
import useRedirectToNewTenantEffect from './useRedirectToNewTenantEffect';

const actionButtonMessage = defineMessages<State['step']>({
  GoToTenant: {
    defaultMessage: 'Manage',
    description: 'Copy link step button text',
  },
  TenantName: {
    defaultMessage: 'Create',
    description: 'Next button text',
  },
});

const AddTenantModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useAddTenantModalReducer();
  useCreateTenantEffect(dispatch, state);
  useRedirectToNewTenantEffect(dispatch, state);

  return (
    <AddTenantModalContext.Provider value={{ open: () => dispatch({ type: 'ModalOpened' }) }}>
      <Modal
        visible={state.visible}
        title={formatMessage({
          defaultMessage: 'Create New Account',
          description: 'Add tenant modal title',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage(actionButtonMessage[state.step])}
        isActionButtonPending={state.createTenantStatus === 'Loading'}
        isActionButtonDisabled={!state.tenantName}
        onCancel={() => dispatch({ type: 'ModalClosed' })}
        onAction={() => dispatch({ type: 'NextButtonClicked' })}
      >
        <AddTenantModalContent dispatch={dispatch} {...state} />
      </Modal>
      {children}
    </AddTenantModalContext.Provider>
  );
};

export default AddTenantModalProvider;
