import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { State, Event } from './useGenerateTokenModalReducer';
import assertUnreachable from 'util/assertUnreachable';
import { Button, Input } from '@snapchat/snap-design-system';
import { copyLinkInputClassName, copyLinkRowClassName, nameInputClassName } from './styles';
import { CheckIcon } from '@snapchat/snap-design-system-icons';

interface Props extends State {
  dispatch: React.Dispatch<Event>;
}

const GenerateTokenModalContent: React.FC<Props> = ({ dispatch, step, generateTokenStatus, tokenValue, name }) => {
  const { formatMessage } = useIntl();
  const [copied, setCopied] = useState(false);

  switch (step) {
    case 'CopyToken':
      return (
        <div>
          <p>
            {formatMessage({
              defaultMessage: 'Copy this token and store it securely. You will not be able to view its value again.',
              description: 'Copy link step text',
            })}
          </p>
          <div className={copyLinkRowClassName}>
            <Input value={tokenValue} className={copyLinkInputClassName} />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(tokenValue ?? '');
                setCopied(true);
              }}
            >
              {copied ? (
                <>
                  <CheckIcon />
                  {formatMessage({
                    defaultMessage: 'Copied',
                    description: 'Copied button text',
                  })}
                </>
              ) : (
                formatMessage({
                  defaultMessage: 'Copy Token',
                  description: 'Copy token button text',
                })
              )}
            </Button>
          </div>
        </div>
      );
    case 'NameInput':
      return (
        <div>
          <Input
            value={name}
            onChange={(name) => dispatch({ type: 'NameChanged', name })}
            label={formatMessage({
              defaultMessage: 'Name',
              description: 'Name input label',
            })}
            placeholder={formatMessage({
              defaultMessage: 'Enter token name',
              description: 'Name input placeholder',
            })}
            className={nameInputClassName}
          />
        </div>
      );
    default:
      return assertUnreachable(step);
  }
};

export default GenerateTokenModalContent;
