import React, { PropsWithChildren } from 'react';
import GlobalStyles from './GlobalStyles';
import { ConfigProvider, Notification } from '@snapchat/snap-design-system';
import enUS from '@snapchat/snap-design-system/es/locales/enUS';
import IntlWrap from './IntlWrap';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const RootContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider locale={enUS}>
      <IntlWrap>
        <Notification.Provider>
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            {children}
          </QueryClientProvider>
        </Notification.Provider>
      </IntlWrap>
    </ConfigProvider>
  );
};

export default RootContainer;
