import { DateRangeInputJson } from 'api/types';
import { DateRange } from 'types';

const toDateRangeInput = (dateRange: DateRange): DateRangeInputJson => {
  return {
    from: dateRange.from?.getTime(),
    to: dateRange.to?.getTime(),
  };
};

export default toDateRangeInput;
