import { Button, Section } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import { setupSectionLayoutClassName } from './styles';
import { useSetupSubdomainModal } from './SetupSubdomainModalProvider';

const DataRoutingSetupSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const { open } = useSetupSubdomainModal();

  return (
    <Section>
      <div className={setupSectionLayoutClassName}>
        <div>
          <h4>
            {formatMessage({
              defaultMessage: 'Optimize Data Routing to Improve Performance',
              description: 'Data Routing Setup section title',
            })}
          </h4>
          <p>
            {formatMessage({
              defaultMessage:
                'Using a first-party domain can help you track ad performance more accurately. To get started, add a new subdomain through your DNS provider using the primary domain linked to this account.',
              description: 'Data Routing Setup section description',
            })}
          </p>
        </div>
        <div>
          <Button type='primary' onClick={open}>
            {formatMessage({
              defaultMessage: 'Add Subdomain',
              description: 'Add subdomain button text',
            })}
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default DataRoutingSetupSection;
