import { css } from '@emotion/css';

import { Colors, Spacing } from '@snapchat/snap-design-system';
import { SMALL_DEVICE_MQ } from 'common/constants';

export const layoutClassName = css({
  display: 'grid',
  gridTemplateColumns: '270px auto',
  height: 'calc(100vh - 54px)',
  overflow: 'hidden',

  [SMALL_DEVICE_MQ]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
    position: 'relative',
  },
});

export const sideNavClassName = css({
  backgroundColor: Colors.Gray.V100,
  paddingTop: Spacing.XS,

  [SMALL_DEVICE_MQ]: {
    paddingTop: 0,
  },
});

export const mainClassName = css({
  padding: Spacing.LG,
  backgroundColor: Colors.Gray.V50,
  overflowY: 'scroll',
});

export const sideNavDividerClassName = css({
  borderTop: `1px solid ${Colors.Gray.V200}`,
  width: '100%',
});
