import React from 'react';
import { useIntl } from 'react-intl';
import EVENT_NAME_MESSAGES from './messages';

interface Props {
  value: string;
}

const EventNameColumnRenderer: React.FC<Props> = ({ value: eventName }) => {
  const { formatMessage } = useIntl();

  if (EVENT_NAME_MESSAGES[eventName]) {
    return <>{formatMessage(EVENT_NAME_MESSAGES[eventName])}</>;
  }

  return <>{eventName}</>;
};

export default EventNameColumnRenderer;
