import { useQuery } from '@tanstack/react-query';
import { getUsers } from 'api/api';
import { useTenantId } from 'tenant';
import { User } from './types';
import { fromJson } from 'translators/UserTranslator';
import assertUnreachable from 'util/assertUnreachable';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  users: User[];
}

const useUsers = (): Loading | Error | Success => {
  const tenantId = useTenantId();

  const query = useQuery({
    queryKey: ['users', tenantId],
    queryFn: () => getUsers(tenantId),
  });

  switch (query.status) {
    case 'error':
      return { status: 'Error' };
    case 'pending':
      return { status: 'Loading' };
    case 'success': {
      return { status: 'Success', users: query.data?.data.users.map(fromJson) };
    }
    default:
      return assertUnreachable(query);
  }
};

export default useUsers;
