import { css } from '@emotion/css';
import { Fonts, Spacing } from '@snapchat/snap-design-system';

export const sectionBodyClassName = css({
  display: 'block',
  minHeight: '120px',
  maxHeight: '500px',
  overflow: 'hidden',
});

export const actionDropdownClassName = css({
  cursor: 'pointer',
});

export const connectedPixelColumnClassName = css({
  display: 'flex',
  flexDirection: 'column',
  gap: Spacing['2XS'],
});

export const connectedPixelIdClassName = css({
  ...Fonts.Paragraph.P300,
  fontWeight: 500,
  margin: 0,
});
