import { useContext } from 'react';
import { AddTenantModalContext } from './AddTenantModalContext';

const useAddTenantModal = () => {
  const { open } = useContext(AddTenantModalContext);

  return { open };
};

export default useAddTenantModal;
