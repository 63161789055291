export const SMALL_DEVICE_BREAKPOINT = 480;
export const MEDIUM_DEVICE_BREAKPOINT = 768;
export const LARGE_DEVICE_BREAKPOINT = 1920;

export const SMALL_DEVICE_MQ = `@media (max-width: ${SMALL_DEVICE_BREAKPOINT}px)`;
export const MEDIUM_DEVICE_MQ = `@media (max-width: ${MEDIUM_DEVICE_BREAKPOINT}px)`;
export const LARGE_DEVICE_MQ = `@media (min-width: ${LARGE_DEVICE_BREAKPOINT}px)`;

export const ROUTES = {
  OVERVIEW: '/',
  DATA_ROUTING: '/data-routing',
  LOGIN: '/login',
  TENANTS: '/accounts',
  USERS: '/users',
  ACCEPT_INVITE: '/accept-invite',
  SETTINGS: '/settings',
  NOT_FOUND: '/notfound',
};

export const CONTACT_SUPPORT_URL = 'https://businesshelp.snapchat.com/s/contactsupport';

export const ADMIN_TENANT_ID = 'admin';

export const CONNECT_PIXEL_STATE_COOKIE_NAME = 'connect_pixel_state';
export const CONNECTED_PIXEL_STATUS_COOKIE_NAME_PREFIX = 'connected_pixel_status_';
