import { useQuery } from '@tanstack/react-query';
import useSetupSubdomainModal from './useSetupSubdomainModal';
import assertUnreachable from 'util/assertUnreachable';
import { getDnsInfo } from 'api/api';
import { useTenantId } from 'tenant';

const useDNSRecordsStatus = (): 'Ready' | 'NotReady' => {
  const tenantId = useTenantId();
  const { visible, step } = useSetupSubdomainModal();

  const { status, data } = useQuery({
    queryKey: ['subdomainStatus'],
    queryFn: async () => await getDnsInfo(tenantId ?? ''),
    enabled: visible && step === 'ConfirmSubdomain',
    retry: false,
    refetchInterval: 5000,
  });

  switch (status) {
    case 'error':
    case 'pending':
      return 'NotReady';
    case 'success':
      if (data.data.is_custom_hostname_ready) {
        return 'Ready';
      }
      return 'NotReady';
    default:
      return assertUnreachable(status);
  }
};

export default useDNSRecordsStatus;
