import { Alert } from '@snapchat/snap-design-system';
import { ROUTES } from '../common/constants';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import assertUnreachable from 'util/assertUnreachable';
import useSubdomainSetupStatus from 'data-routing/useSubdomainSetupStatus';
import { setupSubdomainAlertClassName } from './styles';

const SetupSubdomainAlert: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = useSubdomainSetupStatus();
  const navigate = useNavigate();

  switch (data.status) {
    case 'Error':
    case 'Loading':
      return null;
    case 'SubdomainNotActive':
    case 'NotSetup':
      return (
        <Alert
          className={setupSubdomainAlertClassName}
          status='warning'
          title={formatMessage({
            defaultMessage:
              'Track ad performance more accurately by using a first-party domain. To get started, add a new subdomain through your DNS provider',
            description: 'Data Routing page title',
          })}
          actionText={formatMessage({
            defaultMessage: 'Get Started',
            description: 'Configure DNS button text',
          })}
          onActionClick={() => navigate(ROUTES.DATA_ROUTING)}
        />
      );
    case 'Setup': {
      if (data.pixelHostingEnabled) {
        return (
          <Alert
            className={setupSubdomainAlertClassName}
            status='info'
            title={formatMessage({
              defaultMessage: "Have you pointed your website's Snap Pixel code to your CAPI Gateway?",
              description: 'Setup Pixel JS hosting title',
            })}
            actionText={formatMessage({
              defaultMessage: 'View Instructions',
              description: 'Configure DNS button text',
            })}
            onActionClick={() => navigate(ROUTES.DATA_ROUTING)}
          />
        );
      }
      return null;
    }
    default:
      return assertUnreachable(data);
  }
};

export default SetupSubdomainAlert;
