import { css } from '@emotion/css';
import { Colors, Spacing } from '@snapchat/snap-design-system';

export const pageClassName = css({
  alignItems: 'center',
  backgroundColor: Colors.Gray.V50,
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 54px)',
  justifyContent: 'center',
  padding: Spacing.LG,
});

export const sectionClassName = css({
  maxWidth: '600px',
  width: '90%',
});

export const sectionBodyClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: Spacing.MD,
});

export const inputClassName = css({
  width: '100%',
});
