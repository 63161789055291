import { useEffect, useState } from 'react';

import {
  DEFAULT_LOCALE_CODE,
  getLocaleFromBrowserSettings,
  getLocaleFromPath,
  getValidLocaleCode,
  LocaleCode,
} from '@snapchat/ads-l10n';

import { SDS_LOCALES } from './constants';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';

export const localeAtom = atomWithStorage<LocaleCode>('locale', getInitLocale());

/**
 * Get initial locale from path, browser or default in order.
 * @returns locale
 */
function getInitLocale() {
  return getValidLocaleCode(
    [getLocaleFromPath(), getLocaleFromBrowserSettings(), DEFAULT_LOCALE_CODE].find(Boolean) as string,
  );
}

async function fallback<T>(asyncFunc: () => Promise<T>, fallbackAsyncFunc: () => Promise<T>) {
  try {
    return await asyncFunc();
  } catch (e) {
    return fallbackAsyncFunc();
  }
}

/**
 * Fetch all required messages for locale.
 *
 * @param localeCode
 * @returns merged messages
 */
async function fetchLocaleMessages(localeCode: LocaleCode) {
  const possibleLocaleForSDS = SDS_LOCALES[localeCode as keyof typeof SDS_LOCALES];
  const [languageCode] = localeCode.split('-');

  const [messages, sdsMessages, ...polyfillsMessages] = await Promise.all([
    fallback(
      () => import(`../../lang/${localeCode}.json`),
      () => import('../../lang/en-US.json'),
    ),
    fallback(
      () => import(`@snapchat/snap-design-system/es/locales/${possibleLocaleForSDS}.js`),
      () => import('@snapchat/snap-design-system/es/locales/enUS.js'),
    ),
    fallback(
      () => import(`@formatjs/intl-pluralrules/locale-data/${languageCode}`),
      () => import('@formatjs/intl-pluralrules/locale-data/en'),
    ),
    fallback(
      () => import(`@formatjs/intl-relativetimeformat/locale-data/${languageCode}`),
      () => import('@formatjs/intl-relativetimeformat/locale-data/en'),
    ),
  ]);

  return {
    messages,
    sdsMessages,
    polyfillsMessages,
  };
}

function setDocumentLang(localeCode: LocaleCode) {
  try {
    const lang = localeCode ? new Intl.Locale(localeCode).language : localeCode;
    if (lang) {
      document.documentElement.lang = lang;
    }
  } catch (e) {}
}

export const useIntlState = () => {
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useAtom<LocaleCode>(localeAtom);
  const [localeDataForSds, setLocaleDataForSds] = useState({ locale });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const fetched = await fetchLocaleMessages(locale);
        setMessages(fetched.messages);
        setLocaleDataForSds(fetched.sdsMessages.default);
        setDocumentLang(locale);
      } finally {
        setLoading(false);
      }
    })();
  }, [locale]);

  return {
    messages,
    locale,
    localeDataForSds,
    loading,
    setLocale,
  };
};
