import React from 'react';
import { useIntl } from 'react-intl';
import { State, Event } from './useDeactivateTenantModalReducer';

interface Props extends State {
  dispatch: React.Dispatch<Event>;
}

const DeactivateTenantModalContent: React.FC<Props> = ({ tenant }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <p>
        {formatMessage(
          {
            defaultMessage:
              'Deactivating {name} will pause all Pixel event publishing for this tenant. Are you sure you want to deactivate this account?',
          },
          { name: tenant?.name ?? '' },
        )}
      </p>
    </div>
  );
};

export default DeactivateTenantModalContent;
