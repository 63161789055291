import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { Notification } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import { pausePixelConnection } from 'api/api';
import { useTenantId } from 'tenant';

const usePausePixelConnection = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const tenantId = useTenantId();

  const pausePixelMutation = useMutation({
    mutationFn: async (pixelId: string) => pausePixelConnection(tenantId, pixelId),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to pause Pixel connection',
          description: 'Error message when pausing pixel connected to CAPI Gateway fails',
        }),
      });
    },
    onSuccess: () => {
      success({
        title: formatMessage({
          defaultMessage:
            'Successfully paused Pixel connection. It may take up to 20 minutes for the updated status to reflect.',
          description: 'Success message when pausing pixel connected to CAPI Gateway succeeds',
        }),
        duration: 5000,
      });
    },
  });

  return pausePixelMutation;
};

export default usePausePixelConnection;
