import React, { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import { ConfigProvider } from '@snapchat/snap-design-system';

import { useIntlState } from './useIntlState';
import { LOCALE_DIRECTION } from './constants';

export const IntlWrap: React.FC<PropsWithChildren> = ({ children }) => {
  const { localeDataForSds, locale, messages, loading } = useIntlState();

  if (loading || Object.values(messages).length === 0) {
    return null;
  }

  return (
    <ConfigProvider
      locale={localeDataForSds}
      direction={LOCALE_DIRECTION[locale] ?? (document.documentElement.getAttribute('dir') as 'ltr' | 'rtl')}
    >
      <IntlProvider locale={locale} defaultLocale='en-US' textComponent='span' messages={messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
};

IntlWrap.displayName = 'IntlWrap';

export default IntlWrap;
