import { useIntl } from 'react-intl';

import { LOCALE_CODE_MAP, LOCALE_TITLES, LocaleCode } from '@snapchat/ads-l10n';
import { useAtom } from 'jotai';
import { localeAtom } from 'root/IntlWrap/useIntlState';

export interface LocaleProps {
  localeCode: string;
  setLocaleCode: (localeCode: LocaleCode) => void;
  localesList: Array<{ localeCode: LocaleCode; title: string }>;
}

const availableLocales: LocaleCode[] = [
  LOCALE_CODE_MAP.ar,
  LOCALE_CODE_MAP.enUS,
  LOCALE_CODE_MAP.frFR,
  LOCALE_CODE_MAP.deDE,
  LOCALE_CODE_MAP.enGB,
  LOCALE_CODE_MAP.es,
  LOCALE_CODE_MAP.esES,
  LOCALE_CODE_MAP.esMX,
  LOCALE_CODE_MAP.zhHans,
];

const LOCALE_CODES_SORTED: string[] = [
  LOCALE_CODE_MAP.ar,
  LOCALE_CODE_MAP.zhHans,
  LOCALE_CODE_MAP.enGB,
  LOCALE_CODE_MAP.enUS,
  LOCALE_CODE_MAP.frFR,
  LOCALE_CODE_MAP.deDE,
  LOCALE_CODE_MAP.es,
  LOCALE_CODE_MAP.esES,
  LOCALE_CODE_MAP.esMX,
];

export const useLanguageSelector = (): LocaleProps => {
  const { locale } = useIntl();
  const [, setLocale] = useAtom(localeAtom);

  const localesList = availableLocales
    .map((localeCode: keyof typeof LOCALE_TITLES) => ({
      localeCode,
      title: LOCALE_TITLES[localeCode],
    }))
    .sort((a, b) => LOCALE_CODES_SORTED.indexOf(a.localeCode) - LOCALE_CODES_SORTED.indexOf(b.localeCode));

  const setLocaleCode = (localeCode: LocaleCode) => {
    setLocale(localeCode);
  };

  return {
    localeCode: locale,
    setLocaleCode,
    localesList,
  };
};
