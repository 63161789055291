import React from 'react';
import { useIntl } from 'react-intl';
import { PixelEventsData } from 'types';
import { eventCountColumnClassName } from './styles';
import useFormatTimestamp from 'overview/useFormatTimestamp';

export interface Props {
  value: number;
  row: {
    original: PixelEventsData;
  };
  timestampKey: 'lastReceivedAgoMillis' | 'lastSentAgoMillis';
}

const EventCountColumnRenderer: React.FC<Props> = ({ row, value, timestampKey }) => {
  const formatTimestamp = useFormatTimestamp();
  const { formatNumber, formatMessage } = useIntl();
  const values = row.original;

  return (
    <div className={eventCountColumnClassName}>
      <span>{formatNumber(value)}</span>
      {values[timestampKey] && (
        <span>
          {timestampKey === 'lastReceivedAgoMillis'
            ? formatMessage(
                {
                  defaultMessage: 'Last received {timeAgo}',
                  description: 'Text for last received time ago',
                },
                {
                  timeAgo: formatTimestamp(values[timestampKey] as number),
                },
              )
            : formatMessage(
                {
                  defaultMessage: 'Last sent {timeAgo}',
                  description: 'Text for last sent time ago',
                },
                {
                  timeAgo: formatTimestamp(values[timestampKey] as number),
                },
              )}
        </span>
      )}
    </div>
  );
};

export default EventCountColumnRenderer;
