import React, { PropsWithChildren } from 'react';
import {
  eventsOverviewItemClassName,
  eventsOverviewItemTitleClassName,
  eventsOverviewItemValueClassName,
  eventsOverviewSplitItemValueClassName,
  eventsOverviewRowClassName,
  eventsOverviewSplitItemTopSectionClassName,
} from './styles';
import { useIntl } from 'react-intl';
import useEventOverviewData from './useEventOverviewData';
import assertUnreachable from 'util/assertUnreachable';
import { Skeleton } from '@snapchat/snap-design-system';
import GenericError from 'common/GenericError';

const OverviewItem: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className={eventsOverviewItemClassName}>{children}</div>;
};

interface OverviewSplitItemProps {
  topTitle: string;
  topValue: number;
  bottomTitle: string;
  bottomValue: number;
}

const OverviewSplitItem: React.FC<OverviewSplitItemProps> = ({ topTitle, topValue, bottomTitle, bottomValue }) => {
  return (
    <OverviewItem>
      <div>
        <div className={eventsOverviewSplitItemTopSectionClassName}>
          <h6 className={eventsOverviewItemTitleClassName}>{topTitle}</h6>
          <h2 className={eventsOverviewSplitItemValueClassName}>{topValue.toLocaleString()}</h2>
        </div>
        <div>
          <h6 className={eventsOverviewItemTitleClassName}>{bottomTitle}</h6>
          <h2 className={eventsOverviewSplitItemValueClassName}>{bottomValue.toLocaleString()}</h2>
        </div>
      </div>
    </OverviewItem>
  );
};

const OverviewSplitItemLoading: React.FC<{ topTitle: string; bottomTitle: string }> = ({ topTitle, bottomTitle }) => {
  return (
    <OverviewItem>
      <div>
        <div className={eventsOverviewSplitItemTopSectionClassName}>
          <h6 className={eventsOverviewItemTitleClassName}>{topTitle}</h6>
          <h2 className={eventsOverviewSplitItemValueClassName}>
            <Skeleton />
          </h2>
        </div>
        <div>
          <h6 className={eventsOverviewItemTitleClassName}>{bottomTitle}</h6>
          <h2 className={eventsOverviewSplitItemValueClassName}>
            <Skeleton />
          </h2>
        </div>
      </div>
    </OverviewItem>
  );
};

const PixelEventsOverviewRow = () => {
  const { formatMessage } = useIntl();
  const data = useEventOverviewData();

  const eventTypesReceivedTitle = formatMessage({
    description: 'Pixel events overview row top title',
    defaultMessage: 'Event Types Received',
  });

  const eventCountReceivedTitle = formatMessage({
    description: 'Pixel events overview row bottom title',
    defaultMessage: 'Event Count Received',
  });

  const eventTypesSentTitle = formatMessage({
    description: 'Pixel events overview sent row top title',
    defaultMessage: 'Event Types Sent',
  });

  const eventCountSentTitle = formatMessage({
    description: 'Pixel events overview sent row bottom title',
    defaultMessage: 'Event Count Sent',
  });

  const successRateTitle = formatMessage({
    description: 'Pixel events overview row first section title',
    defaultMessage: 'Success Rate',
  });

  const successRateSubtitle = formatMessage({
    description: 'Pixel events overview row first section description',
    defaultMessage: 'The percentage of events successfully received by Conversions API Gateway',
  });

  switch (data.status) {
    case 'Error':
      return <GenericError />;
    case 'Loading':
      return (
        <div className={eventsOverviewRowClassName}>
          <OverviewItem>
            <h6 className={eventsOverviewItemTitleClassName}>{successRateTitle}</h6>
            <h2 className={eventsOverviewItemValueClassName}>
              <Skeleton height={36} />
            </h2>
            <p className={eventsOverviewItemTitleClassName}>{successRateSubtitle}</p>
          </OverviewItem>
          <OverviewSplitItemLoading topTitle={eventTypesReceivedTitle} bottomTitle={eventCountReceivedTitle} />
          <OverviewSplitItemLoading topTitle={eventTypesSentTitle} bottomTitle={eventCountSentTitle} />
        </div>
      );
    case 'Success':
      return (
        <div className={eventsOverviewRowClassName}>
          <OverviewItem>
            <h6 className={eventsOverviewItemTitleClassName}>{successRateTitle}</h6>
            <h2 className={eventsOverviewItemValueClassName}>
              {data.eventReceivedCount === 0
                ? '0%'
                : `${Math.round(100 * (data.eventSentCount / data.eventReceivedCount))}%`}
            </h2>
            <p className={eventsOverviewItemTitleClassName}>{successRateSubtitle}</p>
          </OverviewItem>
          <OverviewSplitItem
            topTitle={eventTypesReceivedTitle}
            topValue={data.eventTypeReceivedCount}
            bottomTitle={eventCountReceivedTitle}
            bottomValue={data.eventReceivedCount}
          />
          <OverviewSplitItem
            topTitle={eventTypesSentTitle}
            topValue={data.eventTypeSentCount}
            bottomTitle={eventCountSentTitle}
            bottomValue={data.eventSentCount}
          />
        </div>
      );
    default:
      return assertUnreachable(data);
  }
};

export default PixelEventsOverviewRow;
