import React, { PropsWithChildren } from 'react';
import { AddUserModalContext } from './AddUserModalContext';
import { defineMessages, useIntl } from 'react-intl';
import useAddUserModalReducer, { State } from './useAddUserModalReducer';
import { Modal } from '@snapchat/snap-design-system';
import AddUserModalContent from './AddUserModalContent';
import useGenerateInviteCodeEffect from './useGenerateInviteCodeEffect';

const actionButtonMessage = defineMessages<State['step']>({
  CopyLink: {
    defaultMessage: 'Done',
    description: 'Copy link step button text',
  },
  RoleSelection: {
    defaultMessage: 'Next',
    description: 'Next button text',
  },
});

const AddUserModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useAddUserModalReducer();
  useGenerateInviteCodeEffect(dispatch, state);

  return (
    <AddUserModalContext.Provider value={{ dispatch }}>
      <Modal
        visible={state.visible}
        title={formatMessage({
          defaultMessage: 'Add New User',
          description: 'Add user modal title',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage(actionButtonMessage[state.step])}
        isActionButtonPending={state.generateInviteCodeStatus === 'Loading'}
        onCancel={() => dispatch({ type: 'ModalClosed' })}
        onAction={() => dispatch({ type: 'NextButtonClicked' })}
        isActionButtonDisabled={state.step === 'RoleSelection' && !state.name}
      >
        <AddUserModalContent dispatch={dispatch} {...state} />
      </Modal>
      {children}
    </AddUserModalContext.Provider>
  );
};

export default AddUserModalProvider;
