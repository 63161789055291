import { useContext } from 'react';
import { GenerateTokenModalContext } from './GenerateTokenModalContext';

const useGenerateTokenModal = () => {
  const { dispatch } = useContext(GenerateTokenModalContext);

  return { dispatch };
};

export default useGenerateTokenModal;
