import { css } from '@emotion/css';
import { Colors, Fonts, Spacing } from '@snapchat/snap-design-system';

export const eventsOverviewRowClassName = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});

export const eventsOverviewItemClassName = css({
  backgroundColor: Colors.Gray.V50,
  flex: 1,
  minHeight: '116px',
  padding: `${Spacing.XS}px ${Spacing.MD}px`,
});

export const eventsOverviewItemTitleClassName = css({
  ...Fonts.SubHeading.SH400,
  color: Colors.Black.V50,
  fontSize: '12px',
  fontWeight: 400,
  marginBottom: Spacing['2XS'],
});

export const eventsOverviewItemValueClassName = css({
  ...Fonts.Heading.H200,
  fontWeight: 600,
  marginBottom: Spacing['2XS'],
});

export const eventsOverviewSplitItemValueClassName = css({
  ...Fonts.Heading.H500,
});

export const eventsOverviewSplitItemTopSectionClassName = css({
  borderBottom: `2px solid ${Colors.Gray.V100}`,
  marginBottom: Spacing.XS,
});

export const eventTableTitleClassName = css({
  ...Fonts.Heading.H600,
  paddingTop: Spacing.MD,
});

export const eventCountColumnClassName = css({
  display: 'flex',
  flexDirection: 'column',
  gap: Spacing['2XS'],
});
