import { Dispatch, useEffect } from 'react';
import { Event, State } from './useAddTenantModalReducer';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/constants';
import { useAtom } from 'jotai';
import { tenantIdAtom } from 'tenant/useTenantId';

const useRedirectToNewTenantEffect = (dispatch: Dispatch<Event>, state: State) => {
  const navigate = useNavigate();
  const [, setTenantId] = useAtom(tenantIdAtom);

  useEffect(() => {
    if (state.redirectToNewTenantStatus === 'Ready') {
      setTenantId(state.newTenantId ?? '');
      navigate(ROUTES.USERS);
    }
  }, [state.redirectToNewTenantStatus, state.newTenantId, navigate, setTenantId]);
};

export default useRedirectToNewTenantEffect;
