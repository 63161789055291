import { useContext, useEffect } from 'react';
import useSetupSubdomainModal from './useSetupSubdomainModal';
import { useTenantId } from 'tenant';
import { initTenantSubdomain } from 'api/api';
import { useMutation } from '@tanstack/react-query';
import { Notification } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';

const useRegisterSubdomainEffect = () => {
  const tenantId = useTenantId();
  const { dispatch, subdomain, registerSubdomainStatus } = useSetupSubdomainModal();
  const { formatMessage } = useIntl();
  const { error } = useContext(Notification.Context);

  const mutation = useMutation({
    mutationFn: async () => await initTenantSubdomain(tenantId, subdomain!),
    onSuccess: (data) => {
      dispatch({ type: 'RegisterSubdomainSuccess' });
    },
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to setup subdomain',
          description: 'Error message when setting a subdomain',
        }),
      });
      dispatch({ type: 'RegisterSubdomainError' });
    },
  });

  useEffect(() => {
    if (registerSubdomainStatus === 'Ready') {
      mutation.mutate();
      dispatch({ type: 'RegisterSubdomainStarted' });
    }
  }, [dispatch, mutation, registerSubdomainStatus]);
};

export default useRegisterSubdomainEffect;
