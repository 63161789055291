export enum UserRole {
  Member = 'MEMBER',
  Admin = 'ADMIN',
  // Represents a member of the admin tenant
  SuperMember = 'SUPER_MEMBER',
  // Represents an admin of the admin tenant
  SuperAdmin = 'SUPER_ADMIN',
}

export interface User {
  name?: string;
  id?: string;
  role?: UserRole;
  status?: 'ACTIVE' | 'INVITED';
}
