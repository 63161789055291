import React from 'react';

import ErrorSection from '../ErrorSection';

import { useIntl } from 'react-intl';
import { Link } from '@snapchat/snap-design-system';
import { CONTACT_SUPPORT_URL } from 'common/constants';

export const GenericError: React.FC = () => {
  const { formatMessage } = useIntl();

  const linkText = formatMessage({
    defaultMessage: 'contact support',
    description: 'Link text for contacting support',
  });

  const link = (
    <Link openNewTab isExternal href={CONTACT_SUPPORT_URL}>
      {linkText}
    </Link>
  );

  return (
    <ErrorSection
      title={formatMessage({
        defaultMessage: 'Sorry, we can’t fetch your data right now',
        description: 'Title for generic error message',
      })}
    >
      {formatMessage(
        {
          defaultMessage:
            'You can try to refresh your page. If this keeps happening, you might want to {link} for help.',
          description: 'Body message for generic error message',
        },
        {
          link,
        },
      )}
    </ErrorSection>
  );
};

export default GenericError;
