import { css } from '@emotion/css';
import { BorderRadius, Colors, Spacing } from '@snapchat/snap-design-system';

export const pageClassName = css({
  alignItems: 'center',
  backgroundColor: Colors.Gray.V50,
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 54px)',
  justifyContent: 'center',
});

export const authRedirectWrapperClassName = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 54px)',
  width: '100vw',
  backgroundColor: Colors.Gray.V50,
});

export const loginButtonClassName = css({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  backgroundColor: Colors.Yellow.V100,
  border: 'none',
  borderRadius: BorderRadius.button,
  fontWeight: 500,
  fontSize: '20px',
  justifyContent: 'center',
  padding: `${Spacing.SM}px ${Spacing.MD}px`,
});
