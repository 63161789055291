import { Modal } from '@snapchat/snap-design-system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePixelParamData from './usePixelParamData';
import assertUnreachable from '../../util/assertUnreachable';
import { useIntl } from 'react-intl';
import useConnectPixel from './useConnectPixel';
import { useTenantId } from 'tenant';
import isAuthorized from 'auth/isAuthorized';
import useSelectedTenant from 'tenant/useSelectedTenant';

const AddPixelConnectionModal: React.FC = () => {
  const navigate = useNavigate();
  const pixelData = usePixelParamData();
  const { formatMessage } = useIntl();
  const connectPixel = useConnectPixel();
  const tenantId = useTenantId();
  const { role } = useSelectedTenant();

  const connectText = formatMessage({
    defaultMessage: 'Connect',
    description: 'Connect button text',
  });
  const cancelText = formatMessage({
    defaultMessage: 'Cancel',
    description: 'Cancel button text',
  });

  if (!isAuthorized({ role, entity: 'PixelConfig', action: 'Create' })) {
    return (
      <Modal
        visible={true}
        actionText={connectText}
        cancelText={cancelText}
        onCancel={() => navigate('/')}
        isActionButtonDisabled
      >
        <div>
          <h1>
            {formatMessage({
              defaultMessage: 'You don\t have permission to connect this pixel, please contact your administrator',
              description: 'Unauthorized modal title',
            })}
          </h1>
        </div>
      </Modal>
    );
  }

  switch (pixelData.type) {
    case 'Invalid':
      return (
        <Modal
          visible={true}
          actionText={connectText}
          cancelText={cancelText}
          onCancel={() => navigate('/')}
          isActionButtonDisabled
        >
          <div>
            <h1>
              {formatMessage({
                defaultMessage: 'Invalid Pixel Connection',
                description: 'Invalid pixel connection modal title',
              })}
            </h1>
          </div>
        </Modal>
      );
    case 'Valid':
      return (
        <Modal
          visible={true}
          actionText={connectText}
          cancelText={cancelText}
          onCancel={() => navigate('/')}
          onAction={() =>
            connectPixel.mutate({
              pixel_id: pixelData.pixelId,
              capi_token: pixelData.capiToken,
              tenant_id: tenantId,
              name: pixelData.name,
            })
          }
          isActionButtonPending={connectPixel.isPending}
          title={formatMessage({
            defaultMessage: 'Confirm Pixel Connection',
            description: 'Confirm pixel connection modal title',
          })}
        >
          <div>
            <p>
              {formatMessage({
                defaultMessage: 'Are you sure you want to connect this pixel?',
                description: 'Confirm pixel connection modal description',
              })}
            </p>
            {pixelData.name && <p>{pixelData.name}</p>}
            <p>
              {formatMessage(
                {
                  defaultMessage: 'Pixel ID: {pixelId}',
                },
                {
                  pixelId: pixelData.pixelId,
                },
              )}
            </p>
          </div>
        </Modal>
      );
    default:
      return assertUnreachable(pixelData);
  }
};

export default AddPixelConnectionModal;
