import { PixelConfigJson } from 'api/types';
import { PixelConfig } from 'types';

export const fromJson = (pixelConfig: PixelConfigJson): PixelConfig => {
  return {
    pixelId: pixelConfig.pixel_id,
    capiToken: pixelConfig.capi_token,
    name: pixelConfig.name,
  };
};

export const toJson = (pixelConfig: PixelConfig): PixelConfigJson => {
  return {
    pixel_id: pixelConfig.pixelId,
    capi_token: pixelConfig.capiToken,
    tenant_id: pixelConfig.tenantId,
    name: pixelConfig.name,
  };
};
