import { useQuery } from '@tanstack/react-query';
import { getPixelEventsOverview } from 'api/api';
import { useAtom } from 'jotai';
import { selectedDateRangeAtom } from 'overview/atoms';
import { useTenantId } from 'tenant';
import toDateRangeInput from 'util/toDateRangeInput';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
  message?: string;
}

interface Success {
  status: 'Success';
  eventTypeReceivedCount: number;
  eventTypeSentCount: number;
  eventReceivedCount: number;
  eventSentCount: number;
  lastReceivedAgoMillis?: number;
  lastSentAgoMillis?: number;
}

type Output = Loading | Error | Success;

const usePixelEventOverview = (pixelId: string): Output => {
  const [dateRange] = useAtom(selectedDateRangeAtom);
  const tenantId = useTenantId();

  const pixelOverviewQuery = useQuery({
    queryKey: ['pixelOverview', tenantId, pixelId, dateRange],
    queryFn: async () => await getPixelEventsOverview(tenantId, pixelId, toDateRangeInput(dateRange)),
    enabled: !!pixelId,
  });

  switch (pixelOverviewQuery.status) {
    case 'pending':
      return { status: 'Loading' };
    case 'error':
      return { status: 'Error', message: pixelOverviewQuery.error.message };
    case 'success':
      return {
        status: 'Success',
        eventTypeReceivedCount: pixelOverviewQuery.data.event_types_received ?? 0,
        eventTypeSentCount: pixelOverviewQuery.data.event_types_sent ?? 0,
        eventReceivedCount: pixelOverviewQuery.data.total_events_received ?? 0,
        eventSentCount: pixelOverviewQuery.data.total_events_sent ?? 0,
        lastReceivedAgoMillis: pixelOverviewQuery.data.time_since_received,
        lastSentAgoMillis: pixelOverviewQuery.data.time_since_sent,
      };
  }
};

export default usePixelEventOverview;
