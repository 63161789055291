import { authToken } from 'auth/authToken';
import { SessionInfo } from 'auth/types';
import useAuthRedirect from 'auth/useAuthRedirect';
import useUserTenants from 'auth/useUserTenants';
import { ROUTES } from 'common/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { userId } from 'users/userId';
import assertUnreachable from 'util/assertUnreachable';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  sessionInfo: SessionInfo;
}

const useAuthorizeUser = (): Loading | Error | Success => {
  const [authRedirect, setAuthRedirect] = useAuthRedirect();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tenantsData = useUserTenants();

  if (!authToken || !userId) {
    setAuthRedirect(location.pathname + '?' + params.toString());
    return { status: 'Error' };
  }

  if (authRedirect) {
    navigate(authRedirect);
    setAuthRedirect(undefined);
  }

  switch (tenantsData.status) {
    case 'Loading':
      return { status: 'Loading' };
    case 'Error':
      return { status: 'Error' };
    case 'Success': {
      if (tenantsData.tenants.length === 0) {
        navigate(ROUTES.NOT_FOUND);
      }

      return { status: 'Success', sessionInfo: { userTenants: tenantsData.tenants } };
    }
    default:
      return assertUnreachable(tenantsData);
  }
};

export default useAuthorizeUser;
