import { Alert } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import { setupSubdomainAlertClassName } from './styles';
import useIsTenantDeactivated from 'tenant/useIsTenantDeactivated';

const DeactivatedTenantAlert: React.FC = () => {
  const { formatMessage } = useIntl();
  const isTenantDeactivated = useIsTenantDeactivated();

  if (!isTenantDeactivated) {
    return null;
  }

  return (
    <Alert
      className={setupSubdomainAlertClassName}
      status='warning'
      title={formatMessage({
        defaultMessage:
          'Your account has been deactivated, please contact your account administrator for more information.',
        description: 'Tenant deactivated alert title',
      })}
    />
  );
};

export default DeactivatedTenantAlert;
