import { useQuery } from '@tanstack/react-query';
import { getPixelEvents, getTenantEvents } from 'api/api';
import { useAtom } from 'jotai';
import { selectedDateRangeAtom, selectedPixelIdAtom } from 'overview/atoms';
import { useTenantId } from 'tenant';
import { PixelEventsData } from 'types';
import toDateRangeInput from 'util/toDateRangeInput';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
  message?: string;
}

interface Success {
  status: 'Success';
  pixelEvents: PixelEventsData[];
}
type Output = Loading | Error | Success;

const usePixelEventsData = (): Output => {
  const tenantId = useTenantId();
  const [pixelId] = useAtom(selectedPixelIdAtom);
  const [dateRange] = useAtom(selectedDateRangeAtom);

  const tenantOverview = useQuery({
    queryKey: ['tenantEvents', tenantId, dateRange],
    queryFn: async () => await getTenantEvents(tenantId, toDateRangeInput(dateRange)),
    enabled: !pixelId,
  });

  const pixelOverview = useQuery({
    queryKey: ['pixelEvents', pixelId],
    queryFn: async () => await getPixelEvents(tenantId, pixelId!, toDateRangeInput(dateRange)),
    enabled: !!pixelId,
  });

  const queryResult = pixelId ? pixelOverview : tenantOverview;

  switch (queryResult.status) {
    case 'pending':
      return { status: 'Loading' };
    case 'error':
      return { status: 'Error', message: queryResult.error.message };
    case 'success': {
      const eventsList: PixelEventsData[] =
        queryResult.data.event_types?.map((event) => ({
          eventName: event.event_type,
          totalReceived: (event.success_count ?? 0) + (event.failed_count ?? 0),
          totalSent: event.success_count,
          lastReceivedAgoMillis: event.time_since_received,
          lastSentAgoMillis: event.time_since_sent,
        })) ?? [];

      return {
        status: 'Success',
        pixelEvents: eventsList,
      };
    }
  }
};

export default usePixelEventsData;
