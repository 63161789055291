import { useQuery } from '@tanstack/react-query';
import { getLoginRedirectUrl } from 'api/api';

const useLoginRedirectUrl = () => {
  const query = useQuery({
    queryKey: ['loginRedirectUrl'],
    queryFn: getLoginRedirectUrl,
    refetchInterval: false,
  });
  return query.data?.redirect_url;
};

export default useLoginRedirectUrl;
