import React from 'react';
import { NavBar as SdsNavBar, Spacing } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import { css } from '@emotion/css';

const navBarTitleClassName = css({
  paddingLeft: Spacing.MD,
  margin: 0,
});

interface Props {
  rightContent?: React.ReactNode;
}

const NavBar: React.FC<Props> = ({ rightContent }) => {
  const { formatMessage } = useIntl();

  return (
    <SdsNavBar
      leftContent={
        <h4 className={navBarTitleClassName}>
          {formatMessage({ defaultMessage: 'Conversions API Gateway', description: 'CAPI Gateway nav bar text' })}
        </h4>
      }
      rightContent={rightContent}
    />
  );
};

export default NavBar;
