import React from 'react';
import { useIntl } from 'react-intl';
import { TenantConfig } from 'types';
import { ADMIN_TENANT_ID } from 'common/constants';
import { TenantStatus } from 'api/types';

interface Props {
  value: string;
  row: {
    original: TenantConfig;
  };
}

const TenantNameColumnRenderer: React.FC<Props> = ({ value: tenantId, row }) => {
  const { formatMessage } = useIntl();

  if (tenantId === ADMIN_TENANT_ID) {
    return (
      <span>
        {formatMessage(
          {
            defaultMessage: '{name} (Host Account)',
            description: 'Host account',
          },
          { name: row.original.name },
        )}
      </span>
    );
  }

  if (row.original.status === TenantStatus.Inactive) {
    return (
      <span>
        {formatMessage(
          {
            defaultMessage: '{name} (Deactivated)',
            description: 'Inactive tenant',
          },
          { name: row.original.name },
        )}
      </span>
    );
  }

  return <span>{row.original.name}</span>;
};

export default TenantNameColumnRenderer;
