import React, { PropsWithChildren } from 'react';
import { SetupSubdomainModalContext } from './SetupSubdomainModalContext';
import { Modal } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import { useAtom } from 'jotai';
import { subdomainAtom } from 'data-routing/atoms';
import { useTenantConfig } from 'tenant';
import useSetupSubdomainModalReducer from './useSetupSubdomainModalReducer';
import SetupSubdomainModalBody from './SetupSubdomainModalBody';
import SetupSubdomainModalFooter from './SetupSubdomainModalFooter';

const SetupSubdomainModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const tenantConfig = useTenantConfig();
  const [, setSubdomain] = useAtom(subdomainAtom);
  const [state, dispatch] = useSetupSubdomainModalReducer();

  return (
    <>
      <SetupSubdomainModalContext.Provider
        value={{
          ...state,
          dispatch,
          open: () => {
            dispatch({ type: 'ModalOpened' });
            if (tenantConfig.status === 'Success') {
              setSubdomain(tenantConfig.tenantConfig.subdomain);
            }
          },
        }}
      >
        <Modal
          visible={state.visible}
          title={formatMessage({
            defaultMessage: 'Setup Subdomain',
            description: 'Setup subdomain modal title',
          })}
          footer={false}
          padBody={false}
          width='auto'
        >
          <SetupSubdomainModalBody />
          <SetupSubdomainModalFooter />
        </Modal>
        {children}
      </SetupSubdomainModalContext.Provider>
    </>
  );
};

export default SetupSubdomainModalProvider;
