import { useSearchParams } from 'react-router-dom';

interface ValidConnectionRequest {
  type: 'Valid';
  pixelId: string;
  capiToken: string;
  name?: string;
}

interface InvalidConnectionRequest {
  type: 'Invalid';
}

const usePixelParamData = (): ValidConnectionRequest | InvalidConnectionRequest => {
  const [params] = useSearchParams();
  const pixelData = params.get('pixel');

  if (!pixelData) {
    return { type: 'Invalid' };
  }

  const parsedData = atob(pixelData);
  const pixelObject = JSON.parse(parsedData);
  if (!pixelObject.id || !pixelObject.token) {
    return { type: 'Invalid' };
  }

  return { type: 'Valid', pixelId: pixelObject.id, capiToken: pixelObject.token, name: pixelObject.name };
};

export default usePixelParamData;
