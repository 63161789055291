import React, { PropsWithChildren, useState } from 'react';
import { DeleteUserModalContext } from './DeleteUserModalContext';
import { Modal } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import useDeleteUser from './useDeleteUser';

const DeleteUserModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [userId, setUserId] = useState<string>();
  const { formatMessage } = useIntl();
  const deleteUser = useDeleteUser();

  return (
    <DeleteUserModalContext.Provider value={{ open: (pid: string) => setUserId(pid) }}>
      <Modal
        visible={!!userId}
        onCancel={() => setUserId(undefined)}
        title={formatMessage({
          defaultMessage: 'Are you sure?',
          description: 'Title for the delete user modal',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Delete',
          description: 'Delete button text',
        })}
        onAction={async () => {
          await deleteUser.mutate(userId!);
          setUserId(undefined);
        }}
        isActionButtonPending={deleteUser.isPending}
      >
        <p>
          {formatMessage({
            defaultMessage: 'Are you sure you want to delete this user?',
            description: 'Description for the delete user modal',
          })}
        </p>
      </Modal>
      {children}
    </DeleteUserModalContext.Provider>
  );
};

export default DeleteUserModalProvider;
