import assertUnreachable from 'util/assertUnreachable';
import useSetupSubdomainStatus from './useSubdomainStatus';
import { Status } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';

const useSubdomainInputStatus = (): { status?: Status; message?: string } => {
  const status = useSetupSubdomainStatus();
  const { formatMessage } = useIntl();

  switch (status) {
    case 'Valid':
      return {
        status: 'success',
        message: formatMessage({
          defaultMessage: 'Ready',
          description: 'Subdomain is ready message',
        }),
      };
    case 'InvalidUrl':
      return {
        status: 'error',
        message: formatMessage({
          defaultMessage: 'Please enter a valid URL',
          description: 'Invalid URL message',
        }),
      };
    case 'Empty':
      return {};
    default:
      return assertUnreachable(status);
  }
};

export default useSubdomainInputStatus;
