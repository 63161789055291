import { css } from '@emotion/css';
import { Fonts } from '@snapchat/snap-design-system';

export const pageHeaderClassName = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const pageHeaderTitleClassName = css({
  ...Fonts.Heading.H300,
});
