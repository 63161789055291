import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTenantConfig } from 'api/api';
import { Dispatch, useEffect } from 'react';
import { useTenantId } from 'tenant';
import { Event, State } from './useEditTenantModalReducer';
import { toJson } from 'translators/TenantConfigTranslator';

const useGenerateInviteCodeEffect = (dispatch: Dispatch<Event>, state: State) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['updateTenant', tenantId],
    mutationFn: async () => await updateTenantConfig(toJson({ ...state.initTenant, name: state.name })),
    onSuccess: () => {
      dispatch({ type: 'UpdateTenantSuccess' });
      queryClient.invalidateQueries({ queryKey: ['tenants'] });
    },
    onError: () => dispatch({ type: 'UpdateTenantError' }),
  });

  useEffect(() => {
    if (state.updateTenantStatus === 'Ready') {
      dispatch({ type: 'UpdateTenantStarted' });
      mutation.mutate();
    }
  }, [dispatch, mutation, state.updateTenantStatus]);
};

export default useGenerateInviteCodeEffect;
