import { Section } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import LanguageSelector from './LanguageSelector';

const LanguageSettings: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Section
      title={formatMessage({
        defaultMessage: 'Language Settings',
        description: 'Language settings section title',
      })}
    >
      <LanguageSelector />
    </Section>
  );
};

export default LanguageSettings;
