import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generateApiToken } from 'api/api';
import { Dispatch, useEffect } from 'react';
import { Event, State } from './useGenerateTokenModalReducer';

const useGenerateTokenEffect = (dispatch: Dispatch<Event>, state: State) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['generateTokenCode'],
    mutationFn: async () => await generateApiToken(state.name ?? ''),
    onSuccess: (data) => {
      dispatch({ type: 'GenerateTokenCodeSuccess', tokenValue: data.data.value ?? '' });
      queryClient.invalidateQueries({ queryKey: ['apiTokens'] });
    },
    onError: () => dispatch({ type: 'GenerateTokenCodeError' }),
  });

  useEffect(() => {
    if (state.generateTokenStatus === 'Ready') {
      dispatch({ type: 'GenerateTokenCodeStarted' });
      mutation.mutate();
    }
  }, [state.generateTokenStatus, dispatch, mutation]);
};

export default useGenerateTokenEffect;
