import React, { PropsWithChildren, useState } from 'react';
import { DeleteSubdomainModalContext } from './DeleteSubdomainModalContext';
import { Modal } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import useDeleteSubdomain from './useDeleteSubdomain';

const DeleteSubdomainModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const deleteSubdomain = useDeleteSubdomain();

  return (
    <DeleteSubdomainModalContext.Provider value={{ open: () => setVisible(true) }}>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={formatMessage({
          defaultMessage: 'Are you sure?',
          description: 'Title for the delete subdomain modal',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Remove',
          description: 'Remove button text',
        })}
        onAction={async () => {
          await deleteSubdomain.mutate();
        }}
        isActionButtonPending={deleteSubdomain.isPending}
      >
        <p>
          {formatMessage({
            defaultMessage:
              "Are you sure you want to remove the data routing setup for this account? All Pixels attached to this Gateway will stop sending events to this subdomain and instead send them to the Gateway's default domain.",
            description: 'Description for the delete subdomain modal',
          })}
        </p>
      </Modal>
      {children}
    </DeleteSubdomainModalContext.Provider>
  );
};

export default DeleteSubdomainModalProvider;
