import React from 'react';
import { Outlet } from 'react-router-dom';
import ConnectedPixelsSection from './ConnectedPixelsSection';
import { DisconnectedPixelModalProvider } from './DisconnectPixelModalProvider';
import { useIntl } from 'react-intl';
import PixelEventsSection from './PixelEventsSection';
import PageHeader from 'common/PageHeader';
import { Button } from '@snapchat/snap-design-system';
import SetupSubdomainAlert from './SetupSubdomainAlert';
import { pageHeaderRightContentClassName } from './styles';
import DateRangePicker from './DateRangePicker/DateRangePicker';
import DeactivatedTenantAlert from './DeactivatedTenantAlert';

const OverviewPage: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <DisconnectedPixelModalProvider>
      <div>
        <DeactivatedTenantAlert />
        <SetupSubdomainAlert />
        <PageHeader
          title={formatMessage({
            defaultMessage: 'Overview',
            description: 'Overview page title',
          })}
          rightContent={
            <div className={pageHeaderRightContentClassName}>
              <Button type='link' href='https://businesshelp.snapchat.com/' target='_blank'>
                {formatMessage({
                  defaultMessage: 'Get Support',
                  description: 'Get support button text',
                })}
              </Button>
              <DateRangePicker />
            </div>
          }
        />
        <ConnectedPixelsSection />
        <PixelEventsSection />
        <Outlet />
      </div>
    </DisconnectedPixelModalProvider>
  );
};

export default OverviewPage;
