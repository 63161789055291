import { AutoSizer, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import usePixelEventsData from '../usePixelEventsData';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PixelEventsData } from 'types';
import assertUnreachable from 'util/assertUnreachable';
import EventNameColumnRenderer from './EventNameColumnRenderer';
import EventCountColumnRenderer, { Props as EventCountColumnRendererProps } from './EventCountColumnRenderer';
import GenericError from 'common/GenericError';

const ROW_HEIGHT = 50;

const columns: VirtualTableColumn<PixelEventsData>[] = [
  {
    id: 'eventName',
    accessor: 'eventName',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Event Name' description='Header text for event name column' />,
    Cell: EventNameColumnRenderer,
  },
  {
    id: 'totalReceived',
    accessor: 'totalReceived',
    minWidth: 160,
    Header: () => (
      <FormattedMessage defaultMessage='Total Received' description='Header text for events received column' />
    ),
    Cell: (props: EventCountColumnRendererProps) => (
      <EventCountColumnRenderer {...props} timestampKey='lastReceivedAgoMillis' />
    ),
  },
  {
    id: 'totalSent',
    accessor: 'totalSent',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Total Sent' description='Header text for events sent column' />,
    Cell: (props: EventCountColumnRendererProps) => (
      <EventCountColumnRenderer {...props} timestampKey='lastSentAgoMillis' />
    ),
  },
];

const PixelEventsTable: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = usePixelEventsData();

  switch (data.status) {
    case 'Loading':
      return (
        <div style={{ height: ROW_HEIGHT * 4 }}>
          <AutoSizer>
            {({ width, height }: { width: number; height: number }) => (
              <VirtualTable
                columns={columns}
                data={[{}, {}, {}]}
                loadMore={() => Promise.resolve()}
                hasMore={false}
                height={height}
                width={width}
                rowHeight={ROW_HEIGHT}
                emptyMessage={formatMessage({
                  defaultMessage: 'No events received',
                  description: 'Message displayed when no Pixel events are found',
                })}
                isLoading
              />
            )}
          </AutoSizer>
        </div>
      );
    case 'Error':
      return <GenericError />;
    case 'Success':
      return (
        <div style={{ height: ROW_HEIGHT * (data.pixelEvents.length + 1) }}>
          <AutoSizer>
            {({ width, height }: { width: number; height: number }) => (
              <VirtualTable
                columns={columns}
                data={data.pixelEvents}
                loadMore={() => Promise.resolve()}
                hasMore={false}
                height={height}
                width={width}
                rowHeight={ROW_HEIGHT}
                emptyMessage={formatMessage({
                  defaultMessage: 'No events received',
                  description: 'Message displayed when no Pixel events are found',
                })}
              />
            )}
          </AutoSizer>
        </div>
      );
    default:
      return assertUnreachable(data);
  }
};

export default PixelEventsTable;
