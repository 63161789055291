import { useQuery } from '@tanstack/react-query';
import useSetupSubdomainModal from '../useSetupSubdomainModal';
import { DnsRecord } from 'api/types';
import { getDnsInfo } from 'api/api';
import { useTenantId } from 'tenant';
import assertUnreachable from 'util/assertUnreachable';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  dnsRecords: DnsRecord[];
}

type Output = Loading | Error | Success;

const useDnsRecords = (): Output => {
  const { subdomain } = useSetupSubdomainModal();
  const tenantId = useTenantId();
  const { dispatch } = useSetupSubdomainModal();

  const data = useQuery({
    queryKey: ['dnsRecords', subdomain],
    queryFn: async () => getDnsInfo(tenantId),
    refetchInterval: 5000,
  });

  switch (data.status) {
    case 'error':
      return { status: 'Error' };
    case 'pending':
      return { status: 'Loading' };
    case 'success': {
      if (data.data.data.is_custom_hostname_ready) {
        dispatch({ type: 'CustomHostnameReady' });
      }

      return { status: 'Success', dnsRecords: data.data.data.dns_records ?? [] };
    }
    default:
      return assertUnreachable(data);
  }
};

export default useDnsRecords;
