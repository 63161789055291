import { Dropdown, Menu, MenuItem } from '@snapchat/snap-design-system';
import { EllipsisIcon } from '@snapchat/snap-design-system-icons';
import { useDisconnectPixelModal } from 'overview/DisconnectPixelModalProvider';
import React from 'react';
import { useIntl } from 'react-intl';
import { actionDropdownClassName } from '../styles';
import isAuthorized from 'auth/isAuthorized';
import useSelectedTenant from 'tenant/useSelectedTenant';
import useRefreshPixelConnection from './useRefreshPixelConnection';
import usePausePixelConnection from './usePausePixelConnection';
import useIsTenantDeactivated from 'tenant/useIsTenantDeactivated';

interface Props {
  value: string;
}

const ConnectedPixelActions: React.FC<Props> = ({ value: pixelId }) => {
  const { formatMessage } = useIntl();
  const { open } = useDisconnectPixelModal();
  const { role } = useSelectedTenant();
  const refreshConnectionMutation = useRefreshPixelConnection();
  const pauseConnectionMutation = usePausePixelConnection();
  const isTenantDeactivated = useIsTenantDeactivated();

  if (isTenantDeactivated) {
    return (
      <Dropdown
        overlay={
          <Menu>
            <MenuItem
              disabled={!isAuthorized({ role, entity: 'PixelConfig', action: 'Delete' })}
              onClick={() => open(pixelId)}
              key='disconnect'
            >
              {formatMessage({
                defaultMessage: 'Disconnect',
                description: 'Disconnect connected pixel',
              })}
            </MenuItem>
          </Menu>
        }
        className={actionDropdownClassName}
      >
        <EllipsisIcon />
      </Dropdown>
    );
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          <MenuItem
            disabled={!isAuthorized({ role, entity: 'PixelConfig', action: 'Update' })}
            onClick={() => pauseConnectionMutation.mutate(pixelId)}
            key='pause'
          >
            {formatMessage({
              defaultMessage: 'Pause Publishing',
              description: 'Pause Pixel connection',
            })}
          </MenuItem>
          <MenuItem
            disabled={!isAuthorized({ role, entity: 'PixelConfig', action: 'Delete' })}
            onClick={() => open(pixelId)}
            key='disconnect'
          >
            {formatMessage({
              defaultMessage: 'Disconnect',
              description: 'Disconnect connected pixel',
            })}
          </MenuItem>
          <MenuItem
            disabled={!isAuthorized({ role, entity: 'PixelConfig', action: 'Update' })}
            onClick={() => refreshConnectionMutation.mutate(pixelId)}
            key='force-connect'
          >
            {formatMessage({
              defaultMessage: 'Refresh Connection',
              description: 'Force refresh Pixel connection',
            })}
          </MenuItem>
        </Menu>
      }
      className={actionDropdownClassName}
    >
      <EllipsisIcon />
    </Dropdown>
  );
};

export default ConnectedPixelActions;
