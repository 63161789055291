import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSetupSubdomainModal from './useSetupSubdomainModal';
import { setTenantSubdomain } from 'api/api';
import { useTenantId } from 'tenant';
import { useIntl } from 'react-intl';
import { useContext, useEffect } from 'react';
import { Notification } from '@snapchat/snap-design-system';

const useConfirmSubdomainEffect = () => {
  const tenantId = useTenantId();
  const { dispatch, subdomain, confirmSubdomainStatus } = useSetupSubdomainModal();
  const { formatMessage } = useIntl();
  const { error, success } = useContext(Notification.Context);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => await setTenantSubdomain(tenantId, subdomain!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tenantConfig', tenantId] });
      dispatch({ type: 'ConfirmSubdomainSuccess' });
      success({
        title: formatMessage({
          defaultMessage: 'Subdomain Successfully Setup',
          description: 'Success message when setting a subdomain',
        }),
      });
    },
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to setup subdomain',
          description: 'Error message when setting a subdomain',
        }),
      });
      dispatch({ type: 'ConfirmSubdomainError' });
    },
  });

  useEffect(() => {
    if (confirmSubdomainStatus === 'Ready') {
      mutation.mutate();
      dispatch({ type: 'ConfirmSubdomainStarted' });
    }
  }, [confirmSubdomainStatus, mutation, dispatch]);
};

export default useConfirmSubdomainEffect;
