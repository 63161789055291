import React, { PropsWithChildren, ReactNode } from 'react';

import { Section } from '@snapchat/snap-design-system';

import ErrorImg from 'asset/generic-error.svg';

import {
  errorSectionBodyClassName,
  errorSectionContainerClassName,
  errorSectionImageClasName,
  errorSectionTitleClassName,
} from './styles';

interface Props extends PropsWithChildren {
  title: ReactNode;
}

export const ErrorSection: React.FC<Props> = ({ title, children }) => (
  <Section className={errorSectionContainerClassName}>
    <div className={errorSectionImageClasName}>
      <img src={ErrorImg} alt='No Events' height='100px' />
    </div>
    <h3 className={errorSectionTitleClassName}>{title}</h3>
    <p className={errorSectionBodyClassName}>{children}</p>
  </Section>
);

export default ErrorSection;
