import { createHashRouter } from 'react-router-dom';
import OverviewPage from '../overview/OverviewPage';
import DataRoutingPage from '../data-routing/DataRoutingPage';
import AddPixelConnectionModal from '../overview/AddPixelConnectionModal/AddPixelConnectionModal';
import LoginPage from 'auth/LoginPage';
import { ROUTES } from '../common/constants';
import UsersPage from 'users/UsersPage';
import TenantsPage from 'tenant/TenantsPage/TenantsPage';
import AuthRedirect from 'auth/AuthRedirect';
import AcceptInvitePage from 'auth/AcceptInvitePage';
import NotFoundPage from 'common/NotFoundPage';
import SettingsPage from 'settings/SettingsPage';
import LayoutContainer from 'common/LayoutContainer/LayoutContainer';

const router = createHashRouter([
  {
    element: <AuthRedirect />,
    children: [
      {
        element: <LayoutContainer />,
        children: [
          {
            path: ROUTES.OVERVIEW,
            element: <OverviewPage />,
            children: [
              {
                path: 'add-pixel',
                element: <AddPixelConnectionModal />,
              },
            ],
          },
          {
            path: ROUTES.DATA_ROUTING,
            element: <DataRoutingPage />,
          },
          {
            path: ROUTES.TENANTS,
            element: <TenantsPage />,
          },
          {
            path: ROUTES.USERS,
            element: <UsersPage />,
          },
          {
            path: ROUTES.SETTINGS,
            element: <SettingsPage />,
          },
        ],
      },
    ],
  },
  {
    element: <AcceptInvitePage />,
    path: ROUTES.ACCEPT_INVITE,
  },
  {
    element: <LoginPage />,
    path: ROUTES.LOGIN,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

export default router;
