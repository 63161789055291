import { AutoSizer, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PixelConfig } from 'types';
import ConnectedPixelActions from './ConnectedPixelActions';

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<PixelConfig>[] = [
  {
    id: 'pixelId',
    accessor: 'pixelId',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Pixel' description='Header text for id column' />,
    Cell: ConnectedPixelActions,
  },
  {
    id: 'eventsReceived',
    accessor: 'pixelId',
    minWidth: 120,
    Header: () => (
      <FormattedMessage defaultMessage='Total Events Received' description='Header text for events received column' />
    ),
    Cell: ConnectedPixelActions,
  },
  {
    id: 'eventsSent',
    accessor: 'pixelId',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Total Events Sent' description='Header text for events sent' />,
    Cell: ConnectedPixelActions,
  },
  {
    id: 'actions',
    accessor: 'pixelId',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Actions' description='Header text for actions column' />,
    Cell: ConnectedPixelActions,
  },
];

const ConnectedPixelsTableSkeleton: React.FC = () => {
  return (
    <div style={{ height: ROW_HEIGHT * 2 }}>
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <VirtualTable
            columns={columns}
            data={[{}]}
            loadMore={() => Promise.resolve()}
            hasMore={false}
            height={height}
            width={width}
            rowHeight={ROW_HEIGHT}
            emptyMessage={''}
            isLoading
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ConnectedPixelsTableSkeleton;
