import { Colors, Spinner } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import useDNSRecordsStatus from './useDNSRecordsStatus';
import assertUnreachable from 'util/assertUnreachable';
import { CheckCircleIcon } from '@snapchat/snap-design-system-icons';
import {
  finalizeSubdomainCheckmarkClassName,
  finalizeSubdomainContainerClassName,
  finalizeSubdomainTextClassName,
} from './styles';

const FinalizeSubdomain: React.FC = () => {
  const { formatMessage } = useIntl();
  const dnsStatus = useDNSRecordsStatus();

  switch (dnsStatus) {
    case 'NotReady':
      return (
        <div className={finalizeSubdomainContainerClassName}>
          <Spinner size='x-large' />
          <p className={finalizeSubdomainTextClassName}>
            {formatMessage({
              defaultMessage:
                'Waiting for your DNS records to activate. This may take a few a little while; you can close this page and return later if necessary',
              description: 'Finalize subdomain instructions',
            })}
          </p>
        </div>
      );
    case 'Ready':
      return (
        <div className={finalizeSubdomainContainerClassName}>
          <CheckCircleIcon fill={Colors.Green.V200} className={finalizeSubdomainCheckmarkClassName} />
          <p className={finalizeSubdomainTextClassName}>
            {formatMessage({
              defaultMessage: 'Your DNS setup looks good! Click the Save button below to finish.',
              description: 'Finalize subdomain ready instructions',
            })}
          </p>
        </div>
      );
    default:
      return assertUnreachable(dnsStatus);
  }
};

export default FinalizeSubdomain;
