import { useContext } from 'react';
import { EditTenantModalContext } from './EditTenantModalContext';

const useEditTenantModal = () => {
  const { open } = useContext(EditTenantModalContext);

  return { open };
};

export default useEditTenantModal;
