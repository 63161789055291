import React from 'react';

import { SideNav as SdsSideNav, SideNavLink } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AnalyticsIcon,
  ArrowsClockwiseRectangleLandscapeIcon,
  ProfileCircleIcon,
  SettingsIcon,
  SnapKitIcon,
} from '@snapchat/snap-design-system-icons';
import { sideNavDividerClassName } from './styles';
import useIsGatewayAdmin from 'auth/useIsGatewayAdmin';
import { ROUTES } from 'common/constants';
import { useConfirmLogoutModal } from 'auth/ConfirmLogoutModalProvider';

const SideNav: React.FC = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { open: openConfirmLogout } = useConfirmLogoutModal();
  const isGatewayAdmin = useIsGatewayAdmin();

  return (
    <SdsSideNav selectedItemKey={location.pathname}>
      <SideNavLink
        itemKey={ROUTES.OVERVIEW}
        text={formatMessage({ defaultMessage: 'Overview', description: 'Overview text' })}
        onClick={() => navigate(ROUTES.OVERVIEW)}
        icon={<AnalyticsIcon />}
      />
      <SideNavLink
        itemKey={ROUTES.DATA_ROUTING}
        text={formatMessage({ defaultMessage: 'Data Routing', description: 'Data routing side nav item' })}
        onClick={() => navigate(ROUTES.DATA_ROUTING)}
        icon={<ArrowsClockwiseRectangleLandscapeIcon />}
      />
      <hr className={sideNavDividerClassName} />
      <SideNavLink
        itemKey={ROUTES.USERS}
        text={formatMessage({ defaultMessage: 'Users', description: 'Users side nav item' })}
        onClick={() => navigate(ROUTES.USERS)}
        icon={<ProfileCircleIcon />}
      />
      {isGatewayAdmin && (
        <SideNavLink
          itemKey={ROUTES.TENANTS}
          text={formatMessage({ defaultMessage: 'Accounts', description: 'Accounts side nav item' })}
          onClick={() => navigate(ROUTES.TENANTS)}
          icon={<SnapKitIcon />}
        />
      )}
      {
        <SideNavLink
          itemKey={ROUTES.SETTINGS}
          text={formatMessage({ defaultMessage: 'Settings', description: 'Settings side nav item' })}
          onClick={() => navigate(ROUTES.SETTINGS)}
          icon={<SettingsIcon />}
        />
      }
      <SideNavLink
        itemKey='logout'
        text={formatMessage({ defaultMessage: 'Logout', description: 'Accounts side nav item' })}
        onClick={() => openConfirmLogout()}
      />
    </SdsSideNav>
  );
};

export default SideNav;
