import { css } from '@emotion/css';
import { Colors, Fonts, Spacing } from '@snapchat/snap-design-system';

export const errorSectionImageClasName = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 220,
  marginTop: Spacing.XL,
});

export const errorSectionTitleClassName = css({
  ...Fonts.Heading.H500,
  paddingBottom: Spacing.LG,
});

export const errorSectionBodyClassName = css({
  ...Fonts.Paragraph.P200,
  color: Colors.Black.V50,
  margin: '0 auto',
  maxWidth: 544,
  lineHeight: '20px',
});

export const errorSectionContainerClassName = css({
  textAlign: 'center',
});
