import React, { PropsWithChildren, useState } from 'react';
import { DeleteApiTokenModalContext } from './DeleteApiTokenModalContext';
import { Modal } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import useDeleteApiToken from './useDeleteApiToken';

const DeleteApiTokenModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tokenId, setTokenId] = useState<string>();
  const { formatMessage } = useIntl();
  const deleteApiToken = useDeleteApiToken();

  return (
    <DeleteApiTokenModalContext.Provider value={{ open: (id: string) => setTokenId(id) }}>
      <Modal
        visible={!!tokenId}
        onCancel={() => setTokenId(undefined)}
        title={formatMessage({
          defaultMessage: 'Are you sure?',
          description: 'Title for the delete API token modal',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Delete',
          description: 'Delete button text',
        })}
        onAction={async () => {
          await deleteApiToken.mutate(tokenId!);
          setTokenId(undefined);
        }}
        isActionButtonPending={deleteApiToken.isPending}
      >
        <p>
          {formatMessage({
            defaultMessage: 'Are you sure you want to delete this access token?',
            description: 'Description for the delete token modal',
          })}
        </p>
      </Modal>
      {children}
    </DeleteApiTokenModalContext.Provider>
  );
};

export default DeleteApiTokenModalProvider;
