import { useQuery } from '@tanstack/react-query';
import { getTenants } from 'api/api';
import { fromJson } from 'translators/TenantConfigTranslator';
import assertUnreachable from 'util/assertUnreachable';
import { TenantConfig } from 'types';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  tenants: TenantConfig[];
}

const useTenants = (): Loading | Error | Success => {
  const query = useQuery({
    queryKey: ['tenants'],
    queryFn: () => getTenants(),
  });

  switch (query.status) {
    case 'error':
      return { status: 'Error' };
    case 'pending':
      return { status: 'Loading' };
    case 'success':
      return { status: 'Success', tenants: query.data.tenants.map(fromJson) };
    default:
      return assertUnreachable(query);
  }
};

export default useTenants;
