import React from 'react';
import { useLanguageSelector } from './useLanguageSelector';
import { DropdownInput, Option } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';

const LanguageSelector: React.FC = () => {
  const { formatMessage } = useIntl();
  const { localesList, localeCode, setLocaleCode } = useLanguageSelector();

  const options = localesList.map(({ title, localeCode }) => (
    <Option key={localeCode} title={title} value={localeCode}>
      {title}
    </Option>
  ));

  return (
    <DropdownInput
      label={formatMessage({
        defaultMessage: 'Select Your Language',
        description: 'Language selector label',
      })}
      value={localeCode}
      onChange={setLocaleCode}
    >
      {options}
    </DropdownInput>
  );
};

export default LanguageSelector;
