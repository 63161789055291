import { useReducer } from 'react';
import { QueryStatus } from 'types';
import assertUnreachable from 'util/assertUnreachable';

export interface State {
  visible: boolean;
  step: 'EnterSubdomain' | 'DnsRecords' | 'ConfirmSubdomain';
  subdomain?: string;
  registerSubdomainStatus: QueryStatus;
  confirmSubdomainStatus: QueryStatus;
}

export const initialState: State = {
  visible: false,
  step: 'EnterSubdomain',
  registerSubdomainStatus: 'None',
  confirmSubdomainStatus: 'None',
};

interface SubdomainChanged {
  type: 'SubdomainChanged';
  subdomain: string;
}

interface ModalOpened {
  type: 'ModalOpened';
}

interface ActionButtonClicked {
  type: 'ActionButtonClicked';
}

interface CancelButtonClicked {
  type: 'CancelButtonClicked';
}

interface RegisterSubdomainStarted {
  type: 'RegisterSubdomainStarted';
}

interface RegisterSubdomainSuccess {
  type: 'RegisterSubdomainSuccess';
}

interface RegisterSubdomainError {
  type: 'RegisterSubdomainError';
}

interface ConfirmSubdomainStarted {
  type: 'ConfirmSubdomainStarted';
}

interface ConfirmSubdomainSuccess {
  type: 'ConfirmSubdomainSuccess';
}

interface ConfirmSubdomainError {
  type: 'ConfirmSubdomainError';
}

interface CompleteSetupButtonClicked {
  type: 'CompleteSetupButtonClicked';
  subdomain: string;
}

interface CustomHostnameReady {
  type: 'CustomHostnameReady';
}

export type Events =
  | SubdomainChanged
  | ModalOpened
  | ActionButtonClicked
  | CancelButtonClicked
  | RegisterSubdomainStarted
  | RegisterSubdomainSuccess
  | RegisterSubdomainError
  | ConfirmSubdomainStarted
  | ConfirmSubdomainSuccess
  | ConfirmSubdomainError
  | CompleteSetupButtonClicked
  | CustomHostnameReady;

const reducer = (state: State, event: Events): State => {
  switch (event.type) {
    case 'SubdomainChanged':
      return { ...state, subdomain: event.subdomain };
    case 'ModalOpened':
      return {
        ...state,
        visible: true,
      };
    case 'ActionButtonClicked': {
      switch (state.step) {
        case 'ConfirmSubdomain':
          return {
            ...state,
            confirmSubdomainStatus: 'Ready',
          };
        case 'EnterSubdomain':
          return {
            ...state,
            registerSubdomainStatus: 'Ready',
          };
        case 'DnsRecords':
          return {
            ...state,
            step: 'ConfirmSubdomain',
          };
        default:
          return assertUnreachable(state.step);
      }
    }
    case 'CancelButtonClicked':
      return initialState;
    case 'ConfirmSubdomainStarted':
      return { ...state, confirmSubdomainStatus: 'Loading' };
    case 'ConfirmSubdomainError':
      return { ...state, confirmSubdomainStatus: 'Error' };
    case 'ConfirmSubdomainSuccess':
      return { ...state, confirmSubdomainStatus: 'Success', visible: false };
    case 'RegisterSubdomainStarted':
      return { ...state, registerSubdomainStatus: 'Loading' };
    case 'RegisterSubdomainError':
      return { ...state, registerSubdomainStatus: 'Error' };
    case 'RegisterSubdomainSuccess':
      return { ...state, registerSubdomainStatus: 'Success', step: 'DnsRecords' };
    case 'CompleteSetupButtonClicked':
      return {
        ...state,
        step: 'DnsRecords',
        subdomain: event.subdomain,
        visible: true,
      };
    case 'CustomHostnameReady':
      return {
        ...state,
        step: 'ConfirmSubdomain',
      };
    default:
      return assertUnreachable(event);
  }
};

const useSetupSubdomainModalReducer = (): [State, (event: Events) => void] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useSetupSubdomainModalReducer;
