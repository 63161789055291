import React from 'react';
import { loginButtonClassName, pageClassName } from './styles';
import NavBar from 'root/NavBar';
import { useIntl } from 'react-intl';
import useLoginRedirectUrl from './useLoginRedirectUrl';
import LoadingPage from './LoadingPage';
import { useCookies } from 'react-cookie';
import SnapLogo from 'asset/snap-logo.png';

const LoginPage: React.FC = () => {
  const [, setCookie] = useCookies();
  const { formatMessage } = useIntl();
  const loginRedirectUrl = useLoginRedirectUrl();

  if (!loginRedirectUrl) {
    return <LoadingPage />;
  }

  return (
    <div>
      <NavBar />
      <div className={pageClassName}>
        <h1>
          {formatMessage({
            defaultMessage: 'Login to access Conversions API Gateway',
            description: 'Login page title',
          })}
        </h1>
        <button
          className={loginButtonClassName}
          onClick={() => {
            setCookie('state', new URLSearchParams(loginRedirectUrl).get('state'));
            window.location.replace(loginRedirectUrl ?? '');
          }}
        >
          <img src={SnapLogo} height={32} alt='Snapchat Logo' />
          <span>
            {formatMessage({
              defaultMessage: 'Login with Snapchat',
              description: 'Login button text',
            })}
          </span>
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
