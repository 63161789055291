import React, { PropsWithChildren } from 'react';
import { EditTenantModalContext } from './EditTenantModalContext';
import { useIntl } from 'react-intl';
import useEditTenantModalReducer from './useEditTenantModalReducer';
import { Modal } from '@snapchat/snap-design-system';
import EditTenantModalContent from './EditTenantModalContent';
import useUpdateTenantEffect from './useUpdateTenantEffect';
import { TenantConfig } from 'types';

const EditTenantModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useEditTenantModalReducer();
  useUpdateTenantEffect(dispatch, state);

  return (
    <EditTenantModalContext.Provider
      value={{ open: (tenant: TenantConfig) => dispatch({ type: 'ModalOpened', tenant }) }}
    >
      <Modal
        visible={state.visible}
        title={formatMessage({
          defaultMessage: 'Edit Tenant',
          description: 'Edit tenant modal title',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Save',
          description: 'Save button text',
        })}
        isActionButtonPending={state.updateTenantStatus === 'Loading'}
        onCancel={() => dispatch({ type: 'ModalClosed' })}
        onAction={() => dispatch({ type: 'ActionButtonClicked' })}
        isActionButtonDisabled={!state.name}
      >
        <EditTenantModalContent dispatch={dispatch} {...state} />
      </Modal>
      {children}
    </EditTenantModalContext.Provider>
  );
};

export default EditTenantModalProvider;
