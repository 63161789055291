import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUserInvite } from 'api/api';
import { Dispatch, useEffect } from 'react';
import { useTenantId } from 'tenant';
import { Event, State } from './useAddUserModalReducer';

const useGenerateInviteCodeEffect = (dispatch: Dispatch<Event>, state: State) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['generateInviteCode'],
    mutationFn: async () => await createUserInvite(tenantId, { role: state.selectedRole, name: state.name }),
    onSuccess: (data) => {
      dispatch({ type: 'GenerateInviteCodeSuccess', inviteUrl: data.data.invite_url ?? '' });
      queryClient.invalidateQueries({ queryKey: ['users', tenantId]})
    },
    onError: () => dispatch({ type: 'GenerateInviteCodeError' }),
  });

  useEffect(() => {
    if (state.generateInviteCodeStatus === 'Ready') {
      dispatch({ type: 'GenerateInviteCodeStarted' });
      mutation.mutate();
    }
  }, [state.generateInviteCodeStatus, dispatch, mutation]);
};

export default useGenerateInviteCodeEffect;
