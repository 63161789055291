import { useCookies } from 'react-cookie';
import { setAuthToken } from './authToken';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import { SESSION_ID_COOKIE_NAME } from './constants';

export const useLogout = (): (() => void) => {
  const [, setCookie] = useCookies();
  const navigate = useNavigate();

  return () => {
    setCookie(SESSION_ID_COOKIE_NAME, '', undefined);
    setAuthToken(undefined);
    navigate(ROUTES.LOGIN);
  };
};

export default useLogout;
