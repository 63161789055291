import { AutoSizer, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiToken } from 'types';

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<ApiToken>[] = [
  {
    id: 'name',
    accessor: 'name',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Name' description='Header text for name column' />,
    Cell: ({ value }: { value: string }) => <>{value}</>,
  },
];

const ApiTokensTableSkeleton: React.FC = () => {
  return (
    <div style={{ height: ROW_HEIGHT * 3 }}>
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <VirtualTable
            columns={columns}
            data={[{}, {}]}
            loadMore={() => Promise.resolve()}
            hasMore={false}
            height={height}
            width={width}
            rowHeight={ROW_HEIGHT}
            emptyMessage={''}
            isLoading
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ApiTokensTableSkeleton;
