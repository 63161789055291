import { css } from '@emotion/css';
import { Colors, Spacing } from '@snapchat/snap-design-system';

export const dnsSetupOptionClassName = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const dnsSetupOptionBorderClassName = css({
  marginBottom: Spacing.XS,
  borderBottom: `1px solid ${Colors.Gray.V150}`,
});

export const dnsSetupOptionButtonClassName = css({
  maxHeight: '32px',
});

export const recommendedTagClassName = css({
  marginLeft: Spacing.SM,
  lineHeight: '10px',
});
