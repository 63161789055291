import { useQuery } from '@tanstack/react-query';
import { getApiTokens } from 'api/api';
import { ApiToken } from 'types';
import assertUnreachable from 'util/assertUnreachable';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  tokens: ApiToken[];
}

type Output = Loading | Error | Success;

const useApiTokens = (): Output => {
  const data = useQuery({
    queryKey: ['apiTokens'],
    queryFn: getApiTokens,
  });

  switch (data.status) {
    case 'error':
      return { status: 'Error' };
    case 'pending':
      return { status: 'Loading' };
    case 'success':
      return { status: 'Success', tokens: data.data.data.tokens };
    default:
      return assertUnreachable(data);
  }
};

export default useApiTokens;
