import { Button } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAddUserModal } from './AddUserModalProvider';
import { ProfileAddIcon } from '@snapchat/snap-design-system-icons';

const AddNewUserButton: React.FC = () => {
  const { formatMessage } = useIntl();
  const { dispatch } = useAddUserModal();

  return (
    <Button onClick={() => dispatch({ type: 'ModalOpened' })}>
      <ProfileAddIcon />
      {formatMessage({
        defaultMessage: 'Add New User',
        description: 'Add new user button',
      })}
    </Button>
  );
};

export default AddNewUserButton;
