import { AlignmentTypes, AutoSizer, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyState from 'common/EmptyState';
import { ApiToken } from 'types';
import DeleteTokenColumnRenderer from './DeleteTokenColumnRenderer';

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<ApiToken>[] = [
  {
    id: 'name',
    accessor: 'name',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Name' description='Header text for name column' />,
    Cell: ({ value }: { value: string }) => <>{value}</>,
  },
];

interface Props {
  tokens: ApiToken[];
}

const ApiTokensTable: React.FC<Props> = ({ tokens }) => {
  const { formatMessage } = useIntl();
  const [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined);

  return (
    <div style={{ height: tokens.length === 0 ? 200 : ROW_HEIGHT * (tokens.length + 1) }}>
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <VirtualTable
            columns={[
              ...columns,
              {
                id: 'actions',
                accessor: 'id',
                minWidth: 120,
                Header: () => null,
                alignment: AlignmentTypes.Right,
                Cell: (props: any) => <DeleteTokenColumnRenderer {...props} hoverIndex={hoverIndex} />,
              },
            ]}
            data={tokens}
            loadMore={() => Promise.resolve()}
            hasMore={false}
            height={height}
            width={width}
            rowHeight={ROW_HEIGHT}
            onRowMouseOver={(_event, index) => setHoverIndex(index)}
            emptyMessage={
              <EmptyState
                title={formatMessage({
                  defaultMessage: 'No tokens found',
                  description: 'Empty state title for tokens table',
                })}
              />
            }
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ApiTokensTable;
