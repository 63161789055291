import React from 'react';
import useSetupSubdomainModal from './useSetupSubdomainModal';
import SubdomainInput from './SubdomainInput';
import assertUnreachable from 'util/assertUnreachable';
import { ModalBody } from '@snapchat/snap-design-system';
import useRegisterSubdomainEffect from './useRegisterSubdomainEffect';
import useConfirmSubdomainEffect from './useConfirmSubdomainEffect';
import DnsRecordInstructions from './DnsRecordInstructions';
import FinalizeSubdomain from './FinalizeSubdomain';

const SetupSubdomainModalBody: React.FC = () => {
  const { step } = useSetupSubdomainModal();
  useRegisterSubdomainEffect();
  useConfirmSubdomainEffect();

  switch (step) {
    case 'EnterSubdomain':
      return (
        <ModalBody>
          <SubdomainInput />
        </ModalBody>
      );
    case 'DnsRecords':
      return (
        <ModalBody>
          <DnsRecordInstructions />
        </ModalBody>
      );
    case 'ConfirmSubdomain':
      return (
        <ModalBody>
          <FinalizeSubdomain />
        </ModalBody>
      );
    default:
      return assertUnreachable(step);
  }
};

export default SetupSubdomainModalBody;
