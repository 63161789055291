import { Notification } from '@snapchat/snap-design-system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTenantConfig } from 'api/api';
import { ADMIN_TENANT_ID } from 'common/constants';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { toJson } from 'translators/AdminConfigTranslator';
import { AdminConfig } from 'types';

const useSetDnsProvider = (): ((admin: AdminConfig) => Promise<unknown>) => {
  const { error } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (admin: AdminConfig) => updateTenantConfig(toJson(admin)),
    onError: () =>
      error({
        title: formatMessage({
          defaultMessage: 'Failed to set DNS provider',
          description: 'Failed to set DNS provider error message',
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tenantConfig', ADMIN_TENANT_ID] });
    },
  });

  return (admin: AdminConfig) => mutation.mutateAsync(admin);
};

export default useSetDnsProvider;
