import { useReducer } from 'react';
import { QueryStatus } from 'types';
import assertUnreachable from 'util/assertUnreachable';

export interface State {
  token?: string;
  saveTokenStatus: QueryStatus;
  tokenValidationStatus: QueryStatus;
}

const initialState: State = {
  saveTokenStatus: 'None',
  tokenValidationStatus: 'None',
};

interface TokenChanged {
  type: 'TokenChanged';
  token: string;
}

interface SaveButtonClicked {
  type: 'SaveButtonClicked';
}

interface SaveTokenStarted {
  type: 'SaveTokenStarted';
}

interface SaveTokenSucceeded {
  type: 'SaveTokenSucceeded';
}

interface SaveTokenFailed {
  type: 'SaveTokenFailed';
}

interface ValidateTokenStarted {
  type: 'ValidateTokenStarted';
}

interface ValidateTokenSucceeded {
  type: 'ValidateTokenSucceeded';
  valid: boolean;
}

interface ValidateTokenFailed {
  type: 'ValidateTokenFailed';
}

export type Events =
  | TokenChanged
  | SaveButtonClicked
  | SaveTokenSucceeded
  | SaveTokenFailed
  | SaveTokenStarted
  | ValidateTokenStarted
  | ValidateTokenSucceeded
  | ValidateTokenFailed;

const reducer = (state: State, event: Events): State => {
  switch (event.type) {
    case 'SaveButtonClicked':
      return {
        ...state,
        saveTokenStatus: 'Ready',
      };
    case 'TokenChanged':
      return {
        ...state,
        token: event.token,
        tokenValidationStatus: event.token ? 'Ready' : 'None',
      };
    case 'SaveTokenSucceeded':
      return {
        ...state,
        saveTokenStatus: 'Success',
      };
    case 'SaveTokenFailed':
      return {
        ...state,
        saveTokenStatus: 'Error',
      };
    case 'SaveTokenStarted':
      return {
        ...state,
        saveTokenStatus: 'Loading',
      };
    case 'ValidateTokenStarted':
      return {
        ...state,
        tokenValidationStatus: 'Loading',
      };
    case 'ValidateTokenSucceeded':
      return {
        ...state,
        tokenValidationStatus: event.valid ? 'Success' : 'Error',
      };
    case 'ValidateTokenFailed':
      return {
        ...state,
        tokenValidationStatus: 'Error',
      };
    default:
      return assertUnreachable(event);
  }
};

const useCloudflareTokenInputReducer = (): [State, (event: Events) => void] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useCloudflareTokenInputReducer;
