import { useIntl } from 'react-intl';
import { CURRENT_DATE, END_OF_TODAY, ONE_WEEK_AGO, START_OF_TODAY, TWENTY_EIGHT_DAYS_AGO } from './constants';
import { DateRangePickerProps } from '@snapchat/snap-design-system';

const useShortcuts = (): DateRangePickerProps['shortcuts'] => {
  const { formatMessage } = useIntl();
  return [
    {
      id: 'today',
      label: formatMessage({
        defaultMessage: 'Today',
        description: 'Today date range shortcut',
      }),
      value: {
        from: START_OF_TODAY,
        to: END_OF_TODAY,
      },
    },
    {
      id: '1week',
      label: formatMessage({
        defaultMessage: 'Last week',
        description: '1 week date range shortcut',
      }),
      value: {
        from: ONE_WEEK_AGO,
        to: CURRENT_DATE,
      },
    },
    {
      id: '28days',
      label: formatMessage({
        defaultMessage: 'Last 28 days',
        description: '28 days date range shortcut',
      }),
      value: {
        from: TWENTY_EIGHT_DAYS_AGO,
        to: CURRENT_DATE,
      },
    },
  ];
};

export default useShortcuts;
