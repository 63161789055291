import React from 'react';
import useConnectedPixelStatus from './useConnectedPixelStatus';
import assertUnreachable from 'util/assertUnreachable';
import { useIntl } from 'react-intl';
import { Skeleton, StatusLabel, TooltipIndicator } from '@snapchat/snap-design-system';

interface Props {
  pixelId: string;
}

const ConnectedPixelStatus: React.FC<Props> = ({ pixelId }) => {
  const { formatMessage } = useIntl();
  const pixelStatus = useConnectedPixelStatus(pixelId);

  switch (pixelStatus) {
    case 'Connected':
      return (
        <StatusLabel status='success'>
          {formatMessage({
            defaultMessage: 'Connected',
            description: 'Connected pixel status message',
          })}
        </StatusLabel>
      );
    case 'Loading':
      return <Skeleton />;
    case 'Error':
      return (
        <StatusLabel status='error'>
          {formatMessage({
            defaultMessage: 'Unable to retrieve status',
            description: 'Unable to retrieve pixel status message',
          })}
        </StatusLabel>
      );
    case 'MismatchedSubdomain':
      return (
        <StatusLabel status='error'>
          {formatMessage({
            defaultMessage: 'Incorrect Data Routing',
            description: 'Mismatched subdomain status message',
          })}
          <TooltipIndicator
            marginPlacement='left'
            content={formatMessage({
              defaultMessage:
                "The gateway domain associated with this Pixel does not match your Gateway's domain. If you recently completed setting up data routing this is expected, otherwise please refresh this Pixel's connection.",
              description: 'Mismatch gateway config message',
            })}
          />
        </StatusLabel>
      );
    case 'RecentlyConnected':
      return (
        <StatusLabel status='success'>
          {formatMessage({
            defaultMessage: 'Connected',
            description: 'Recently Connected Pixel status message',
          })}
          <TooltipIndicator
            marginPlacement='left'
            content={formatMessage({
              defaultMessage:
                "This Pixel isn't sending events to the Conversions API Gateway yet. This may take up to 30 minutes after initially connecting.",
              description: 'Pixel recently connected status tooltip',
            })}
          />
        </StatusLabel>
      );
    case 'NoConfigFound':
      return (
        <StatusLabel status='inactive'>
          {formatMessage({
            defaultMessage: 'Inactive',
            description: 'Inactive Pixel status message',
          })}
        </StatusLabel>
      );
    default:
      return assertUnreachable(pixelStatus);
  }
};

export default ConnectedPixelStatus;
