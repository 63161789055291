import { useQuery } from '@tanstack/react-query';
import { getDnsInfo } from 'api/api';
import { useTenantConfig, useTenantId } from 'tenant';
import assertUnreachable from 'util/assertUnreachable';

interface Error {
  status: 'Error';
}

interface Loading {
  status: 'Loading';
}

interface Setup {
  status: 'Setup';
  subdomain: string;
  pixelHostingEnabled: boolean;
}

interface NotSetup {
  status: 'NotSetup';
}

interface SubdomainNotActive {
  status: 'SubdomainNotActive';
  subdomain: string;
}

type Output = Error | Loading | Setup | NotSetup | SubdomainNotActive;

const useSubdomainSetupStatus = (): Output => {
  const tenantId = useTenantId();
  const tenantConfig = useTenantConfig();
  const dnsData = useQuery({
    queryKey: ['dnsInfo', tenantId],
    queryFn: async () => await getDnsInfo(tenantId),
  });

  switch (tenantConfig.status) {
    case 'Loading':
      return { status: 'Loading' };
    case 'Error':
      return { status: 'Error' };
    case 'Success': {
      if (!tenantConfig.tenantConfig.subdomain) {
        return {
          status: 'NotSetup',
        };
      }

      if (!tenantConfig.tenantConfig.isSubdomainActive) {
        return {
          status: 'SubdomainNotActive',
          subdomain: tenantConfig.tenantConfig.subdomain ?? '',
        };
      }

      switch (dnsData.status) {
        case 'error':
          return { status: 'Error' };
        case 'pending':
          return { status: 'Loading' };
        case 'success':
          return {
            status: 'Setup',
            subdomain: tenantConfig.tenantConfig.subdomain ?? '',
            pixelHostingEnabled: !!dnsData.data.data.pixel_hosting_enabled,
          };
        default:
          return assertUnreachable(dnsData);
      }
    }
    default:
      return assertUnreachable(tenantConfig);
  }
};

export default useSubdomainSetupStatus;
