import React, { PropsWithChildren } from 'react';
import { DeactivateTenantModalContext } from './DeactivateTenantModalContext';
import { useIntl } from 'react-intl';
import useDeactivateTenantModalReducer from './useDeactivateTenantModalReducer';
import { Modal } from '@snapchat/snap-design-system';
import DeactivateTenantModalContent from './DeactivateTenantModalContent';
import useDeactivateTenantEffect from './useDeactivateTenantEffect';
import { TenantConfig } from 'types';

const DeactivateTenantModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useDeactivateTenantModalReducer();
  useDeactivateTenantEffect(dispatch, state);

  return (
    <DeactivateTenantModalContext.Provider
      value={{ open: (tenant: TenantConfig) => dispatch({ type: 'ModalOpened', tenant }) }}
    >
      <Modal
        visible={state.visible}
        title={formatMessage({
          defaultMessage: 'Are you sure you want to deactivate this account?',
          description: 'Deactivate tenant modal title',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Deactivate',
          description: 'Save button text',
        })}
        isActionButtonPending={state.updateTenantStatus === 'Loading'}
        onCancel={() => dispatch({ type: 'ModalClosed' })}
        onAction={() => dispatch({ type: 'ActionButtonClicked' })}
      >
        <DeactivateTenantModalContent dispatch={dispatch} {...state} />
      </Modal>
      {children}
    </DeactivateTenantModalContext.Provider>
  );
};

export default DeactivateTenantModalProvider;
