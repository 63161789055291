import { Reducer, useReducer } from 'react';
import { TenantConfig } from 'types';
import assertUnreachable from 'util/assertUnreachable';

export interface State {
  visible: boolean;
  updateTenantStatus: 'Idle' | 'Ready' | 'Loading' | 'Success' | 'Error';
  tenant?: TenantConfig;
}

interface ActionButtonClicked {
  type: 'ActionButtonClicked';
}

interface ModalClosed {
  type: 'ModalClosed';
}

interface ModalOpened {
  type: 'ModalOpened';
  tenant: TenantConfig;
}

interface UpdateTenantStarted {
  type: 'UpdateTenantStarted';
}

interface UpdateTenantSuccess {
  type: 'UpdateTenantSuccess';
}

interface UpdateTenantError {
  type: 'UpdateTenantError';
}

export type Event =
  | ActionButtonClicked
  | ModalClosed
  | ModalOpened
  | UpdateTenantStarted
  | UpdateTenantSuccess
  | UpdateTenantError;

const initialState: State = {
  visible: false,
  updateTenantStatus: 'Idle',
};

const reducer = (state: State, event: Event): State => {
  switch (event.type) {
    case 'ActionButtonClicked':
      return {
        ...state,
        updateTenantStatus: 'Ready',
      };
    case 'ModalClosed':
      return initialState;
    case 'ModalOpened':
      return {
        ...initialState,
        visible: true,
        tenant: event.tenant,
      };
    case 'UpdateTenantStarted':
      return {
        ...state,
        updateTenantStatus: 'Loading',
      };
    case 'UpdateTenantError':
      return {
        ...state,
        updateTenantStatus: 'Error',
      };
    case 'UpdateTenantSuccess':
      return {
        ...state,
        updateTenantStatus: 'Success',
        visible: false,
      };
    default:
      return assertUnreachable(event);
  }
};

const useDeactivateTenantModalReducer = () => {
  return useReducer<Reducer<State, Event>>(reducer, initialState);
};

export default useDeactivateTenantModalReducer;
