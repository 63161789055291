import { Reducer, useReducer } from 'react';
import { User, UserRole } from 'users/types';
import assertUnreachable from 'util/assertUnreachable';

export interface State {
  selectedRole: UserRole;
  visible: boolean;
  updateUserStatus: 'Idle' | 'Ready' | 'Loading' | 'Success' | 'Error';
  name?: string;
  initUser?: User;
}

interface RoleSelectedEvent {
  type: 'RoleSelected';
  role: UserRole;
}

interface ActionButtonClicked {
  type: 'ActionButtonClicked';
}

interface ModalClosed {
  type: 'ModalClosed';
}

interface ModalOpened {
  type: 'ModalOpened';
  user: User;
}

interface UpdateUserStarted {
  type: 'UpdateUserStarted';
}

interface UpdateUserSuccess {
  type: 'UpdateUserSuccess';
}

interface UpdateUserError {
  type: 'UpdateUserError';
}

interface NameChanged {
  type: 'NameChanged';
  name: string;
}

export type Event =
  | RoleSelectedEvent
  | ActionButtonClicked
  | ModalClosed
  | ModalOpened
  | UpdateUserStarted
  | UpdateUserSuccess
  | UpdateUserError
  | NameChanged;

const initialState: State = {
  selectedRole: UserRole.Member,
  visible: false,
  updateUserStatus: 'Idle',
};

const reducer = (state: State, event: Event): State => {
  switch (event.type) {
    case 'RoleSelected':
      return {
        ...state,
        selectedRole: event.role,
      };
    case 'ActionButtonClicked':
      return {
        ...state,
        updateUserStatus: 'Ready',
      };
    case 'ModalClosed':
      return initialState;
    case 'ModalOpened':
      return {
        ...initialState,
        visible: true,
        initUser: event.user,
        name: event.user.name,
        selectedRole: event.user.role ?? state.selectedRole,
      };
    case 'UpdateUserStarted':
      return {
        ...state,
        updateUserStatus: 'Loading',
      };
    case 'UpdateUserError':
      return {
        ...state,
        updateUserStatus: 'Error',
      };
    case 'UpdateUserSuccess':
      return {
        ...state,
        updateUserStatus: 'Success',
        visible: false,
      };
    case 'NameChanged':
      return {
        ...state,
        name: event.name,
      };
    default:
      return assertUnreachable(event);
  }
};

const useEditUserModalReducer = () => {
  return useReducer<Reducer<State, Event>>(reducer, initialState);
};

export default useEditUserModalReducer;
