import { Notification } from '@snapchat/snap-design-system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTenantConfig } from 'api/api';
import { ADMIN_TENANT_ID } from 'common/constants';
import { Dispatch, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toJson } from 'translators/AdminConfigTranslator';
import { AdminConfig } from 'types';
import { Events, State } from './useCloudflareTokenInputReducer';

const useSetCloudflareTokenEffect = (dispatch: Dispatch<Events>, state: State, admin: AdminConfig) => {
  const { error } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (admin: AdminConfig) => updateTenantConfig(toJson(admin)),
    onError: () =>
      error({
        title: formatMessage({
          defaultMessage: 'Failed to set Cloudflare token',
          description: 'Failed to set Cloudflare token error message',
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tenantConfig', ADMIN_TENANT_ID] });
    },
  });

  useEffect(() => {
    if (state.saveTokenStatus === 'Ready') {
      dispatch({ type: 'SaveTokenStarted' });
      mutation.mutate({ ...admin, cloudflareToken: state.token });
    }
  }, [state.saveTokenStatus, state.token, dispatch, mutation, admin]);

  return (admin: AdminConfig) => mutation.mutateAsync(admin);
};

export default useSetCloudflareTokenEffect;
