import { AUTH_REDIRECT_COOKIE_NAME } from './constants';
import { useCookies } from 'react-cookie';

const useAuthRedirect = () => {
  const [cookies, setCookie] = useCookies();
  const authRedirect = cookies[AUTH_REDIRECT_COOKIE_NAME];
  const setAuthRedirect = (value: string) => {
    setCookie(AUTH_REDIRECT_COOKIE_NAME, value ?? '', { maxAge: 60 * 2 });
  };

  return [authRedirect, setAuthRedirect];
};

export default useAuthRedirect;
