import { css } from '@emotion/css';
import { Spacing } from '@snapchat/snap-design-system';

export const setupSectionLayoutClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: Spacing.MD,
  justifyContent: 'space-between',
  width: '100%',
});

export const rightContentClassName = css({
  display: 'flex',
  gap: Spacing.XS,
});
