import { Reducer, useReducer } from 'react';
import { QueryStatus } from 'types';
import assertUnreachable from 'util/assertUnreachable';

export interface State {
  step: 'NameInput' | 'CopyToken';
  visible: boolean;
  generateTokenStatus: QueryStatus;
  tokenValue?: string;
  name?: string;
}

interface NextButtonClicked {
  type: 'NextButtonClicked';
}

interface ModalClosed {
  type: 'ModalClosed';
}

interface ModalOpened {
  type: 'ModalOpened';
}

interface GenerateTokenCodeStarted {
  type: 'GenerateTokenCodeStarted';
}

interface GenerateTokenCodeSuccess {
  type: 'GenerateTokenCodeSuccess';
  tokenValue: string;
}

interface GenerateTokenCodeError {
  type: 'GenerateTokenCodeError';
}

interface NameChanged {
  type: 'NameChanged';
  name: string;
}

interface ViewTokenClicked {
  type: 'ViewTokenClicked';
  tokenValue: string;
}

export type Event =
  | NextButtonClicked
  | ModalClosed
  | ModalOpened
  | GenerateTokenCodeStarted
  | GenerateTokenCodeSuccess
  | GenerateTokenCodeError
  | NameChanged
  | ViewTokenClicked;

const initialState: State = {
  step: 'NameInput',
  visible: false,
  generateTokenStatus: 'None',
};

const reducer = (state: State, event: Event): State => {
  switch (event.type) {
    case 'NextButtonClicked': {
      switch (state.step) {
        case 'NameInput':
          return {
            ...state,
            generateTokenStatus: 'Ready',
          };
        case 'CopyToken':
          return {
            ...state,
            visible: false,
          };
        default:
          return assertUnreachable(state.step);
      }
    }
    case 'ModalClosed':
      return initialState;
    case 'ModalOpened':
      return {
        ...initialState,
        visible: true,
      };
    case 'GenerateTokenCodeStarted':
      return {
        ...state,
        generateTokenStatus: 'Loading',
      };
    case 'GenerateTokenCodeError':
      return {
        ...state,
        generateTokenStatus: 'Error',
      };
    case 'GenerateTokenCodeSuccess':
      return {
        ...state,
        step: 'CopyToken',
        generateTokenStatus: 'Success',
        tokenValue: event.tokenValue,
      };
    case 'NameChanged':
      return {
        ...state,
        name: event.name,
      };
    case 'ViewTokenClicked':
      return {
        ...state,
        visible: true,
        step: 'CopyToken',
        tokenValue: event.tokenValue,
      };
    default:
      return assertUnreachable(event);
  }
};

const useGenerateTokenModalReducer = () => {
  return useReducer<Reducer<State, Event>>(reducer, initialState);
};

export default useGenerateTokenModalReducer;
