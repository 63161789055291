import { useQuery } from '@tanstack/react-query';
import { getDnsInfo } from 'api/api';
import useTenantId from 'tenant/useTenantId';
import assertUnreachable from 'util/assertUnreachable';

const useIsDnsSetupTypeSet = (): 'Loading' | 'Setup' | 'NotSetup' => {
  const tenantId = useTenantId();

  const dnsInfo = useQuery({
    queryKey: ['getDnsInfo', tenantId],
    queryFn: async () => await getDnsInfo(tenantId),
  });

  switch (dnsInfo.status) {
    case 'pending':
      return 'Loading';
    case 'error':
      return 'NotSetup';
    case 'success':
      return dnsInfo.data.data.dns_setup_type ? 'Setup' : 'NotSetup';
    default:
      return assertUnreachable(dnsInfo);
  }
};

export default useIsDnsSetupTypeSet;
