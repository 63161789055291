import { Role } from 'api/types';
import useSessionInfo from './SessionInfoProvider/useSessionInfo';
import { ADMIN_TENANT_ID } from 'common/constants';

const useIsGatewayAdmin = (): boolean => {
  const { userTenants } = useSessionInfo();
  return userTenants.some((tenant) => tenant.tenantId === ADMIN_TENANT_ID && tenant.role === Role.Admin);
};

export default useIsGatewayAdmin;
