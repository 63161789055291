import { Toast } from '@snapchat/snap-design-system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTenantConfig } from 'api/api';
import { ADMIN_TENANT_ID } from 'common/constants';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { toJson } from 'translators/AdminConfigTranslator';
import { AdminConfig } from 'types';

const useTogglePixelHosting = () => {
  const { formatMessage } = useIntl();
  const { error } = useContext(Toast.Context);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['updateTenant', ADMIN_TENANT_ID],
    mutationFn: async (config: AdminConfig) => await updateTenantConfig(toJson(config)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tenantConfig', ADMIN_TENANT_ID] }),
    onError: () =>
      error({
        title: formatMessage({
          defaultMessage: 'Failed to update Pixel Hosting status',
          description: 'Error message when updating Pixel Hosting status fails',
        }),
      }),
  });

  return mutation;
};

export default useTogglePixelHosting;
