import { AutoSizer, Section, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { User } from '../types';
import { usersSectionClassName } from '../styles';

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<User>[] = [
  {
    id: 'name',
    accessor: 'name',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Name' description='Header text for name column' />,
    Cell: () => <></>,
  },
  {
    id: 'role',
    accessor: 'role',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Role' description='Header text for role column' />,
    Cell: () => <></>,
  },
  {
    id: 'actions',
    accessor: 'id',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Actions' description='Header text for actions column' />,
    Cell: () => <></>,
  },
];

const UsersTableSkeleton: React.FC = () => {
  return (
    <Section className={usersSectionClassName}>
      <div style={{ height: ROW_HEIGHT * 2 }}>
        <AutoSizer>
          {({ width, height }: { width: number; height: number }) => (
            <VirtualTable
              columns={columns}
              data={[{}]}
              loadMore={() => Promise.resolve()}
              hasMore={false}
              height={height}
              width={width}
              rowHeight={ROW_HEIGHT}
              emptyMessage={''}
              isLoading
            />
          )}
        </AutoSizer>
      </div>
    </Section>
  );
};

export default UsersTableSkeleton;
