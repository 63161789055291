import { css } from '@emotion/css';
import { Spacing } from '@snapchat/snap-design-system';

export const cloudflareTokenInputContainerClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

export const cloudflareTokenInputClassName = css({
  flex: 1,
});

export const cloudflareTokenInputButtonClassName = css({
  marginLeft: Spacing.SM,
  maxHeight: '32px',
});
