import { Button, Spinner, Tooltip } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import { ProfileAddIcon } from '@snapchat/snap-design-system-icons';
import useAddTenantModal from './AddTenantModalProvider/useAddTenantModal';
import useIsDnsSetupTypeSet from './useIsDnsSetupTypeSet';
import assertUnreachable from 'util/assertUnreachable';

const AddNewTenantButton: React.FC = () => {
  const { formatMessage } = useIntl();
  const { open } = useAddTenantModal();
  const status = useIsDnsSetupTypeSet();

  switch (status) {
    case 'Loading':
      return <Spinner />;
    case 'Setup':
      return (
        <Button onClick={open}>
          <ProfileAddIcon />
          {formatMessage({
            defaultMessage: 'Add New Account',
            description: 'Add new account button',
          })}
        </Button>
      );
    case 'NotSetup':
      return (
        <Tooltip
          content={formatMessage({
            defaultMessage:
              'Please select your DNS setup method on the Data Routing page before adding additional accounts',
            description: 'Disabled add tenants button tooltip',
          })}
        >
          <Button isDisabled>
            <ProfileAddIcon />
            {formatMessage({
              defaultMessage: 'Add New Account',
              description: 'Add new account button',
            })}
          </Button>
        </Tooltip>
      );
    default:
      return assertUnreachable(status);
  }
};

export default AddNewTenantButton;
