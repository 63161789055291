import React from 'react';
import { useIntl } from 'react-intl';
import { Card } from '@snapchat/snap-design-system';
import DeleteSubdomainSetupButton from './DeleteSubdomainSetupButton';
import { rightContentClassName } from './styles';

interface Props {
  subdomain: string;
  readOnly?: boolean;
}

const DataRoutingSummarySection: React.FC<Props> = ({ subdomain, readOnly }) => {
  const { formatMessage } = useIntl();

  return (
    <Card
      title={formatMessage({
        defaultMessage: 'You have successfully setup your subdomain',
        description: 'Data Routing Summary section title',
      })}
      subtitle={subdomain}
      status='success'
      // TODO (rgreen): Add support for editing the subdomain
      rightHeaderContent={
        !readOnly && (
          <div className={rightContentClassName}>
            <DeleteSubdomainSetupButton />
          </div>
        )
      }
    >
      <div>
        <p>
          {formatMessage({
            defaultMessage: 'All Pixels attached to this Gateway will send events to this subdomain.',
            description: 'Data Routing Setup section description',
          })}
        </p>
      </div>
    </Card>
  );
};

export default DataRoutingSummarySection;
