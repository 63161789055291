import { Role } from 'api/types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { State, Event } from './useAddUserModalReducer';
import assertUnreachable from 'util/assertUnreachable';
import { Button, Input, Radio, RadioGroup } from '@snapchat/snap-design-system';
import { copyLinkInputClassName, copyLinkRowClassName, nameInputClassName } from './styles';
import { CheckIcon } from '@snapchat/snap-design-system-icons';

interface Props extends State {
  dispatch: React.Dispatch<Event>;
}

const AddUserModalContent: React.FC<Props> = ({
  dispatch,
  selectedRole,
  step,
  generateInviteCodeStatus,
  inviteUrl,
  name,
}) => {
  const { formatMessage } = useIntl();
  const [copied, setCopied] = useState(false);

  switch (step) {
    case 'CopyLink':
      return (
        <div>
          <p>
            {formatMessage({
              defaultMessage:
                'Copy this invitation link and share it with new user. This link will expire in 24 hours.',
              description: 'Copy link step text',
            })}
          </p>
          <div className={copyLinkRowClassName}>
            <Input value={inviteUrl} className={copyLinkInputClassName} />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(inviteUrl ?? '');
                setCopied(true);
              }}
            >
              {copied ? (
                <>
                  <CheckIcon />
                  {formatMessage({
                    defaultMessage: 'Copied',
                    description: 'Copied button text',
                  })}
                </>
              ) : (
                formatMessage({
                  defaultMessage: 'Copy Link',
                  description: 'Copy link button text',
                })
              )}
            </Button>
          </div>
        </div>
      );
    case 'RoleSelection':
      return (
        <div>
          <Input
            value={name}
            onChange={(name) => dispatch({ type: 'NameChanged', name })}
            label={formatMessage({
              defaultMessage: 'Name',
              description: 'Name input label',
            })}
            placeholder={formatMessage({
              defaultMessage: 'Enter name',
              description: 'Name input placeholder',
            })}
            className={nameInputClassName}
          />
          <RadioGroup
            value={selectedRole}
            onChange={(value) => dispatch({ type: 'RoleSelected', role: value })}
            disabled={generateInviteCodeStatus === 'Loading'}
          >
            <Radio
              value={Role.Member}
              label={formatMessage({
                defaultMessage: 'Member',
                description: 'Role option for member',
              })}
            />
            <Radio
              value={Role.Admin}
              label={formatMessage({
                defaultMessage: 'Admin',
                description: 'Role option for admin',
              })}
            />
          </RadioGroup>
        </div>
      );
    default:
      return assertUnreachable(step);
  }
};

export default AddUserModalContent;
