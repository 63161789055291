import React, { PropsWithChildren } from 'react';
import SessionInfoContext from './SessionInfoContext';
import { SessionInfo } from 'auth/types';

interface Props extends PropsWithChildren {
  sessionInfo: SessionInfo;
}

const SessionInfoProvider: React.FC<Props> = ({ children, sessionInfo }) => {
  return <SessionInfoContext.Provider value={sessionInfo}>{children}</SessionInfoContext.Provider>;
};

export default SessionInfoProvider;
