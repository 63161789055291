import { Switch } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import { AdminConfig } from 'types';
import useTogglePixelHosting from './useTogglePixelHosting';

interface Props {
  config: AdminConfig;
}

const EnablePixelHostingToggle: React.FC<Props> = ({ config }) => {
  const { formatMessage } = useIntl();
  const mutation = useTogglePixelHosting();

  return (
    <Switch
      label={formatMessage({
        defaultMessage: 'Enable Pixel Hosting',
        description: 'Toggle label for enabling Pixel hosting',
      })}
      tooltip={formatMessage({
        defaultMessage:
          'This will allow accounts of this gateway to point their Pixel scripts to this gateway. This will help',
        description: 'Tooltip for enabling Pixel hosting',
      })}
      isChecked={config.pixelHostingEnabled}
      onChange={(newVal: boolean) => mutation.mutate({ ...config, pixelHostingEnabled: newVal })}
      checkedSwitchLabel={formatMessage({
        defaultMessage: 'Enabled',
        description: 'Enabled label for toggle',
      })}
      uncheckedSwitchLabel={formatMessage({
        defaultMessage: 'Disabled',
        description: 'Disabled label for toggle',
      })}
      isDisabled={mutation.status === 'pending'}
    />
  );
};

export default EnablePixelHostingToggle;
