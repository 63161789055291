import { Dropdown, Menu, MenuItem } from '@snapchat/snap-design-system';
import { EllipsisIcon } from '@snapchat/snap-design-system-icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { actionDropdownClassName } from './styles';
import useDeleteTenantModal from '../DeleteTenantModalProvider/useDeleteTenantModal';
import { useEditTenantModal } from '../EditTenantModalProvider';
import { TenantConfig } from 'types';
import { ADMIN_TENANT_ID, ROUTES } from 'common/constants';
import isAuthorized from 'auth/isAuthorized';
import useSelectedTenant from 'tenant/useSelectedTenant';
import { useAtom } from 'jotai';
import { tenantIdAtom } from 'tenant/useTenantId';
import { useNavigate } from 'react-router-dom';
import { TenantStatus } from 'api/types';
import { useDeactivateTenantModal } from '../DeactivateTenantModalProvider';
import useReactivateTenant from './useReactivateTenant';

interface Props {
  value: string;
  row: {
    original: TenantConfig;
  };
}

const TenantActionsColumnRenderer: React.FC<Props> = ({ value: tenantId, row }) => {
  const { formatMessage } = useIntl();
  const { open: openDeleteModal } = useDeleteTenantModal();
  const { open: openEditModal } = useEditTenantModal();
  const { open: openDeactivateModal } = useDeactivateTenantModal();
  const reactivateTenant = useReactivateTenant();
  const { role } = useSelectedTenant();
  const [, setTenantId] = useAtom(tenantIdAtom);
  const navigate = useNavigate();

  return (
    <Dropdown
      overlay={
        <Menu>
          <MenuItem
            onClick={() => {
              setTenantId(tenantId);
              navigate(ROUTES.OVERVIEW);
            }}
            key='goto'
          >
            {formatMessage({
              defaultMessage: 'Go to Account',
              description: 'Go to tenant',
            })}
          </MenuItem>
          <MenuItem
            onClick={() => openEditModal(row.original)}
            key='edit'
            disabled={!isAuthorized({ role, entity: 'Tenant', action: 'Update' })}
          >
            {formatMessage({
              defaultMessage: 'Edit',
              description: 'Edit tenant',
            })}
          </MenuItem>
          {row.original.status === TenantStatus.Inactive ? (
            <MenuItem
              onClick={async () => {
                try {
                  await reactivateTenant(row.original);
                } catch (e) {}
              }}
              key='reactivate'
              disabled={!isAuthorized({ role, entity: 'Tenant', action: 'Update' })}
            >
              {formatMessage({
                defaultMessage: 'Reactivate',
                description: 'Reactivate tenant',
              })}
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => openDeactivateModal(row.original)}
              key='deactivate'
              disabled={!isAuthorized({ role, entity: 'Tenant', action: 'Update' })}
            >
              {formatMessage({
                defaultMessage: 'Deactivate',
                description: 'Deactivate tenant',
              })}
            </MenuItem>
          )}
          {tenantId !== ADMIN_TENANT_ID && (
            <MenuItem
              onClick={() => openDeleteModal(tenantId)}
              key='delete'
              disabled={!isAuthorized({ role, entity: 'Tenant', action: 'Delete' })}
            >
              {formatMessage({
                defaultMessage: 'Delete',
                description: 'Delete tenant',
              })}
            </MenuItem>
          )}
        </Menu>
      }
      className={actionDropdownClassName}
    >
      <EllipsisIcon />
    </Dropdown>
  );
};

export default TenantActionsColumnRenderer;
