import { Link, NotFound } from '@snapchat/snap-design-system';
import { ROUTES } from 'common/constants';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { notFoundDescriptionClassName } from './styles';

const NotFoundPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const returnHome = (
    <Link onClick={() => navigate(ROUTES.OVERVIEW)}>
      {formatMessage({
        defaultMessage: 'return home',
        description: 'Link text to return to the home page',
      })}
    </Link>
  );

  return (
    <NotFound
      subtitle={formatMessage({
        defaultMessage: '404 Not Found',
        description: 'Subtitle for 404 Not Found page',
      })}
      title={formatMessage({
        defaultMessage: "Lost? Let's guide you home.",
        description: 'Title for 404 Not Found page',
      })}
      description={
        <span className={notFoundDescriptionClassName}>
          {formatMessage(
            {
              defaultMessage:
                "It looks like we can't find the page you're looking for. Make sure the URL is correct and try again or {returnHome}.",
              description: 'Description for 404 Not Found page',
            },
            { returnHome },
          )}
        </span>
      }
    />
  );
};

export default NotFoundPage;
