import React from 'react';
import { useDeleteApiTokenModal } from './ConfirmDeleteApiTokenModalProvider';
import { IconButton } from '@snapchat/snap-design-system';
import { TrashIcon } from '@snapchat/snap-design-system-icons';

interface Props {
  value: string;
  hoverIndex: number | undefined;
  row: {
    index: number;
  };
}

const DeleteTokenColumnRenderer: React.FC<Props> = ({ value, hoverIndex, row }) => {
  const { open } = useDeleteApiTokenModal();

  if (hoverIndex === row.index) {
    return <IconButton icon={<TrashIcon />} onClick={() => open(value)} shape='circle' type='default' />;
  }

  return null;
};

export default DeleteTokenColumnRenderer;
