import { DropdownInput, Option } from '@snapchat/snap-design-system';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useUpdateUser from './useUpdateUser';
import { User, UserRole } from 'users/types';
import useSelectedTenant from 'tenant/useSelectedTenant';
import isAuthorized from 'auth/isAuthorized';

interface Props {
  row: {
    index: number;
    original: User;
  };
}

const UserRoleColumnRenderer: React.FC<Props> = ({ row }) => {
  const { formatMessage } = useIntl();
  const updateUser = useUpdateUser();
  const [role, setRole] = useState<UserRole | undefined>(row.original.role);
  const { role: curUserRole } = useSelectedTenant();

  return (
    <DropdownInput
      readOnly={!isAuthorized({ role: curUserRole, entity: 'User', action: 'Update' })}
      value={role}
      loading={updateUser.status === 'pending'}
      onChange={async (value: UserRole) => {
        const prevValue = role;
        setRole(value);
        try {
          await updateUser.mutateAsync({
            ...row.original,
            role: value,
          });
        } catch (e) {
          setRole(prevValue);
        }
      }}
    >
      <Option value={UserRole.Admin}>
        {formatMessage({
          defaultMessage: 'Admin',
          description: 'Admin role label',
        })}
      </Option>
      <Option value={UserRole.Member}>
        {formatMessage({
          defaultMessage: 'Member',
          description: 'Member role label',
        })}
      </Option>
    </DropdownInput>
  );
};

export default UserRoleColumnRenderer;
