import React, { PropsWithChildren } from 'react';
import { GenerateTokenModalContext } from './GenerateTokenModalContext';
import { defineMessages, useIntl } from 'react-intl';
import useGenerateTokenModalReducer, { State } from './useGenerateTokenModalReducer';
import { Modal } from '@snapchat/snap-design-system';
import GenerateTokenModalContent from './GenerateTokenModalContent';
import useGenerateTokenEffect from './useGenerateTokenEffect';

const actionButtonMessage = defineMessages<State['step']>({
  CopyToken: {
    defaultMessage: 'Done',
    description: 'Copy link step button text',
  },
  NameInput: {
    defaultMessage: 'Next',
    description: 'Next button text',
  },
});

const GenerateTokenModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useGenerateTokenModalReducer();
  useGenerateTokenEffect(dispatch, state);

  return (
    <GenerateTokenModalContext.Provider value={{ dispatch }}>
      <Modal
        visible={state.visible}
        title={formatMessage({
          defaultMessage: 'Generate New Token',
          description: 'Add user modal title',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage(actionButtonMessage[state.step])}
        isActionButtonPending={state.generateTokenStatus === 'Loading'}
        onCancel={() => dispatch({ type: 'ModalClosed' })}
        onAction={() => dispatch({ type: 'NextButtonClicked' })}
        isActionButtonDisabled={state.step === 'NameInput' && !state.name}
      >
        <GenerateTokenModalContent dispatch={dispatch} {...state} />
      </Modal>
      {children}
    </GenerateTokenModalContext.Provider>
  );
};

export default GenerateTokenModalProvider;
