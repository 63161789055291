import { css } from '@emotion/css';
import { Spacing } from '@snapchat/snap-design-system';

export const finalizeSubdomainContainerClassName = css({
  display: 'flex',
  maxWidth: 600,
  minWidth: 400,
  gap: Spacing.MD,
  flexDirection: 'column',
  alignItems: 'center',
});

export const subdomainInputContainerClassName = css({
  minWidth: 400,
});

export const finalizeSubdomainTextClassName = css({
  textAlign: 'center',
  fontSize: '14px',
});

export const finalizeSubdomainCheckmarkClassName = css({
  height: '36px',
  width: '36px',
});
