import React, { PropsWithChildren, useState } from 'react';
import { DeleteTenantModalContext } from './DeleteTenantModalContext';
import { Modal } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import useDeleteTenant from './useDeleteTenant';
import useSessionInfo from 'auth/SessionInfoProvider/useSessionInfo';
import { useAtom } from 'jotai';
import { tenantIdAtom } from 'tenant/useTenantId';

const DeleteTenantModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tenantId, setTenantId] = useState<string>();
  const { formatMessage } = useIntl();
  const deleteTenant = useDeleteTenant();
  const { userTenants } = useSessionInfo();
  const [, setGlobalTenantId] = useAtom(tenantIdAtom);

  return (
    <DeleteTenantModalContext.Provider value={{ open: (tenantId: string) => setTenantId(tenantId) }}>
      <Modal
        visible={!!tenantId}
        onCancel={() => setTenantId(undefined)}
        title={formatMessage({
          defaultMessage: 'Are you sure?',
          description: 'Title for the delete tenant modal',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Delete',
          description: 'Delete button text',
        })}
        onAction={async () => {
          await deleteTenant.mutate(tenantId!);
          setGlobalTenantId(userTenants.find((tenant) => tenant.tenantId !== tenantId)?.tenantId ?? '');
          setTenantId(undefined);
        }}
        isActionButtonPending={deleteTenant.isPending}
      >
        <p>
          {formatMessage({
            defaultMessage: 'Are you sure you want to delete this tenant?',
            description: 'Description for the delete tenant modal',
          })}
        </p>
      </Modal>
      {children}
    </DeleteTenantModalContext.Provider>
  );
};

export default DeleteTenantModalProvider;
