import React, { PropsWithChildren } from 'react';
import { EditUserModalContext } from './EditUserModalContext';
import { useIntl } from 'react-intl';
import useEditUserModalReducer from './useEditUserModalReducer';
import { Modal } from '@snapchat/snap-design-system';
import EditUserModalContent from './EditUserModalContent';
import useUpdateUserEffect from './useUpdateUserEffect';
import { User } from 'users/types';

const EditUserModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useEditUserModalReducer();
  useUpdateUserEffect(dispatch, state);

  return (
    <EditUserModalContext.Provider value={{ open: (user: User) => dispatch({ type: 'ModalOpened', user }) }}>
      <Modal
        visible={state.visible}
        title={formatMessage({
          defaultMessage: 'Edit User',
          description: 'Edit user modal title',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Save',
          description: 'Save button text',
        })}
        isActionButtonPending={state.updateUserStatus === 'Loading'}
        onCancel={() => dispatch({ type: 'ModalClosed' })}
        onAction={() => dispatch({ type: 'ActionButtonClicked' })}
        isActionButtonDisabled={!state.name}
      >
        <EditUserModalContent dispatch={dispatch} {...state} />
      </Modal>
      {children}
    </EditUserModalContext.Provider>
  );
};

export default EditUserModalProvider;
