import { TenantConfigJson } from 'api/types';
import { TenantConfig } from 'types';

export const fromJson = (tenantConfig: TenantConfigJson): TenantConfig => {
  return {
    tenantId: tenantConfig.tenant_id,
    subdomain: tenantConfig.subdomain,
    name: tenantConfig.name,
    isSubdomainActive: tenantConfig.is_subdomain_active,
    status: tenantConfig.status,
  };
};

export const toJson = (tenantConfig: TenantConfig): TenantConfigJson => {
  return {
    tenant_id: tenantConfig.tenantId,
    subdomain: tenantConfig.subdomain,
    name: tenantConfig.name,
    is_subdomain_active: tenantConfig.isSubdomainActive,
    status: tenantConfig.status,
  };
};
