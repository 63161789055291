import { Role, UserJson } from 'api/types';
import { User, UserRole } from 'users/types';
import assertUnreachable from 'util/assertUnreachable';

export const toUserRole = (role: Role): UserRole => {
  switch (role) {
    case Role.Member:
      return UserRole.Member;
    case Role.Admin:
      return UserRole.Admin;
    default:
      return assertUnreachable(role);
  }
};

export const fromUserRole = (role: UserRole): Role => {
  switch (role) {
    case UserRole.Member:
    case UserRole.SuperMember:
      return Role.Member;
    case UserRole.Admin:
    case UserRole.SuperAdmin:
      return Role.Admin;
    default:
      return assertUnreachable(role);
  }
};

export const fromJson = (user: UserJson): User => ({
  id: user.user_id,
  name: user.name,
  role: user.role ? toUserRole(user.role) : undefined,
  status: user.status,
});

export const toJson = (user: User): UserJson => ({
  user_id: user.id,
  name: user.name,
  role: user.role ? fromUserRole(user.role) : undefined,
  status: user.status,
});
