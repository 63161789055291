import { AutoSizer, Section, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { User } from '../types';
import { usersSectionClassName } from '../styles';
import UserActionsColumnRenderer from './UserActionsColumnRenderer';
import UserRoleColumnRenderer from './UserRoleColumnRenderer';
import UserNameColumnRenderer from './UserNameColumnRenderer';
import EmptyState from 'common/EmptyState';

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<User>[] = [
  {
    id: 'name',
    accessor: 'name',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Name' description='Header text for name column' />,
    Cell: UserNameColumnRenderer,
  },
  {
    id: 'role',
    accessor: 'role',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Role' description='Header text for role column' />,
    Cell: UserRoleColumnRenderer,
  },
  {
    id: 'actions',
    accessor: 'id',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Actions' description='Header text for actions column' />,
    Cell: UserActionsColumnRenderer,
  },
];

interface Props {
  users: User[];
}

const UsersTable: React.FC<Props> = ({ users }) => {
  const { formatMessage } = useIntl();
  return (
    <Section className={usersSectionClassName}>
      <div style={{ height: users.length === 0 ? 200 : ROW_HEIGHT * (users.length + 1) }}>
        <AutoSizer>
          {({ width, height }: { width: number; height: number }) => (
            <VirtualTable
              columns={columns}
              data={users}
              loadMore={() => Promise.resolve()}
              hasMore={false}
              height={height}
              width={width}
              rowHeight={ROW_HEIGHT}
              emptyMessage={
                <EmptyState
                  title={formatMessage({
                    defaultMessage: 'No users found',
                    description: 'Empty state title for users table',
                  })}
                />
              }
            />
          )}
        </AutoSizer>
      </div>
    </Section>
  );
};

export default UsersTable;
