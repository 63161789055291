import React, { PropsWithChildren } from 'react';
import useConnectedPixels from '../useConnectedPixels';
import assertUnreachable from 'util/assertUnreachable';
import { Section } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import ConnectedPixelsTable from './ConnectedPixelsTable';
import { sectionBodyClassName } from './styles';
import ConnectedPixelsTableSkeleton from './ConnectedPixelsTableSkeleton';
import GenericError from 'common/GenericError';
import ConnectPixelButton from './ConnectPixelButton';

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  return (
    <Section
      title={formatMessage({ defaultMessage: 'Connected Pixels', description: 'Connected pixels section title' })}
      subtitle={formatMessage({
        defaultMessage: 'Manage how your connected Pixels are set up and functioning',
        description: 'Connected pixels section subtitle',
      })}
      rightHeaderContent={<ConnectPixelButton />}
      bodyClassName={sectionBodyClassName}
    >
      {children}
    </Section>
  );
};

const ConnectedPixelsSection: React.FC = () => {
  const data = useConnectedPixels();

  switch (data.status) {
    case 'pending':
      return (
        <Wrapper>
          <ConnectedPixelsTableSkeleton />
        </Wrapper>
      );
    case 'error':
      return <GenericError />;
    case 'success':
      return (
        <Wrapper>
          <ConnectedPixelsTable connectedPixels={data.data} />
        </Wrapper>
      );
    default:
      return assertUnreachable(data);
  }
};

export default ConnectedPixelsSection;
