import { AutoSizer, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyState from 'common/EmptyState';
import { DnsRecord } from 'api/types';
import useDnsRecords from './useDnsRecords';
import GenericError from 'common/GenericError';
import assertUnreachable from 'util/assertUnreachable';
import { dnsRecordInstructionsClassName } from './styles';

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<DnsRecord>[] = [
  {
    id: 'type',
    accessor: 'type',
    width: 80,
    Header: () => <FormattedMessage defaultMessage='Type' description='Header text for role column' />,
    Cell: ({ value }: { value: string }) => <>{value}</>,
  },
  {
    id: 'name',
    accessor: 'name',
    minWidth: 200,
    Header: () => <FormattedMessage defaultMessage='Name' description='Header text for name column' />,
    Cell: ({ value }: { value: string }) => <>{value}</>,
  },
  {
    id: 'value',
    accessor: 'value',
    minWidth: 200,
    Header: () => <FormattedMessage defaultMessage='Value' description='Header text for actions column' />,
    Cell: ({ value }: { value: string }) => <>{value}</>,
  },
];

const DnsRecordInstructions: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = useDnsRecords();

  switch (data.status) {
    case 'Error':
      return <GenericError />;
    case 'Loading':
    case 'Success':
      return (
        <div className={dnsRecordInstructionsClassName}>
          <h4>
            {formatMessage({
              defaultMessage: 'Setup Your DNS Records',
              description: 'DNS Records setup title',
            })}
          </h4>
          <p>
            {formatMessage({
              defaultMessage:
                'Please go to your DNS provider and add these records to finish setting up your custom subdomain',
              description: 'DNS records instructions',
            })}
          </p>
          <div
            style={{
              height:
                data.status === 'Success' && data.dnsRecords.length === 0
                  ? 200
                  : ROW_HEIGHT * (data.status === 'Loading' ? 3 : data.dnsRecords.length + 1),
            }}
          >
            <AutoSizer>
              {({ width, height }: { width: number; height: number }) => (
                <VirtualTable
                  columns={columns}
                  data={data.status === 'Loading' ? [{}, {}] : data.dnsRecords}
                  loadMore={() => Promise.resolve()}
                  hasMore={false}
                  height={height}
                  width={width}
                  rowHeight={ROW_HEIGHT}
                  emptyMessage={
                    <EmptyState
                      title={formatMessage({
                        defaultMessage: 'No records found',
                        description: 'Empty state title for DNS records table',
                      })}
                    />
                  }
                  isLoading={data.status === 'Loading'}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      );
    default:
      return assertUnreachable(data);
  }
};

export default DnsRecordInstructions;
