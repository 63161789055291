import isValidUrl from 'util/isValidUrl';
import useSetupSubdomainModal from './useSetupSubdomainModal';

export type SubdomainStatus = 'Valid' | 'InvalidUrl' | 'Empty';

const useSetupSubdomainStatus = (): SubdomainStatus => {
  const { subdomain } = useSetupSubdomainModal();
  const url = `https://${subdomain}`;

  if (!subdomain) {
    return 'Empty';
  }

  if (!isValidUrl(url)) {
    return 'InvalidUrl';
  }

  return 'Valid';
};

export default useSetupSubdomainStatus;
