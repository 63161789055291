import { UserRole } from 'users/types';

type Action = 'Read' | 'Create' | 'Update' | 'Delete';
type EntityType = 'User' | 'PixelConfig' | 'Tenant';

type RoleAccess = Record<UserRole, Record<EntityType, Record<Action, boolean>>>;

export const ROLE_ACCESS: RoleAccess = {
  [UserRole.Member]: {
    User: {
      Read: true,
      Create: false,
      Update: false,
      Delete: false,
    },
    PixelConfig: {
      Read: true,
      Create: false,
      Update: false,
      Delete: false,
    },
    Tenant: {
      Read: true,
      Create: false,
      Update: false,
      Delete: false,
    },
  },
  [UserRole.Admin]: {
    User: {
      Read: true,
      Create: true,
      Update: true,
      Delete: true,
    },
    PixelConfig: {
      Read: true,
      Create: true,
      Update: true,
      Delete: true,
    },
    Tenant: {
      Read: true,
      Create: false,
      Update: true,
      Delete: false,
    },
  },
  [UserRole.SuperAdmin]: {
    User: {
      Read: true,
      Create: true,
      Update: true,
      Delete: true,
    },
    PixelConfig: {
      Read: true,
      Create: true,
      Update: true,
      Delete: true,
    },
    Tenant: {
      Read: true,
      Create: true,
      Update: true,
      Delete: true,
    },
  },
  [UserRole.SuperMember]: {
    User: {
      Read: true,
      Create: false,
      Update: false,
      Delete: false,
    },
    PixelConfig: {
      Read: true,
      Create: false,
      Update: false,
      Delete: false,
    },
    Tenant: {
      Read: true,
      Create: false,
      Update: false,
      Delete: false,
    },
  },
};

const isAuthorized = ({ role, entity, action }: { role: UserRole; entity: EntityType; action: Action }): boolean => {
  return ROLE_ACCESS[role][entity][action];
};

export default isAuthorized;
