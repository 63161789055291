import React from 'react';
import usePixelEventOverview from './usePixelEventOverview';
import { Skeleton } from '@snapchat/snap-design-system';
import EventCountColumnRenderer from 'overview/PixelEventsSection/EventCountColumnRenderer';
import assertUnreachable from 'util/assertUnreachable';

interface Props {
  value: string;
  type: 'EventsReceived' | 'EventsSent';
}

const ConnectedPixelEventCountColumnRenderer: React.FC<Props> = ({ type, value: pixelId }) => {
  const data = usePixelEventOverview(pixelId);

  switch (data.status) {
    case 'Loading':
      return <Skeleton />;
    case 'Error':
      return <>0</>;
    case 'Success': {
      const pixelId = type === 'EventsReceived' ? data.eventReceivedCount : data.eventSentCount;

      return (
        <EventCountColumnRenderer
          value={pixelId}
          timestampKey={type === 'EventsReceived' ? 'lastReceivedAgoMillis' : 'lastSentAgoMillis'}
          row={{
            original: {
              lastReceivedAgoMillis: data.lastReceivedAgoMillis,
              lastSentAgoMillis: data.lastSentAgoMillis,
            },
          }}
        />
      );
    }
    default:
      return assertUnreachable(data);
  }
};

export default ConnectedPixelEventCountColumnRenderer;
