import React from 'react';
import { layoutClassName, mainClassName, sideNavClassName } from './styles';
import { Outlet } from 'react-router-dom';
import SideNav from './SideNav';
import TenantsDropdown from 'tenant/TenantsDropdown';
import NavBar from 'root/NavBar';

const LayoutContainer: React.FC = () => (
  <>
    <NavBar rightContent={<TenantsDropdown />} />
    <div className={layoutClassName}>
      <aside className={sideNavClassName}>{<SideNav />}</aside>
      <main className={mainClassName}>
        <Outlet />
      </main>
    </div>
  </>
);

export default LayoutContainer;
