import React from 'react';
import { useIntl } from 'react-intl';
import { Input } from '@snapchat/snap-design-system';
import useSubdomainInputStatus from './useSubdomainInputStatus';
import useSetupSubdomainModal from './useSetupSubdomainModal';
import { subdomainInputContainerClassName } from './styles';

const SubdomainInput: React.FC = () => {
  const { formatMessage } = useIntl();
  const { status, message } = useSubdomainInputStatus();
  const { dispatch, subdomain } = useSetupSubdomainModal();

  return (
    <div className={subdomainInputContainerClassName}>
      <h4>{formatMessage({ defaultMessage: 'Enter Your Custom Domain', description: 'Subdomain input title' })}</h4>
      <Input
        label={formatMessage({
          defaultMessage: 'Subdomain',
          description: 'Subdomain input label',
        })}
        placeholder={'capig.yourdomain.com'}
        onChange={(value) => dispatch({ type: 'SubdomainChanged', subdomain: value })}
        value={subdomain}
        statusType={status}
        status={message}
      />
    </div>
  );
};

export default SubdomainInput;
