import React from 'react';
import { useIntl } from 'react-intl';
import { useSetupSubdomainModal } from './SetupSubdomainModalProvider';
import { Button, Card } from '@snapchat/snap-design-system';
import DeleteSubdomainSetupButton from './DeleteSubdomainSetupButton';
import { rightContentClassName } from './styles';

interface Props {
  subdomain: string;
}

const SetupIncompleteSection: React.FC<Props> = ({ subdomain }) => {
  const { formatMessage } = useIntl();
  const { dispatch } = useSetupSubdomainModal();

  return (
    <Card
      title={formatMessage({
        defaultMessage: 'Your Subdomain Setup is Incomplete',
        description: 'Data Routing incomplete setup section title',
      })}
      subtitle={subdomain}
      status='warning'
      rightHeaderContent={
        <div className={rightContentClassName}>
          <DeleteSubdomainSetupButton />
          <Button onClick={() => dispatch({ type: 'CompleteSetupButtonClicked', subdomain })} type='primary'>
            {formatMessage({
              defaultMessage: 'Complete Setup',
              description: 'Add subdomain button text',
            })}
          </Button>
        </div>
      }
    >
      <div>
        <p>
          {formatMessage({
            defaultMessage:
              'Finish setting up your subdomain so pixels from this gateway can send events to your custom domain.',
            description: 'Data Routing Setup section description',
          })}
        </p>
      </div>
    </Card>
  );
};

export default SetupIncompleteSection;
