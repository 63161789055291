import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Notification } from '@snapchat/snap-design-system';
import { deleteApiToken } from 'api/api';

const useDeleteApiToken = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const deleteApiTokenMutation = useMutation({
    mutationFn: async (tokenId: string) => deleteApiToken(tokenId),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to delete token',
          description: 'Error message when deleting a token',
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['apiTokens'] });
      success({
        title: formatMessage({
          defaultMessage: 'Token Deleted',
          description: 'Success message when deleting a token',
        }),
      });
    },
  });

  return deleteApiTokenMutation;
};

export default useDeleteApiToken;
