import React from 'react';
import { pageHeaderClassName, pageHeaderTitleClassName } from './styles';

interface Props {
  title: string;
  rightContent?: React.ReactNode;
}

const PageHeader: React.FC<Props> = ({ title, rightContent }) => {
  return (
    <div className={pageHeaderClassName}>
      <h1 className={pageHeaderTitleClassName}>{title}</h1>
      {rightContent}
    </div>
  );
};

export default PageHeader;
