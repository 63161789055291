import useTenantConfig from './useTenantConfig';

const useIsTenantDeactivated = () => {
  const config = useTenantConfig();

  switch (config.status) {
    case 'Loading':
    case 'Error':
      return false;
    case 'Success':
      return config.tenantConfig.status === 'INACTIVE';
  }
};

export default useIsTenantDeactivated;
