import React from 'react';
import { useIntl } from 'react-intl';
import { State, Event } from './useEditTenantModalReducer';
import { Input } from '@snapchat/snap-design-system';
import { nameInputClassName } from './styles';

interface Props extends State {
  dispatch: React.Dispatch<Event>;
}

const EditTenantModalContent: React.FC<Props> = ({ dispatch, name, updateTenantStatus }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Input
        value={name}
        onChange={(name) => dispatch({ type: 'NameChanged', name })}
        label={formatMessage({
          defaultMessage: 'Account Name',
          description: 'Name input label',
        })}
        placeholder={formatMessage({
          defaultMessage: 'Enter name',
          description: 'Name input placeholder',
        })}
        className={nameInputClassName}
        disabled={updateTenantStatus === 'Loading'}
      />
    </div>
  );
};

export default EditTenantModalContent;
