import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { Notification } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import { refreshPixelConnection } from 'api/api';
import { useTenantId } from 'tenant';

const useRefreshPixelConnection = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const connectPixelMutation = useMutation({
    mutationFn: async (pixelId: string) => refreshPixelConnection(tenantId, pixelId),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to refresh Pixel connection',
          description: 'Error message when connecting pixel to CAPI Gateway fails',
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['connectedPixels'] });
      success({
        title: formatMessage({
          defaultMessage: 'Successfully refreshed Pixel connection',
          description: 'Success message when connecting pixel to CAPI Gateway succeeds',
        }),
      });
    },
  });

  return connectPixelMutation;
};

export default useRefreshPixelConnection;
