import React from 'react';
import useSetupSubdomainModal from './useSetupSubdomainModal';
import { Button, ModalFooter } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import assertUnreachable from 'util/assertUnreachable';
import useSetupSubdomainStatus from './useSubdomainStatus';
import useDNSRecordsStatus from './useDNSRecordsStatus';

interface Props {
  actionText: string;
  actionButtonDisabled?: boolean;
  actionButtonPending?: boolean;
  hideCancelButton?: boolean;
}

const Footer: React.FC<Props> = ({ actionText, actionButtonDisabled, actionButtonPending, hideCancelButton }) => {
  const { formatMessage } = useIntl();
  const { dispatch } = useSetupSubdomainModal();

  return (
    <ModalFooter>
      {!hideCancelButton && (
        <Button onClick={() => dispatch({ type: 'CancelButtonClicked' })}>
          {formatMessage({
            defaultMessage: 'Cancel',
            description: 'Cancel button text',
          })}
        </Button>
      )}
      <Button
        onClick={() => dispatch({ type: 'ActionButtonClicked' })}
        type='primary'
        isDisabled={actionButtonDisabled}
        isPending={actionButtonPending}
      >
        {actionText}
      </Button>
    </ModalFooter>
  );
};

const SetupSubdomainModalFooter: React.FC = () => {
  const { step, registerSubdomainStatus, confirmSubdomainStatus } = useSetupSubdomainModal();
  const { formatMessage } = useIntl();
  const subdomainStatus = useSetupSubdomainStatus();
  const dnsStatus = useDNSRecordsStatus();

  switch (step) {
    case 'EnterSubdomain':
      return (
        <Footer
          actionText={formatMessage({
            defaultMessage: 'Next',
            description: 'Next button text',
          })}
          actionButtonDisabled={subdomainStatus !== 'Valid'}
          actionButtonPending={registerSubdomainStatus === 'Loading'}
        />
      );
    case 'DnsRecords':
      return (
        <Footer
          actionText={formatMessage({
            defaultMessage: 'Next',
            description: 'Next button text',
          })}
        />
      );
    case 'ConfirmSubdomain':
      return (
        <Footer
          hideCancelButton={dnsStatus === 'Ready'}
          actionButtonDisabled={dnsStatus !== 'Ready'}
          actionButtonPending={confirmSubdomainStatus === 'Loading'}
          actionText={formatMessage({
            defaultMessage: 'Save',
            description: 'Save button text',
          })}
        />
      );
    default:
      return assertUnreachable(step);
  }
};

export default SetupSubdomainModalFooter;
