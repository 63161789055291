import { Section } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import SelectPixelDropdown from './SelectPixelDropdown';
import PixelEventsOverviewRow from './PixelEventsOverviewRow';
import PixelEventsTable from './PixelEventsTable';
import { eventTableTitleClassName } from './styles';
import useEventOverviewData from './useEventOverviewData';
import GenericError from 'common/GenericError';
import assertUnreachable from 'util/assertUnreachable';

const PixelEventsSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = useEventOverviewData();

  switch (data.status) {
    case 'Error':
      return <GenericError />;
    case 'Success':
    case 'Loading':
      return (
        <Section
          title={formatMessage({ defaultMessage: 'Event Activity', description: 'Pixel events section title' })}
          subtitle={formatMessage({
            defaultMessage: 'View metrics for recent events received by Snap\'s Conversions API',
            description: 'Pixel events section subtitle',
          })}
          rightHeaderContent={<SelectPixelDropdown />}
        >
          <PixelEventsOverviewRow />
          <h6 className={eventTableTitleClassName}>
            {formatMessage({
              defaultMessage: 'Events',
              description: 'Pixel events table title',
            })}
          </h6>
          <PixelEventsTable />
        </Section>
      );
    default:
      return assertUnreachable(data);
  }
};

export default PixelEventsSection;
