import PageHeader from 'common/PageHeader';
import React from 'react';
import { useIntl } from 'react-intl';
import useUsers from './useUsers';
import assertUnreachable from 'util/assertUnreachable';
import AddNewUserButton from './AddNewUserButton';
import AddUserModalProvider from './AddUserModalProvider';
import UsersTableSkeleton from './UsersTable/UsersTableSkeleton';
import UsersTable from './UsersTable/UsersTable';
import { DeleteUserModalProvider } from './DeleteUserModalProvider';
import isAuthorized from 'auth/isAuthorized';
import useSelectedTenant from 'tenant/useSelectedTenant';
import GenericError from 'common/GenericError';
import EditUserModalProvider from './EditUserModalProvider';

const UsersPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = useUsers();
  const { role } = useSelectedTenant();

  switch (data.status) {
    case 'Error':
      return <GenericError />;
    case 'Loading':
      return <UsersTableSkeleton />;
    case 'Success':
      return (
        <AddUserModalProvider>
          <DeleteUserModalProvider>
            <EditUserModalProvider>
              <PageHeader
                title={formatMessage({
                  defaultMessage: 'Users',
                  description: 'Users page title',
                })}
                rightContent={isAuthorized({ role, entity: 'User', action: 'Create' }) && <AddNewUserButton />}
              />
              <UsersTable users={data.users} />
            </EditUserModalProvider>
          </DeleteUserModalProvider>
        </AddUserModalProvider>
      );
    default:
      return assertUnreachable(data);
  }
};

export default UsersPage;
