import React from 'react';
import { useIntl } from 'react-intl';
import { State, Event } from './useAddTenantModalReducer';
import assertUnreachable from 'util/assertUnreachable';
import { Input } from '@snapchat/snap-design-system';
import { tenantCreatedStepClassName } from './styles';

interface Props extends State {
  dispatch: React.Dispatch<Event>;
}

const AddTenantModalContent: React.FC<Props> = ({ dispatch, tenantName, step, newTenantId }) => {
  const { formatMessage } = useIntl();

  switch (step) {
    case 'GoToTenant':
      return (
        <div className={tenantCreatedStepClassName}>
          <h4>
            {formatMessage(
              {
                defaultMessage: '{tenantName} successfully created!',
                description: 'Tenant created success message',
              },
              { tenantName },
            )}
          </h4>
        </div>
      );
    case 'TenantName':
      return (
        <Input
          label={formatMessage({
            defaultMessage: 'Account Name',
            description: 'Tenant name input label',
          })}
          placeholder={formatMessage({
            defaultMessage: 'Enter account name',
            description: 'Tenant name input placeholder',
          })}
          value={tenantName}
          onChange={(name) => dispatch({ type: 'TenantNameChanged', name })}
        />
      );
    default:
      return assertUnreachable(step);
  }
};

export default AddTenantModalContent;
