import React from 'react';
import { useIntl } from 'react-intl';
import useTenants from './useTenants';
import assertUnreachable from 'util/assertUnreachable';
import AddNewTenantButton from './AddNewTenantButton';
import { AddTenantModalProvider } from './AddTenantModalProvider';
import TenantsTableSkeleton from './TenantsTable/TenantsTableSkeleton';
import TenantsTable from './TenantsTable/TenantsTable';
import DeleteTenantModalProvider from './DeleteTenantModalProvider/DeleteTenantModalProvider';
import GenericError from 'common/GenericError';
import EditTenantModalProvider from './EditTenantModalProvider';
import PageHeader from 'common/PageHeader';
import DeactivateTenantModalProvider from './DeactivateTenantModalProvider';

const TenantsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = useTenants();

  switch (data.status) {
    case 'Error':
      return <GenericError />;
    case 'Loading':
      return (
        <>
          <PageHeader
            title={formatMessage({
              defaultMessage: 'Accounts',
              description: 'Accounts page title',
            })}
          />
          <TenantsTableSkeleton />
        </>
      );
    case 'Success':
      return (
        <AddTenantModalProvider>
          <DeleteTenantModalProvider>
            <EditTenantModalProvider>
              <DeactivateTenantModalProvider>
                <PageHeader
                  title={formatMessage({
                    defaultMessage: 'Accounts',
                    description: 'Accounts page title',
                  })}
                  rightContent={<AddNewTenantButton />}
                />
                <TenantsTable tenants={data.tenants} />
              </DeactivateTenantModalProvider>
            </EditTenantModalProvider>
          </DeleteTenantModalProvider>
        </AddTenantModalProvider>
      );
    default:
      return assertUnreachable(data);
  }
};

export default TenantsPage;
