import { IconButton } from '@snapchat/snap-design-system';
import { TrashIcon } from '@snapchat/snap-design-system-icons';
import React from 'react';
import { useDeleteSubdomainModal } from './DeleteSubdomainSetupModalProvider';

const DeleteSubdomainSetupButton: React.FC = () => {
  const { open: openDeleteModal } = useDeleteSubdomainModal();

  return <IconButton type='default' shape='circle' icon={<TrashIcon />} onClick={openDeleteModal} />;
};

export default DeleteSubdomainSetupButton;
