import React, { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import ConfirmLogoutModalContext from './ConfirmLogoutModalContext';
import { Modal } from '@snapchat/snap-design-system';
import useLogout from 'auth/useLogout';

const ConfirmLogoutModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const { formatMessage } = useIntl();
  const logout = useLogout();

  return (
    <ConfirmLogoutModalContext.Provider value={{ open: () => setVisible(true) }}>
      <Modal
        actionText={formatMessage({
          defaultMessage: 'Logout',
          description: 'Log out button text',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        onCancel={() => setVisible(false)}
        onAction={() => {
          logout();
          setVisible(false);
        }}
        title={formatMessage({
          defaultMessage: 'Are you sure?',
          description: 'Logout modal title',
        })}
        visible={visible}
      >
        {formatMessage({
          defaultMessage: 'Are you sure you want to log out?',
          description: 'Logout modal message',
        })}
      </Modal>
      {children}
    </ConfirmLogoutModalContext.Provider>
  );
};

export default ConfirmLogoutModalProvider;
