import { Button, Input, NavBar, Section } from '@snapchat/snap-design-system';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useAcceptAdminInvite from './useAcceptAdminInvite';
import { inputClassName, pageClassName, sectionBodyClassName, sectionClassName } from './styles';

const AcceptInvitePage: React.FC = () => {
  const { formatMessage } = useIntl();
  const acceptAdminInvite = useAcceptAdminInvite();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [adminName, setAdminName] = useState('');

  return (
    <div>
      <NavBar />
      <div className={pageClassName}>
        <h1>
          {formatMessage({
            defaultMessage: 'Welcome to Conversions API Gateway!',
            description: 'Accept invite page title',
          })}
        </h1>
        <h3>
          {formatMessage({
            defaultMessage: 'Please enter your information below to complete your account setup',
            description: 'Accept invite page subtitle',
          })}
        </h3>
        <Section className={sectionClassName} bodyClassName={sectionBodyClassName}>
          <Input
            value={userName}
            onChange={setUserName}
            label={formatMessage({
              defaultMessage: 'Your Name',
              description: 'Name input label',
            })}
            placeholder={formatMessage({
              defaultMessage: 'Enter your name',
              description: 'Name input placeholder',
            })}
            className={inputClassName}
          />
          <Input
            value={adminName}
            onChange={setAdminName}
            label={formatMessage({
              defaultMessage: 'Business Name',
              description: 'Account name input label',
            })}
            placeholder={formatMessage({
              defaultMessage: 'Enter your Business name',
              description: 'Account name input placeholder',
            })}
            className={inputClassName}
          />
          <div>
            <Button
              type='primary'
              onClick={async () => {
                setLoading(true);
                await acceptAdminInvite(userName, adminName);
                setLoading(false);
              }}
              isDisabled={!userName || !adminName}
              isPending={loading}
            >
              {formatMessage({
                defaultMessage: 'Continue',
                description: 'Accept invite button text',
              })}
            </Button>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default AcceptInvitePage;
