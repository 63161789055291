import { css } from '@emotion/css';

import { Colors, Spacing } from '@snapchat/snap-design-system';
import { Paragraph } from '@snapchat/snap-design-system/es/constants/fonts';

export const setupCodePreviewClassName = css({
  border: `2px solid ${Colors.Gray.V150}`,
  backgroundColor: Colors.WHITE,
  borderRadius: 8,
  padding: Spacing.MD,
  marginBottom: Spacing.SM,
});

export const preClassName = css({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
});

export const copyAndDownloadButtonClassName = css({
  float: 'right',
  gap: Spacing['1XS'],
});

export const codeSnippetClassName = css({
  ...Paragraph.P300,
  display: 'inline-block',
  lineHeight: '1.5',
});
