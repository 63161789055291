import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Notification } from '@snapchat/snap-design-system';
import { deleteUser } from 'api/api';
import { useTenantId } from 'tenant';

const useDeleteUser = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const deleteUserMutation = useMutation({
    mutationFn: async (userId: string) => deleteUser(tenantId, userId),
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to delete user',
          description: 'Error message when deleting a user',
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users', tenantId] });
      success({
        title: formatMessage({
          defaultMessage: 'User Deleted',
          description: 'Success message when deleting a user',
        }),
      });
    },
  });

  return deleteUserMutation;
};

export default useDeleteUser;
