import { useQuery } from '@tanstack/react-query';
import { getPixelEventsOverview, getTenantEventsOverview } from 'api/api';
import { useAtom } from 'jotai';
import { selectedDateRangeAtom, selectedPixelIdAtom } from 'overview/atoms';
import { useTenantId } from 'tenant';
import toDateRangeInput from 'util/toDateRangeInput';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
  message?: string;
}

interface Success {
  status: 'Success';
  eventTypeReceivedCount: number;
  eventTypeSentCount: number;
  eventReceivedCount: number;
  eventSentCount: number;
}

type Output = Loading | Error | Success;

const useEventOverviewData = (): Output => {
  const tenantId = useTenantId();
  const [pixelId] = useAtom(selectedPixelIdAtom);
  const [dateRange] = useAtom(selectedDateRangeAtom);

  const tenantOverview = useQuery({
    queryKey: ['tenantOverview', tenantId, dateRange],
    queryFn: async () => await getTenantEventsOverview(tenantId, toDateRangeInput(dateRange)),
    enabled: !pixelId,
  });

  const pixelOverview = useQuery({
    queryKey: ['pixelOverview', tenantId, pixelId, dateRange],
    queryFn: async () => await getPixelEventsOverview(tenantId!, pixelId!, toDateRangeInput(dateRange)),
    enabled: !!pixelId,
  });

  const queryResult = pixelId ? pixelOverview : tenantOverview;

  switch (queryResult.status) {
    case 'pending':
      return { status: 'Loading' };
    case 'error':
      return { status: 'Error', message: queryResult.error.message };
    case 'success':
      return {
        status: 'Success',
        eventTypeReceivedCount: queryResult.data.event_types_received ?? 0,
        eventTypeSentCount: queryResult.data.event_types_sent ?? 0,
        eventReceivedCount: queryResult.data.total_events_received ?? 0,
        eventSentCount: queryResult.data.total_events_sent ?? 0,
      };
  }
};

export default useEventOverviewData;
