import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PixelConfigJson } from '../../api/types';
import { useContext } from 'react';
import { Notification } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { connectPixel } from 'api/api';
import { useCookies } from 'react-cookie';
import { CONNECTED_PIXEL_STATUS_COOKIE_NAME_PREFIX } from 'common/constants';

const useConnectPixel = () => {
  const { error, success } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [, setCookie] = useCookies();

  const connectPixelMutation = useMutation({
    mutationFn: async (pixelConfig: PixelConfigJson) => {
      await connectPixel(pixelConfig);
      setCookie(CONNECTED_PIXEL_STATUS_COOKIE_NAME_PREFIX + pixelConfig.pixel_id, 'true', {
        expires: new Date(Date.now() + 30 * 60 * 1000),
      });
    },
    onError: () => {
      error({
        title: formatMessage({
          defaultMessage: 'Failed to connect Pixel to CAPI Gateway',
          description: 'Error message when connecting pixel to CAPI Gateway fails',
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['connectedPixels'] });
      success({
        title: formatMessage({
          defaultMessage: 'Successfully connected Pixel to CAPI Gateway',
          description: 'Success message when connecting pixel to CAPI Gateway succeeds',
        }),
      });
      navigate('/');
    },
  });

  return connectPixelMutation;
};

export default useConnectPixel;
