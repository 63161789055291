import { Button, Section } from '@snapchat/snap-design-system';
import { PlusSignIcon } from '@snapchat/snap-design-system-icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useGenerateTokenModal } from './CreateApiTokenModal';
import ApiTokensTable from './ApiTokensTable';
import useApiTokens from './useApiTokens';
import assertUnreachable from 'util/assertUnreachable';
import ApiTokensTableSkeleton from './ApiTokensTable/ApiTokensSkeleton';

const ApiTokensSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const { dispatch } = useGenerateTokenModal();
  const data = useApiTokens();
  const title = formatMessage({
    defaultMessage: 'API Access Tokens',
    description: 'API Access Tokens section title',
  });

  switch (data.status) {
    case 'Error':
      return (
        <Section title={title}>
          <ApiTokensTable tokens={[]} />
        </Section>
      );
    case 'Loading':
      return (
        <Section title={title}>
          <ApiTokensTableSkeleton />
        </Section>
      );
    case 'Success':
      return (
        <Section
          title={title}
          rightHeaderContent={
            <Button onClick={() => dispatch({ type: 'ModalOpened' })}>
              <PlusSignIcon />
              {formatMessage({ defaultMessage: 'Generate Token' })}
            </Button>
          }
        >
          <ApiTokensTable tokens={data.tokens} />
        </Section>
      );
    default:
      return assertUnreachable(data);
  }
};

export default ApiTokensSection;
