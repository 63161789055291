import { Section } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import EnablePixelJSHostingToggle from './EnablePixelHostingToggle';
import { AdminConfig } from 'types';

interface Props {
  config: AdminConfig;
}

const AdminSettings: React.FC<Props> = ({ config }) => {
  const { formatMessage } = useIntl();
  return (
    <Section
      title={formatMessage({
        defaultMessage: 'Admin Settings',
        description: 'Admin Settings page title',
      })}
    >
      <EnablePixelJSHostingToggle config={config} />
    </Section>
  );
};

export default AdminSettings;
