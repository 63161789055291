import { AutoSizer, VirtualTable, VirtualTableColumn } from '@snapchat/snap-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PixelConfig } from 'types';
import ConnectedPixelActions from './ConnectedPixelActions';
import ConnectedPixelEventCountColumnRenderer from './ConnectedPixelEventCountColumnRenderer';
import EmptyState from 'common/EmptyState';
import ConnectedPixelColumnRenderer from './ConnectedPixelColumnRenderer';

interface Props {
  connectedPixels: PixelConfig[];
}

const ROW_HEIGHT = 60;

const columns: VirtualTableColumn<PixelConfig>[] = [
  {
    id: 'pixelId',
    accessor: 'pixelId',
    minWidth: 160,
    Header: () => <FormattedMessage defaultMessage='Pixel' description='Header text for id column' />,
    Cell: ConnectedPixelColumnRenderer,
  },
  {
    id: 'eventsReceived',
    accessor: 'pixelId',
    minWidth: 120,
    Header: () => (
      <FormattedMessage defaultMessage='Total Events Received' description='Header text for events received column' />
    ),
    Cell: (props: any) => <ConnectedPixelEventCountColumnRenderer type='EventsReceived' {...props} />,
  },
  {
    id: 'eventsSent',
    accessor: 'pixelId',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Total Events Sent' description='Header text for events sent' />,
    Cell: (props: any) => <ConnectedPixelEventCountColumnRenderer type='EventsSent' {...props} />,
  },
  {
    id: 'actions',
    accessor: 'pixelId',
    minWidth: 120,
    Header: () => <FormattedMessage defaultMessage='Actions' description='Header text for actions column' />,
    Cell: ConnectedPixelActions,
  },
];

const ConnectedPixelsTable: React.FC<Props> = ({ connectedPixels }) => {
  const { formatMessage } = useIntl();

  return (
    <div style={{ height: connectedPixels.length === 0 ? 200 : ROW_HEIGHT * (connectedPixels.length + 1) }}>
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <VirtualTable
            columns={columns}
            data={connectedPixels}
            loadMore={() => Promise.resolve()}
            hasMore={false}
            height={height}
            width={width}
            rowHeight={ROW_HEIGHT}
            emptyMessage={
              <EmptyState
                title={formatMessage({
                  defaultMessage: 'No connected Pixels found',
                  description: 'Message displayed when no connected Pixels are found',
                })}
              />
            }
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ConnectedPixelsTable;
