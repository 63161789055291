import { useContext } from 'react';
import { EditUserModalContext } from './EditUserModalContext';

const useEditUserModal = () => {
  const { open } = useContext(EditUserModalContext);

  return { open };
};

export default useEditUserModal;
