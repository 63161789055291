import axios from 'axios';
import {
  AdminConfigJson,
  ApiTokenJson,
  DateRangeInputJson,
  DeletePixelInputJson,
  DnsInformation,
  EventOverviewJson,
  PixelConfigJson,
  PixelEventsData,
  PixelServerPixelConfig,
  TenantConfigJson,
  UserInviteJson,
  UserJson,
  UserTenantRoleJson,
} from './types';
import { authToken } from 'auth/authToken';
import { ADMIN_TENANT_ID } from 'common/constants';

const BASE_URL = process.env.GATEWAY_URL ?? '';

const gatewayInstance = axios.create({
  baseURL: BASE_URL,
});

gatewayInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = authToken ? `Bearer ${authToken}` : '';
  config.headers['Gateway-Domain'] = window.location.host;
  return config;
});

/******* Pixel Configs ********/
export const disconnectPixel = async (input: DeletePixelInputJson) => {
  const response = await gatewayInstance.delete(`/v1/tenant/${input.tenant_id}/pixel_configs`, {
    data: input,
  });
  return response.data;
};

export const connectPixel = async (pixelConfig: PixelConfigJson) => {
  return await gatewayInstance.post(`/v1/tenant/${pixelConfig.tenant_id}/pixel_configs`, pixelConfig);
};

export const getConnectedPixels = async (tenantId: string): Promise<{ pixels: PixelConfigJson[] }> => {
  const response = await gatewayInstance.get(`/v1/tenant/${tenantId}/pixel_configs`);
  return response.data;
};

export const refreshPixelConnection = async (tenantId: string, pixelId: string): Promise<void> =>
  gatewayInstance.put(`v1/tenant/${tenantId}/pixel_configs`, {
    tenant_id: tenantId,
    pixel_id: pixelId,
    status: 'ACTIVE',
  });

export const pausePixelConnection = async (tenantId: string, pixelId: string): Promise<void> =>
  gatewayInstance.put(`v1/tenant/${tenantId}/pixel_configs`, {
    tenant_id: tenantId,
    pixel_id: pixelId,
    status: 'INACTIVE',
  });

export const getPixelConfg = async (pixelId: string): Promise<{ data: PixelServerPixelConfig }> =>
  gatewayInstance.get(`/config/com/${pixelId}.json`);

/******* Tenant Configs ********/
export const getTenantConfig = async (tenantId: string): Promise<TenantConfigJson> =>
  (await gatewayInstance.get(`/v1/tenant/${tenantId}/config`))?.data;

export const getAdminConfig = async (): Promise<AdminConfigJson> =>
  (await gatewayInstance.get(`/v1/tenant/${ADMIN_TENANT_ID}/config`))?.data;

export const getTenants = async (): Promise<{ tenants: TenantConfigJson[] }> =>
  (await gatewayInstance.get(`/v1/tenants`)).data;

export const deleteTenant = async (tenantId: string) => gatewayInstance.delete(`/v1/tenant/${tenantId}`);

export const createTenant = async (tenantName: string): Promise<{ data: TenantConfigJson }> =>
  gatewayInstance.post(`/v1/tenants`, { name: tenantName });

export const updateTenantConfig = async (tenantConfig: TenantConfigJson) =>
  gatewayInstance.put(`/v1/tenant/${tenantConfig.tenant_id}/config`, tenantConfig);

export const initTenantSubdomain = async (tenantId: string, subdomain: string): Promise<void> =>
  gatewayInstance.post(`/v1/tenant/${tenantId}/subdomain/init`, { subdomain });

export const setTenantSubdomain = async (tenantId: string, subdomain: string) =>
  gatewayInstance.post(`/v1/tenant/${tenantId}/subdomain/finalize`, { subdomain });

export const deleteTenantSubdomain = async (tenantId: string) =>
  gatewayInstance.delete(`/v1/tenant/${tenantId}/subdomain`);

export const getDnsInfo = async (tenantId: string): Promise<{ data: DnsInformation }> =>
  gatewayInstance.get(`/v1/tenant/${tenantId}/dns`);

/******* Pixel Events ********/
export const getTenantEventsOverview = async (
  tenantId: string,
  dateRange: DateRangeInputJson,
): Promise<EventOverviewJson> =>
  (await gatewayInstance.get(`/v1/tenant/${tenantId}/event_overview`, { params: dateRange }))?.data;

export const getPixelEventsOverview = async (
  tenantId: string,
  pixelId: string,
  dateRange: DateRangeInputJson,
): Promise<EventOverviewJson> =>
  (await gatewayInstance.get(`/v1/tenant/${tenantId}/pixel/${pixelId}/event_overview`, { params: dateRange }))?.data;

export const getTenantEvents = async (tenantId: string, dateRange: DateRangeInputJson): Promise<PixelEventsData> =>
  (await gatewayInstance.get(`/v1/tenant/${tenantId}/events`, { params: dateRange }))?.data;

export const getPixelEvents = async (
  tenantId: string,
  pixelId: string,
  dateRange: DateRangeInputJson,
): Promise<PixelEventsData> =>
  (await gatewayInstance.get(`/v1/tenant/${tenantId}/pixel/${pixelId}/events`, { params: dateRange }))?.data;

/******* Auth ********/
export const getLoginRedirectUrl = async (): Promise<{ redirect_url: string }> =>
  (await gatewayInstance.get(`/v1/authorization/login_url`)).data;

export const getConnectPixelRedirectUrl = async (): Promise<{ data: { redirect_url: string } }> =>
  await gatewayInstance.get(`/v1/authorization/connect_pixel_url`);

export const generateApiToken = async (name: string): Promise<{ data: ApiTokenJson }> =>
  await gatewayInstance.post(`/v1/tenant/admin/api_tokens`, { name });

export const deleteApiToken = async (tokenId: string): Promise<unknown> =>
  await gatewayInstance.delete(`/v1/tenant/admin/api_tokens/${tokenId}`);

export const getApiTokens = async (): Promise<{ data: { tokens: ApiTokenJson[] } }> =>
  await gatewayInstance.get(`/v1/tenant/admin/api_tokens`);

/******* Users ********/
export const getUsers = async (tenantId: string): Promise<{ data: { users: UserJson[] } }> =>
  gatewayInstance.get(`/v1/tenant/${tenantId}/users`);

export const createUserInvite = async (tenantId: string, invite: UserInviteJson): Promise<{ data: UserInviteJson }> =>
  gatewayInstance.post(`/v1/tenant/${tenantId}/users/invite`, invite);

export const updateUser = async (tenantId: string, user: UserJson) =>
  gatewayInstance.put(`/v1/tenant/${tenantId}/user/${user.user_id}`, user);

export const deleteUser = async (tenantId: string, userId: string) =>
  gatewayInstance.delete(`/v1/tenant/${tenantId}/user/${userId}`);

export const acceptInvite = async (tenantId: string, inviteCode: string): Promise<{ data: UserJson }> =>
  gatewayInstance.post(`/v1/tenant/${tenantId}/users/accept_invite`, { invite_code: inviteCode });

export const getUserConfigs = async (userId: string): Promise<{ data: { tenants: UserTenantRoleJson[] } }> =>
  await gatewayInstance.get(`/v1/user/${userId}/tenants`);

/******* Cloudflare ********/
export const verifyCloudflareToken = async (tenantId: string, token: string): Promise<{ data: boolean }> =>
  await gatewayInstance.get(`v1/tenant/${tenantId}/verify_cloudflare_token`, {
    params: { token },
  });
