import { useContext } from 'react';
import { DeactivateTenantModalContext } from './DeactivateTenantModalContext';

const useDeactivateTenantModal = () => {
  const { open } = useContext(DeactivateTenantModalContext);

  return { open };
};

export default useDeactivateTenantModal;
