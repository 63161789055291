import { Button, DateRangePickerInput } from '@snapchat/snap-design-system';
import { useAtom } from 'jotai';
import { selectedDateRangeAtom } from 'overview/atoms';
import React, { useState } from 'react';
import { CURRENT_DATE, END_OF_TODAY, MIN_DATE } from './constants';
import useShortcuts from './useShortcuts';
import { CalendarIcon } from '@snapchat/snap-design-system-icons';

const DateRangePicker: React.FC = () => {
  const [dateRange, setDateRange] = useAtom(selectedDateRangeAtom);
  const shortcuts = useShortcuts();
  const [shortcutId, setShortcutId] = useState('1week');

  return (
    <DateRangePickerInput
      shortcuts={shortcuts}
      value={dateRange}
      today={CURRENT_DATE}
      minDate={MIN_DATE}
      maxDate={END_OF_TODAY}
      highlightedShortcutId={shortcutId}
      placement='leftBottom'
      onChange={(newRange, _, shortcut) => {
        setDateRange({
          to: newRange.to ?? undefined,
          from: newRange.from ?? undefined,
        });
        setShortcutId(shortcut?.id as string);
      }}
      dateRangeRenderer={
        shortcutId
          ? () => (
              <Button type='default'>
                <CalendarIcon />
                {shortcuts?.find((shortcut) => shortcut.id === shortcutId)?.label}
              </Button>
            )
          : undefined
      }
    />
  );
};

export default DateRangePicker;
