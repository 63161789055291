import { useSearchParams } from 'react-router-dom';

const useEnableCloudflare = () => {
  const [params] = useSearchParams();
  const enableCloudflare = params.get('enableCloudflare') === 'true';

  return enableCloudflare;
};

export default useEnableCloudflare;
