import { css } from '@emotion/css';
import { Colors, Fonts, Spacing } from '@snapchat/snap-design-system';

export const dropdownContainerClassName = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: Spacing['2XS'],
  paddingRight: Spacing.XS,
});

export const dropdownLabelClassName = css({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  gap: Spacing['2XS'],
});

export const tenantNameClassName = css({
  ...Fonts.Heading.H300,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '14px',
});

export const tenantRoleClassName = css({
  ...Fonts.Paragraph.P300,
  color: Colors.Black.V50,
});
