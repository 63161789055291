import { css } from '@emotion/css';
import { Spacing } from '@snapchat/snap-design-system';

export const copyLinkRowClassName = css({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const copyLinkInputClassName = css({
  flex: 1,
  marginRight: Spacing.SM,
  pointerEvents: 'none',
});

export const nameInputClassName = css({
  marginBottom: Spacing.SM,
});
