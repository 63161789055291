import { Role } from 'api/types';
import React from 'react';
import { useIntl } from 'react-intl';
import { State, Event } from './useEditUserModalReducer';
import { Input, Radio, RadioGroup } from '@snapchat/snap-design-system';
import { nameInputClassName } from './styles';

interface Props extends State {
  dispatch: React.Dispatch<Event>;
}

const EditUserModalContent: React.FC<Props> = ({ dispatch, selectedRole, name, updateUserStatus }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Input
        value={name}
        onChange={(name) => dispatch({ type: 'NameChanged', name })}
        label={formatMessage({
          defaultMessage: 'Name',
          description: 'Name input label',
        })}
        placeholder={formatMessage({
          defaultMessage: 'Enter name',
          description: 'Name input placeholder',
        })}
        className={nameInputClassName}
      />
      <RadioGroup
        value={selectedRole}
        onChange={(value) => dispatch({ type: 'RoleSelected', role: value })}
        disabled={updateUserStatus === 'Loading'}
      >
        <Radio
          value={Role.Member}
          label={formatMessage({
            defaultMessage: 'Member',
            description: 'Role option for member',
          })}
        />
        <Radio
          value={Role.Admin}
          label={formatMessage({
            defaultMessage: 'Admin',
            description: 'Role option for admin',
          })}
        />
      </RadioGroup>
    </div>
  );
};

export default EditUserModalContent;
