import useIsGatewayAdmin from 'auth/useIsGatewayAdmin';
import { toUserRole } from 'translators/UserTranslator';
import { UserRole } from 'users/types';
import useIsGatewayAdminMember from 'auth/useIsGatewayAdminMember';
import { Role } from 'api/types';
import useSessionInfo from 'auth/SessionInfoProvider/useSessionInfo';
import useTenantId from './useTenantId';

const useSelectedTenant = (): { name: string; role: UserRole; userName: string } => {
  const selectedTenantId = useTenantId();
  const { userTenants } = useSessionInfo();
  const isGatewayAdmin = useIsGatewayAdmin();
  const isGatewayAdminMember = useIsGatewayAdminMember();

  const selectedTenant = userTenants.find((tenant) => tenant.tenantId === selectedTenantId);
  if (!selectedTenant) {
    return { name: '', role: UserRole.Member, userName: '' };
  }

  if (isGatewayAdminMember) {
    return {
      name: selectedTenant.tenantName ?? '',
      role: UserRole.SuperMember,
      userName: selectedTenant.userName ?? '',
    };
  }

  if (isGatewayAdmin) {
    return {
      name: selectedTenant.tenantName ?? '',
      role: UserRole.SuperAdmin,
      userName: selectedTenant.userName ?? '',
    };
  }

  return {
    name: selectedTenant.tenantName ?? '',
    role: toUserRole(selectedTenant.role ?? Role.Member),
    userName: selectedTenant.userName ?? '',
  };
};

export default useSelectedTenant;
