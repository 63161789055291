import { Spinner } from '@snapchat/snap-design-system';
import React from 'react';
import NavBar from 'root/NavBar';
import { authRedirectWrapperClassName } from './styles';
import { LOGIN_BUTTON_ID } from './constants';

const LoadingPage: React.FC = () => {
  return (
    <>
      <NavBar />
      <div id={LOGIN_BUTTON_ID} className={authRedirectWrapperClassName}>
        <Spinner size='x-large' />
      </div>
    </>
  );
};

export default LoadingPage;
