import { useIntl } from 'react-intl';

const useFormatTimestamp = (): ((timestamp: number) => string) => {
  const { formatMessage } = useIntl();

  return (timestamp: number): string => {
    if (timestamp < 60 * 1000) {
      return formatMessage({
        defaultMessage: 'Just now',
        description: 'Time unit for just now',
      });
    }

    if (timestamp < 60 * 60 * 1000) {
      const minutes = Math.floor(timestamp / (60 * 1000));
      if (minutes === 1) {
        return formatMessage({
          defaultMessage: '1 minute ago',
          description: 'Time for 1 minute ago',
        });
      }

      return formatMessage(
        {
          defaultMessage: '{timeAgo} minutes ago',
          description: 'Time unit for minutes ago',
        },
        {
          timeAgo: minutes,
        },
      );
    }

    if (timestamp < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(timestamp / (60 * 60 * 1000));
      if (hours === 1) {
        return formatMessage({
          defaultMessage: '1 hour ago',
          description: 'Time for 1 hour ago',
        });
      }

      return formatMessage(
        {
          defaultMessage: '{timeAgo} hours ago',
          description: 'Time unit for hours ago',
        },
        {
          timeAgo: hours,
        },
      );
    }

    const days = Math.floor(timestamp / (24 * 60 * 60 * 1000));
    if (days === 1) {
      return formatMessage({
        defaultMessage: '1 day ago',
        description: 'Time for 1 minute ago',
      });
    }

    return formatMessage(
      {
        defaultMessage: '{timeAgo} days ago',
        description: 'Time unit for minutes ago',
      },
      {
        timeAgo: days,
      },
    );
  };
};

export default useFormatTimestamp;
