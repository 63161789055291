import React, { PropsWithChildren, useState } from 'react';
import { DisconnectPixelModalContext } from './DisconnectPixelModalContext';
import { Modal } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import useDisconnectPixel from './useDisconnectPixel';

const DisconnectedPixelModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [pixelId, setPixelId] = useState<string>();
  const { formatMessage } = useIntl();
  const disconnectPixel = useDisconnectPixel();

  return (
    <DisconnectPixelModalContext.Provider value={{ open: (pid: string) => setPixelId(pid) }}>
      <Modal
        visible={!!pixelId}
        onCancel={() => setPixelId(undefined)}
        title={formatMessage({
          defaultMessage: 'Are you sure?',
          description: 'Title for the disconnect pixel modal',
        })}
        cancelText={formatMessage({
          defaultMessage: 'Cancel',
          description: 'Cancel button text',
        })}
        actionText={formatMessage({
          defaultMessage: 'Disconnect',
          description: 'Disconnect button text',
        })}
        onAction={async () => {
          await disconnectPixel.mutate(pixelId!);
          setPixelId(undefined);
        }}
        isActionButtonPending={disconnectPixel.isPending}
      >
        <p>
          {formatMessage({
            defaultMessage:
              'Are you sure you want to disconnect this Pixel? Conversion API events will no longer be sent to Snap for this Pixel',
            description: 'Description for the disconnect pixel modal',
          })}
        </p>
        <p>
          {formatMessage(
            {
              defaultMessage: 'Pixel ID: {pixelId}',
            },
            {
              pixelId: pixelId,
            },
          )}
        </p>
      </Modal>
      {children}
    </DisconnectPixelModalContext.Provider>
  );
};

export default DisconnectedPixelModalProvider;
