import { Button, Input, Section, Spinner } from '@snapchat/snap-design-system';
import React from 'react';
import { AdminConfig } from 'types';
import { useIntl } from 'react-intl';
import {
  cloudflareTokenInputButtonClassName,
  cloudflareTokenInputClassName,
  cloudflareTokenInputContainerClassName,
} from './styles';
import useCloudflareTokenInputReducer from './useCloudflareTokenInputReducer';
import useSetCloudflareTokenEffect from './useSetCloudflareTokenEffect';
import useValidateCloudflareTokenEffect from './useValidateCloudflareTokenEffect';

interface Props {
  admin: AdminConfig;
}

const CloudflareTokenInput: React.FC<Props> = ({ admin }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = useCloudflareTokenInputReducer();
  useSetCloudflareTokenEffect(dispatch, state, admin);
  useValidateCloudflareTokenEffect(dispatch, state);

  const { tokenValidationStatus, saveTokenStatus, token } = state;

  return (
    <Section
      title={formatMessage({
        defaultMessage: 'Input your Cloudflare Token',
        description: 'Cloudflare Token input section title',
      })}
    >
      <div className={cloudflareTokenInputContainerClassName}>
        <Input
          className={cloudflareTokenInputClassName}
          value={token}
          onChange={(val) => dispatch({ type: 'TokenChanged', token: val })}
          placeholder={formatMessage({
            defaultMessage: 'Enter Cloudflare Token',
            description: 'Cloudflare Token input placeholder',
          })}
          label={formatMessage({
            defaultMessage: 'Cloudflare Token',
            description: 'Cloudflare Token input label',
          })}
          suffix={tokenValidationStatus === 'Loading' ? <Spinner /> : undefined}
          statusType={
            tokenValidationStatus === 'Error' ? 'error' : tokenValidationStatus === 'Success' ? 'success' : undefined
          }
          status={
            tokenValidationStatus === 'Error'
              ? formatMessage({
                  defaultMessage: 'Invalid token',
                  description: 'Invalid Cloudflare Token error message',
                })
              : tokenValidationStatus === 'Success'
                ? formatMessage({
                    defaultMessage: 'Token is valid',
                    description: 'Valid Cloudflare Token message',
                  })
                : undefined
          }
        />
        <Button
          onClick={() => {
            dispatch({ type: 'SaveButtonClicked' });
          }}
          isDisabled={!token || tokenValidationStatus !== 'Success'}
          isPending={saveTokenStatus === 'Loading'}
          className={cloudflareTokenInputButtonClassName}
          type='primary'
        >
          {formatMessage({
            defaultMessage: 'Save',
            description: 'Set Cloudflare Token button',
          })}
        </Button>
      </div>
    </Section>
  );
};

export default CloudflareTokenInput;
