import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTenantConfig } from 'api/api';
import { useContext } from 'react';
import { useTenantId } from 'tenant';
import { toJson } from 'translators/TenantConfigTranslator';
import { TenantStatus } from 'api/types';
import { TenantConfig } from 'types';
import { Notification } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';

const useReactivateTenant = (): ((tenant: TenantConfig) => Promise<unknown>) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();
  const { error } = useContext(Notification.Context);
  const { formatMessage } = useIntl();

  const mutation = useMutation({
    mutationKey: ['updateTenant', tenantId],
    mutationFn: async (tenant: TenantConfig) =>
      await updateTenantConfig(toJson({ ...tenant, status: TenantStatus.Active })),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tenants'] }),
    onError: () =>
      error({
        title: formatMessage({
          defaultMessage: 'Failed to reactive account',
          description: 'Failed to reactive tenant',
        }),
      }),
  });

  return (tenant: TenantConfig) => mutation.mutateAsync(tenant);
};

export default useReactivateTenant;
