import { Dropdown, Menu, MenuItem } from '@snapchat/snap-design-system';
import { EllipsisIcon } from '@snapchat/snap-design-system-icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { actionDropdownClassName } from './styles';
import { useDeleteUserModal } from 'users/DeleteUserModalProvider';
import isAuthorized from 'auth/isAuthorized';
import useSelectedTenant from 'tenant/useSelectedTenant';
import { useEditUserModal } from 'users/EditUserModalProvider';
import { User } from 'users/types';
import { userId as currentUserId } from 'users/userId';
import { useAddUserModal } from 'users/AddUserModalProvider';
import { useTenantId } from 'tenant';

interface Props {
  value: string;
  row: {
    index: number;
    original: User;
  };
}

const UserActionsColumnRenderer: React.FC<Props> = ({ value: userId, row }) => {
  const { formatMessage } = useIntl();
  const { open: openDeleteModal } = useDeleteUserModal();
  const { open: openEditModal } = useEditUserModal();
  const { dispatch } = useAddUserModal();
  const { role } = useSelectedTenant();
  const tenantId = useTenantId();

  return (
    <Dropdown
      overlay={
        <Menu>
          {row.original.status === 'INVITED' && (
            <MenuItem onClick={() => dispatch({ type: 'ViewInviteClicked', inviteUrl: window.location.origin + `/#/accept-invite?code=${userId ?? ''}&tenantId=${tenantId}` })}>
              {formatMessage({
                defaultMessage: 'View Invite',
                description: 'View user invite',
              })}
            </MenuItem>
          )}
          <MenuItem
            disabled={!isAuthorized({ role, entity: 'User', action: 'Update' })}
            onClick={() => openEditModal(row.original)}
            key='edit'
          >
            {formatMessage({
              defaultMessage: 'Edit',
              description: 'Edit user',
            })}
          </MenuItem>
          {userId !== currentUserId && (
            <MenuItem
              disabled={!isAuthorized({ role, entity: 'User', action: 'Delete' })}
              onClick={() => openDeleteModal(userId)}
              key='delete'
            >
              {formatMessage({
                defaultMessage: 'Delete',
                description: 'Delete user',
              })}
            </MenuItem>
          )}
        </Menu>
      }
      className={actionDropdownClassName}
    >
      <EllipsisIcon />
    </Dropdown>
  );
};

export default UserActionsColumnRenderer;
